import React from "react";
import { Skeleton } from "antd";

import { SkeletonListContainer } from "./styles";

export default function SkeletonList() {
  return (
    <SkeletonListContainer>
      <div className="first-line">
        <Skeleton.Input
          active={true}
          size="default"
          shape="default"
          block={false}
          style={{ width: 170, height: 35 }}
        />
      </div>
      <div className="second-line">
        <Skeleton.Input
          active={true}
          size="default"
          shape="default"
          block={false}
          style={{ width: 50, height: 25 }}
        />
        <Skeleton.Input
          active={true}
          size="default"
          shape="default"
          block={false}
          style={{ width: 50, height: 25 }}
        />
        <Skeleton.Input
          active={true}
          size="default"
          shape="default"
          block={false}
          style={{ width: 50, height: 25 }}
        />
      </div>
      <div className="third-line">
        {[1, 2, 3, 4, 5].map((item) => (
          <div key={item} className="sub-line">
            <Skeleton.Input
              active={true}
              block={false}
              style={{ width: "400px", height: 40 }}
            />
            <Skeleton.Input
              active={true}
              block={false}
              style={{ width: "400px", height: 40 }}
            />
            <Skeleton.Input
              active={true}
              block={false}
              style={{ width: "400px", height: 40 }}
            />
          </div>
        ))}
      </div>
    </SkeletonListContainer>
  );
}
