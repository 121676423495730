import React from "react";

// Estilos
import { FormTriggersChatContainer } from "./styles";

export default function FormTriggersChat(props) {
  const { connection, groupId, setGroupId } = props;

  return (
    <FormTriggersChatContainer>
      <div className="field-box mb-3 pt-1 rounded ">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="name"
        >
          Grupos
        </label>
        <select
          className=" select-language input-form shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="language"
          type="text"
          value={groupId}
          defaultValue="all"
          onChange={(e) => {
            setGroupId(e.target.value);
            console.log(e.target.value);
          }}
        >
          <option className="option-languege h-10" key="all" value="all">
            Todos
          </option>

          {connection.chatGroups !== undefined &&
          connection.chatGroups.length > 0 ? (
            connection.chatGroups.map((group) => (
              <option
                className="option-languege h-10"
                key={group.code}
                value={group.code}
              >
                {group.name}
              </option>
            ))
          ) : (
            <></>
          )}
        </select>
      </div>
    </FormTriggersChatContainer>
  );
}
