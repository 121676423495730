import React, { useEffect } from "react";
import { ButtonBlueDefault } from "styles/Global";
import { ViewUserWrapper } from "./styles";
import { Modal } from "antd";
import { API } from "services/api";

// Styles
import { InputDefault } from "styles/Global";
import { useNotification } from "contexts/session";

export default function ViewFormProfile(props) {
  // Hooks
  const { openNotification } = useNotification();
  const { userData } = props;

  const [name, setName] = React.useState("");
  const [alertPasswordMessage, setAlertPasswordMessage] = React.useState(
    "As senhas não correspondem"
  );
  const [alertFormPassword, setAlertFormPassword] = React.useState(false);
  const [passwordForm, setPasswordForm] = React.useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [activeButtonResetPassword, setActiveButtonResetPassword] =
    React.useState(true);

  const changePassword = () => {
    const formChangePassword = {
      currentPassword: passwordForm.currentPassword,
      newPassword: passwordForm.newPassword,
      email: userData.email,
    };

    API({
      method: "PATCH",
      url: "users/password",
      data: formChangePassword,
    })
      .then((res) => {
        openNotification({
          message: "Sucesso",
          description: "Senha alterada com sucesso.",
          status: "success",
        });
        setPasswordForm({
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        });
        setActiveButtonResetPassword(true);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 401) {
          openNotification({
            message: "Senha não alterada",
            description: "A senha atual não confere.",
            status: "error",
          });
          setActiveButtonResetPassword(true);
        }
      });
  };

  const changeProfileInformations = () => {
    const formUser = {
      name: name,
      email: userData.email,
    };
    API({
      method: "PATCH",
      url: `users/profile`,
      data: formUser,
    })
      .then((res) => {
        console.log(res);
        if (res.status === "success")
          openNotification({
            message: "Atualizado",
            description: "As informações de perfil foram atualizadas",
            status: "success",
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      passwordForm.newPassword !== "" &&
      passwordForm.confirmNewPassword !== "" &&
      passwordForm.newPassword !== passwordForm.confirmNewPassword
    ) {
      setAlertPasswordMessage("As senhas não correspondem");
      setAlertFormPassword(true);
      setActiveButtonResetPassword(true);
      return;
    } else {
      setAlertFormPassword(false);
    }
    if (
      passwordForm.newPassword !== "" &&
      passwordForm.confirmNewPassword !== ""
    )
      setActiveButtonResetPassword(false);
    else setActiveButtonResetPassword(true);
    if (passwordForm.newPassword && passwordForm.newPassword.length < 8) {
      setAlertPasswordMessage("A senha deve conter no mínimo 8 caracteres");
      setAlertFormPassword(true);
      return;
    }
    if (passwordForm.newPassword) setActiveButtonResetPassword(false);
  }, [passwordForm]);

  useEffect(() => {
    setName(userData.name);
  }, [userData]);

  return (
    <ViewUserWrapper>
      <div className="image-wrapper">
        <div>
          <img
            src={`https://ui-avatars.com/api/?name=${userData.name.replace(
              " ",
              "+"
            )}&background=82bec2&size=400`}
            alt="Perfil"
          />
        </div>
      </div>
      <div className="user-info">
        <div className="profile-fields">
          <div className="title-sections">Informações de perfil</div>
          <div className="input-box">
            <label htmlFor="name">Nome</label>
            <InputDefault
              id="name"
              type="name"
              placeholder="Nome do usuário"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="input-box">
            <label htmlFor="email">E-mail</label>
            <InputDefault
              id="email"
              className="input-disabled"
              readOnly
              type="name"
              placeholder="Nome do usuário"
              value={userData.email}
            />
          </div>
          <div className="button-wrapper">
            <ButtonBlueDefault onClick={changeProfileInformations}>
              Atualizar
            </ButtonBlueDefault>
          </div>
        </div>
        <hr />
        <div className="change-password-fields">
          <div className="title-sections">Alterar senha</div>
          <div className="input-box">
            <label htmlFor="current-password">Senha Atual</label>
            <InputDefault
              id="current-password"
              type="password"
              placeholder="••••••••"
              value={passwordForm.currentPassword}
              onChange={(e) =>
                setPasswordForm({
                  ...passwordForm,
                  currentPassword: e.target.value,
                })
              }
            />
          </div>
          <div className="input-box">
            <label htmlFor="new-password">Nova senha</label>
            <InputDefault
              id="new-password"
              type="password"
              placeholder="••••••••"
              value={passwordForm.newPassword}
              onChange={(e) =>
                setPasswordForm({
                  ...passwordForm,
                  newPassword: e.target.value,
                })
              }
            />
          </div>
          <div className="input-box">
            <label htmlFor="confirm-new-password">Confirmar nova senha</label>
            <InputDefault
              id="confirm-new-password"
              type="password"
              placeholder="••••••••"
              value={passwordForm.confirmNewPassword}
              onChange={(e) =>
                setPasswordForm({
                  ...passwordForm,
                  confirmNewPassword: e.target.value,
                })
              }
            />
          </div>
          {alertFormPassword && (
            <div className="alert-form-error">{alertPasswordMessage}</div>
          )}
          <div className="button-wrapper">
            <ButtonBlueDefault
              disabled={activeButtonResetPassword}
              onClick={changePassword}
            >
              Atualizar
            </ButtonBlueDefault>
          </div>
        </div>
      </div>
    </ViewUserWrapper>
  );
}
