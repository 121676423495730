import React from "react";
import { useSession } from "contexts/session";
import { Link } from "react-router-dom";

import { Dropdown } from "antd";

// Styles
import { HeaderBody, HeaderMenu } from "./styles";

export default function Header() {
  const { userData, logout } = useSession();

  const MenuHeaderDropdown = (
    <HeaderMenu>
      <Link to="admin/profile" className="menu-link">
        Perfil
      </Link>
      <Link to="#" onClick={() => logout()} className="menu-link">
        Sair
      </Link>
    </HeaderMenu>
  );

  return (
    <HeaderBody>
      <div x-data="{ isOpen: false }">
        <Dropdown overlay={MenuHeaderDropdown} placement="bottomRight">
          <button>
            <img
              src={`https://ui-avatars.com/api/?name=${userData.name.replace(
                " ",
                "+"
              )}&background=82bec2&size=400`}
              alt="Perfil"
            />
          </button>
        </Dropdown>
      </div>
    </HeaderBody>
  );
}
