import React from "react";

import { Checkbox } from "antd";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

// Drag components
import { Draggable } from "react-beautiful-dnd";

import { QuestionStyle } from "./styles";

export default function Question({
  quote,
  index,
  id,
  onChange,
  onRemove,
  required,
}) {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <QuestionStyle
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="mb-3 pt-1 rounded" className="wrapper-question">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor={id}
            >
              Pergunta {index + 1}
            </label>
            <div className="wrapper-input">
              <input
                className="input-form shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id={id}
                type="text"
                name={`question-${id}`}
                placeholder="Texto da pergunta"
                value={quote}
                onChange={(e) => onChange({ id: id, value: e.target.value })}
              />
              <button
                onClick={() => {
                  onRemove(id);
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
            <div className="required-checkbox-wrapper">
              <Checkbox
                onChange={(e) =>
                  onChange({ id: id, required: e.target.checked })
                }
              >
                Obrigatória
              </Checkbox>
            </div>
          </div>
        </QuestionStyle>
      )}
    </Draggable>
  );
}
