import styled from "styled-components";

// Barra fixada no topo da página
export const WrapperPageRating = styled.div`
  display: grid;
  grid-template-rows: 10.5fr 1.5fr;
  width: 100vw;
  height: 100vh;
  .box-form-colored {
    width: 550px;
    @media (max-width: 800px) {
      width: 90%;
    }
  }
  .wrapper-main-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loading-questions {
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
  }
  .result-submit {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .title-result {
      text-align: center;
      font-size: 1.2rem;
      margin-bottom: 20px;
    }
    .message-result {
      text-align: center;
    }
  }
  .inicial-text {
    text-align: center;
    font-size: 1.02rem;
    margin: 5px 0px 25px 0px;
  }
  .question-label {
    margin-bottom: 25px;
    .required-message {
      color: #f53141;
      display: flex;
      align-items: center;
      font-size: 0.75rem;
    }
  }
  .wrapper-rating-field {
    margin-bottom: 25px;
    .rating-nps {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
`;

export const FooterLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .develophedby-text {
    font-family: "Roboto";
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
  }
  img {
    height: 20px;
  }
`;
