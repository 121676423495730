import styled from "styled-components";

export const FormRegisterTemplate = styled.form`
  .editorWrapper {
    width: 100%;
  }
  .option-languege {
    height: 90px;
    border: none;
  }
  .box-preview {
    border: 1px solid rgba(40, 40, 40, 0.2);
    border-radius: 3px;
    padding: 5px;
    min-height: 20px;
  }
  .box-field {
    margin-bottom: 0.75rem;
    margin-top: 0.25rem;
    border-radius: 0.25rem;
  }
`;
