import React from "react";
import { Link } from "react-router-dom";
import { useSession } from "contexts/session";
import {
  AreaChartOutlined,
  FundProjectionScreenOutlined,
  StarOutlined,
  PartitionOutlined,
  AppstoreOutlined,
  RocketOutlined,
} from "@ant-design/icons";
import { Dropdown, Tooltip } from "antd";
import { mdiEmailSendOutline } from "@mdi/js";
import Icon from "@mdi/react";

// Assets
import IconeLogo from "assets/IconeLogoLoupen.png";
import LogoNPS from "assets/LogoNpsAPPV2.png";

// Styles
import { SidebarBody, OptionsMenu } from "./styles";

export default function Sidebar() {
  const { validateLogin, activePage, setActivePage } = useSession();
  const pages = [
    {
      name: "Dashboard",
      path: "/",
      icon: <AreaChartOutlined />,
    },
    {
      name: "Campanhas",
      path: "/campaigns",
      icon: <StarOutlined />,
    },
    {
      name: "Conexões",
      path: "/connections",
      icon: <PartitionOutlined />,
    },
    {
      name: "Avaliações Rápidas",
      path: "/quicky",
      icon: <RocketOutlined />,
    },
    // {
    //   name: "Envios",
    //   path: "/home/connections",
    //   icon: <Icon path={mdiEmailSendOutline} />,
    // },
    {
      name: "Admin",
      path: "/admin",
      icon: <AppstoreOutlined />,
    },
  ];

  function updatePage(pageName) {
    validateLogin();
    setActivePage(pageName);
  }

  const optionsHelpMenu = (
    <OptionsMenu>
      <Link to="#" className="account-link">
        Documentação
      </Link>
      <a
        href="https://suporte.loupen.com.br/support/tickets/new"
        target="_blank"
        rel="noreferrer"
        className="account-link"
      >
        Suporte
      </a>
    </OptionsMenu>
  );

  return (
    <SidebarBody className="gradient">
      <div className="wrapper-logo">
        <Link to="/home" onClick={() => validateLogin()}>
          <div className="box-image-logo">
            <img alt="Home" src={LogoNPS} width="70" height="90" />
          </div>
        </Link>
      </div>
      <nav className="wrapper-items">
        {pages.map((page, index) => (
          <Tooltip
            title={page.name}
            key={index}
            placement="right"
            color="green"
          >
            <Link
              className={` item ${page.name === activePage && "active-item"}`}
              to={page.path}
              onClick={() => updatePage(page.name)}
            >
              {page.icon}
            </Link>
          </Tooltip>
        ))}
      </nav>
      <div className="wrapper-options">
        <Dropdown overlay={optionsHelpMenu} placement="topLeft">
          <img src={IconeLogo} alt="Loupen" width={40} height={40} />
        </Dropdown>
      </div>
    </SidebarBody>
  );
}
