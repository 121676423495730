import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

import { ConnectionBox } from "./styles";

export default function Connection(props) {
  const { connection } = props;
  return (
    <ConnectionBox>
      <div className="connection-box">
        <div className="connection-image-box">
          <img
            src={connection.logo}
            alt={connection.name}
            className="connection-image"
          />
        </div>
        <div className="connection-infos-box">
          <div>
            <div className="connection-type">{connection.toolName}</div>
            <div className="connection-name">
              {" "}
              Nome: <span className="name">{connection.name}</span>{" "}
            </div>
          </div>
          <div style={{ fontSize: "0.72rem" }}>
            Sincronizado em:{" "}
            {new Date(connection.updatedDate).toLocaleDateString()} ás{" "}
            {new Date(connection.updatedDate).toLocaleTimeString()}
          </div>
          <div>
            <div className="connection-toolUrl">{connection.toolUrl}</div>
          </div>
        </div>
        <div className="button-wrapper">
          <Link to={`${connection.name}`}>
            <div className="button">
              <FontAwesomeIcon icon={faCogs} />
            </div>
          </Link>
        </div>
      </div>
    </ConnectionBox>
  );
}
