import React from "react";
import { Route, Routes } from "react-router-dom";

import { SessionProvider } from "contexts/session";

import Home from "pages/private";
import Dashboard from "pages/private/Dashboard";
import QuickyRatings from "pages/private/QuickyRatings";
import TemplateList from "pages/private/templates/List";
import NewTemplate from "pages/private/templates/New";
import ViewTemplate from "pages/private/templates/View";

import Connections from "pages/private/connections";
import ConnectionsList from "pages/private/connections/List";
import ConnectionsView from "pages/private/connections/View";

import FormsList from "pages/private/formularios/List";
import FormsNew from "pages/private/formularios/New";
import FormsView from "pages/private/formularios/View";

import Campaigns from "pages/private/campaigns";
import ListCampaigns from "pages/private/campaigns/List";
import NewCampaign from "pages/private/campaigns/New";
import ViewCampaign from "pages/private/campaigns/View";

import Admin from "pages/private/admin";
import AdminMenu from "pages/private/admin/Menu";
import AdminUsersList from "pages/private/admin/users/list";
import AdminUsersNew from "pages/private/admin/users/new";
import AdminUsersView from "pages/private/admin/users/view";
import ViewProfile from "pages/private/admin/profile";
import ConfirmRegistration from "pages/public/confirmRegistration";

import Login from "pages/private/login";
import AnswerRating from "pages/private/ratings";

// Rotas envolvidas pelo contexto de sessão
export default function PrivateRoutes() {
  return (
    <SessionProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="ratings/:ratingId" element={<AnswerRating />} />
        <Route path="confirm/:token" element={<ConfirmRegistration />} />
        <Route path="/" element={<Home />}>
          <Route path="" element={<Dashboard />} />
          <Route path="quicky" element={<QuickyRatings />} />
          <Route path="campaigns" element={<Campaigns />}>
            <Route path="" element={<ListCampaigns />} />
            <Route path=":id" element={<ViewCampaign />} />
            <Route path="new" element={<NewCampaign />} />
            <Route path="templates" element={<TemplateList />} />
            <Route path="templates/new" element={<NewTemplate />} />
            <Route path="templates/:id" element={<ViewTemplate />} />
            <Route path="forms" element={<FormsList />} />
            <Route path="forms/new" element={<FormsNew />} />
            <Route path="forms/:id" element={<FormsView />} />
          </Route>
          <Route path="connections" element={<Connections />}>
            <Route path="" element={<ConnectionsList />} />
            <Route path=":id" element={<ConnectionsView />} />
          </Route>
          <Route path="admin" element={<Admin />}>
            <Route path="" element={<AdminMenu />} />
            <Route path="users" element={<AdminUsersList />} />
            <Route path="users/new" element={<AdminUsersNew />} />
            <Route path="users/:email" element={<AdminUsersView />} />
            <Route path="profile" element={<ViewProfile />} />
          </Route>
        </Route>
        <Route path="*" element={<Login />} />
      </Routes>
    </SessionProvider>
  );
}
