import styled from "styled-components";

export const WrapperPageRegister = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 100vh;
  padding-top: 1.25rem;
  padding-bottom: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  @media (min-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
  }

  .header-login {
    display: flex;
    max-width: 32rem;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 2.5rem;
    @media (min-width: 768px) {
      margin-top: 3rem;
      margin-bottom: 2.5rem;
    }

    .login-image {
      margin: 0px;
      padding: 0px;
      cursor: pointer;
    }
  }

  a {
    color: rgb(255, 255, 255);
    font-weight: bold;
  }
  .logo {
    width: 400px;
    @media (max-height: 768px) {
      width: 250px;
    }
  }

  .login-card {
    width: 70%;
    background-color: white;
    max-width: 32rem;
    border-radius: 0.5rem;
    padding: 1.25rem 2rem 2rem 2rem;
    margin: 1.25rem auto 1rem auto;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    @media (max-height: 700px) {
      width: 100%;
    }
    @media (min-width: 768px) {
      padding-left: 3rem;
      padding-right: 3rem;
      padding-bottom: 2rem;
    }
    .title-box-login {
      h3 {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2rem;
      }
      justify-content: center;
      display: flex;
      margin-bottom: 0.75rem;
      @media (min-width: 768px) {
        margin-bottom: 1rem;
      }
    }
    .section-form-login {
      margin-top: 0.5rem;
      form {
        display: flex;
        flex-direction: column;
      }
    }
    .wrapper-input:nth-child(2) {
      margin-bottom: 20px;
    }
    .wrapper-input {
      margin-bottom: 0.75rem;
      padding-top: 0.25rem;
      border-radius: 0.25rem;
      label {
        display: block;
        color: rgb(55, 65, 81);
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
      }
    }
  }
`;
