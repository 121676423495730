import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API } from "services/api";
import { useNotification } from "contexts/session";
import { useSession } from "contexts/session";

// Styles
import { ScreenTitle } from "styles/Global";
import { PageWrapper } from "styles/ListItems";

// Icones
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import ViewFormProfile from "components/home/admin/profile/viewFormProfile";

export default function Viewuser() {
  const params = useParams();
  const { email } = params;
  const { openNotification } = useNotification();
  const [user, setUser] = useState({});
  const [loaded, setLoaded] = useState(false);

  const { userData } = useSession();

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
    API({ method: "GET", url: `users/${userData.email}` })
      .then((res) => {
        setUser(res);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        openNotification({
          message: "Erro",
          description: "Usuário não encontrado.",
          status: "error",
        });
      });
  }, [user, email, openNotification, loaded, userData]);

  return (
    <PageWrapper>
      <ScreenTitle>
        <span>
          <FontAwesomeIcon icon={faUser} />
        </span>
        {user.name || "Carregando ..."}
      </ScreenTitle>
      {user.name && <ViewFormProfile userData={user} />}
    </PageWrapper>
  );
}
