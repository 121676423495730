import styled from "styled-components";

export const WhiteLoadingWrapper = styled.div`
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 0px 10000vh rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 3rem;
`;
