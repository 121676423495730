import styled from "styled-components";

export const WrapperPageRegister = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  a {
    color: rgb(255, 255, 255);
    font-weight: bold;
  }
  .logo {
    width: 400px;
    @media (max-height: 768px) {
      width: 250px;
    }
  }
  .box-form-window {
    width: 70%;
    @media (max-height: 700px) {
      width: 100%;
    }
  }
  .text-confirm-register {
    text-align: center;
    font-size: 1rem;
  }
  .wrapper-form-confirmation {
    .title-instructions {
      width: 100%;
      text-align: center;
      font-weight: 700;
    }
  }

  .confirmation-text-error {
    margin-top: 10px;
    color: white;
    width: 100%;
    text-align: center;
    background-color: #df3a3a;
    padding: 10px 5px;
    border: 2px solid #d13535;
    border-radius: 7px;
  }

  .button-login-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }
`;
