import styled from "styled-components";

export const HeaderMenu = styled.div`
  width: 8rem;
  background-color: white;
  border-radius: 0%.5rem;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .menu-link {
    color: black;
    display: block;
    padding: 0.5rem 1rem;
    :hover {
      color: rgb(90, 90, 90);
    }
  }
`;

export const HeaderBody = styled.header`
  width: 100%;
  align-items: center;
  background-color: white;
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  .account-link:hover {
    background: rgba(116, 130, 133, 0.5);
  }
  @media (min-width: 640px) {
    display: flex;
  }

  button {
    z-index: 10;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 9999px;
    overflow: hidden;
    border: 4px solid rgb(156, 163, 175);
    :hover {
      border: 4px solid rgba(209, 213, 219);
    }
    :focus {
      border: 4px solid rgba(209, 213, 219);
      outline: none;
    }
  }
`;
