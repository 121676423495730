import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Pagination } from "antd";

// Styles
import { PageWrapper } from "styles/ListItems";
import { BtnOptMenu, ScreenTitle } from "styles/Global";

// Components
import ItemUser from "components/home/admin/users/List/Item";
import { API } from "services/api.js";

// Icones
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SkeletonList from "components/home/global/skeletons/skeletonList";

export default function UsersList() {
  const [loaded, setLoaded] = useState(false);
  const [total, setTotal] = useState(100);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [usersLoaded, setUsersLoaded] = useState(false);

  const [users, setUsers] = useState([]);

  const onChange = (_page) => {
    setPage(_page);
    API({
      window: window,
      method: "GET",
      url: `users?page=${_page}&per_page=${perPage}`,
    }).then(({ data, total }) => {
      setUsers(data);
      setTotal(total);
    });
  };

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
    API({ method: "GET", url: "users" }).then(({ users, total }) => {
      setUsers(users);
      setTotal(total);
      setUsersLoaded(true);
    });
  });

  return (
    <>
      <PageWrapper>
        <ScreenTitle>
          <span>
            <FontAwesomeIcon icon={faUser} />
          </span>
          Usuários
        </ScreenTitle>

        {usersLoaded ? (
          <>
            {users.length ? (
              <>
                <div className="menu-options">
                  <Link to="/admin/users/new">
                    <BtnOptMenu className="text-gray-800 font-extrabold rounded my-2 md:my-2 py-2 px-2 w-40 mx-2 shadow-sm">
                      Criar novo
                    </BtnOptMenu>
                  </Link>
                </div>
                <table className="w-full text-md bg-white shadow-md rounded mb-4">
                  <tbody>
                    <tr className="border-b">
                      <th className="px-4 py-3">Nome</th>
                      <th className="px-4 py-3">Email</th>
                      <th className="px-4 py-3">Cargo</th>
                      <th className="px-4 py-3"></th>
                    </tr>
                    {users.map((user) => (
                      <ItemUser
                        name={user.name}
                        email={user.email}
                        accesslevel={user.accesslevel}
                      />
                    ))}
                  </tbody>
                </table>
                <div className="wrapper-paginator">
                  <Pagination
                    simple
                    defaultCurrent={page}
                    total={total}
                    onChange={onChange}
                    pageSize={perPage}
                  />
                </div>
              </>
            ) : (
              <div className="notFound-message-wrapper">
                <div className="message-nnotFound">
                  Nenhum formulário encontrado
                </div>
                <div>
                  <Link to="/forms/new">
                    <BtnOptMenu className="text-gray-800 font-extrabold rounded my-2 md:my-2 py-2 px-2 w-40 mx-2 shadow-sm">
                      Criar novo
                    </BtnOptMenu>
                  </Link>
                </div>
              </div>
            )}
          </>
        ) : (
          <SkeletonList />
        )}
      </PageWrapper>
    </>
  );
}
