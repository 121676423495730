import styled from "styled-components";

export const WrapperPageRegister = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  a {
    color: rgb(255, 255, 255);
    font-weight: bold;
  }
  .logo {
    width: 400px;
    @media (max-height: 768px) {
      width: 250px;
    }
  }
  .box-form-window {
    width: 70%;
  }
`;

export const FormRedirectAccount = styled.form`
  .wrapper-button {
    display: flex;
    width: 100%;
    justify-content: center;
    button {
      font-family: "Roboto", sans-serif;
      color: white;
      font-weight: 600;
      padding: 0 30px;
      border-radius: 30px;
      float: right;
      height: 35px;
      background-color: #00adac;
      width: 80%;

      :hover {
        background-color: #00adac;
      }
    }
  }
`;
