import styled from "styled-components";

export const LanguageItemWrapper = styled.div`
  width: 100%;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  padding: 5px 10px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin-bottom: 5px;

  // Icone com nome do idioma
  .language-icon {
    background-color: rgba(79, 189, 172, 0.9);
    color: white;
    width: 100%;
    height: 5rem;
    font-size: 1.3rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  // Components do idioma
  .language-components {
    border: 2px solid #e0e0e0;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ececec;
    margin-left: 5px;
    padding-left: 15px;

    // Placeholder/botão adicionar componente
    .add-language-component {
      width: 30%;
      margin: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f4f4f4;
      border: 1px solid #d1d1d1;
      border-radius: 5px;
      color: #8f8f8f;
      padding: 1rem 2rem;
      font-weight: bold;
      cursor: pointer;
      .icon-add-item {
        display: flex;
        align-items: center;
        margin-right: 5px;
      }
    }

    // Component Element
    .language-component {
      width: 30%;
      height: 72px;
      margin: 0 5px;
      display: flex;
      font-size: 0.75rem;
      align-items: center;
      justify-content: center;
      background-color: #c3f161;
      border: 1px solid #b0d660;
      border-radius: 5px;
      color: #446108;
      padding: 1rem 2rem;
      font-weight: bold;
      position: relative;
      .language-component-icon {
        display: flex;
        align-items: center;
        margin: 0 10px;
        font-size: 1.7rem;
        color: #709b15;
      }
      :hover {
        .delete-component {
          display: flex;
          width: 15%;
          font-size: 1.12rem;
          box-shadow: -6px 0px 8px 0px rgba(0, 0, 0, 0.2);

          transition: width 0.3s ease-in-out, font-size 0.3s ease-in-out,
            box-shadow 0.5s ease-in-out;
        }
      }
      .delete-component {
        cursor: pointer;
        background-color: #b84431;
        position: absolute;
        right: 0;
        height: 100%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.01rem;
        color: white;
        width: 0%;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);

        transition: width 0.3s ease-in-out, font-size 0.3s ease-in-out,
          box-shadow 0.5s ease-in-out;
      }
    }
  }
`;
