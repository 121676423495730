import React, { useState, useEffect } from "react";
import { ButtonBlueDefault, FormWrapper } from "styles/Global";
import { useNotification, useModalAlert } from "contexts/session";
import { PlusCircleOutlined } from "@ant-design/icons";

// Api service
import { API } from "services/api";

// Context new campaign
import { useCampaign } from "contexts/campaignRegister";

// Images
import IconRating from "assets/IconClassRating.png";

// Icones
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import LanguageItem from "./LanguageItem";
import ModalSelectLanguage from "./ModalSelectLanguage";
import ModalInserItem from "./ModalInserItem";
import { useNavigate } from "react-router";

// Estilos
import {
  InputDefault,
  LabelDefault,
  SubtitleFormDefault,
  FormSectionDefault,
} from "styles/Form";
import {
  FormCampaignWrapper,
  BoxSelectStyleRating,
  BoxSelectLanguage,
  BoxButtonSave,
} from "./styles";
export default function Form() {
  //Estados
  const [loaded, setLoaded] = useState(false);
  const [isModalSelLangOpened, setIsModalSelLangOpened] = useState(false);
  const [isModalAddCompOpened, setIsModalAddCompOpened] = useState(false);

  // Informações
  const [languages, setLanguages] = useState([]);
  const [objectLanguages, setObjectLanguages] = useState([]);

  // Dados do form
  const [campaignType, setCampaignType] = useState("nps");

  const [componentToAdd, setComponentToAdd] = useState("");
  const [ratingTypeName, setRatingTypeName] = useState("");

  // Contextos
  const { openModalAlert } = useModalAlert();
  const { openNotification } = useNotification();
  const navigate = useNavigate();
  const {
    name,
    setName,
    ratingType,
    envelopes,
    setEnvelopes,
    ratingTypes,
    changingLanguage,
    setChangingLanguage,
  } = useCampaign();

  function onAddLanguage(languageId) {
    let newlanguage = {
      name: objectLanguages[languageId],
      languageId: languageId,
      components: {},
    };
    setEnvelopes([...envelopes, newlanguage]);
    console.log([...envelopes, newlanguage]);
  }

  function onAddComponent(component) {
    let languageIndex = envelopes.findIndex(
      (item) => item.languageId === changingLanguage
    );

    let newEnvelopes = envelopes;
    let selLanguage = envelopes[languageIndex];
    selLanguage.components[componentToAdd] = component;

    newEnvelopes[languageIndex] = selLanguage;
    setEnvelopes(newEnvelopes);
    console.log(newEnvelopes);

    setIsModalAddCompOpened(false);
  }

  function setChangingInformations(componentType, envelopeId) {
    setComponentToAdd(componentType);
    setChangingLanguage(envelopeId);
    setIsModalAddCompOpened(true);
  }

  function deleteLanguageComponent(language, component) {
    let languageIndex = envelopes.findIndex(
      (item) => item.languageId === language
    );
    let newEnvelopes = envelopes;
    let selLanguage = envelopes[languageIndex];
    delete selLanguage.components[component];
    newEnvelopes[languageIndex] = selLanguage;
    setEnvelopes(newEnvelopes);
  }

  function onSaveCampaign() {
    if (name === "") {
      openNotification({
        message: "Não preenchido",
        description: "Campo nome é obrigatório",
        status: "info",
      });
      return;
    }

    if (envelopes.length === 0) {
      openNotification({
        message: "Não preenchido",
        description: "Configure ao menos um idioma",
        status: "info",
      });
      return;
    }

    let newEnvelopes = envelopes.map((item) => ({
      language: item.languageId,
      templateId: item.components.emailTemplate
        ? item.components.emailTemplate.id
        : null,
      templateChatId: item.components.chatTemplate
        ? item.components.chatTemplate.id
        : null,
      formId: item.components.form ? item.components.form.id : null,
    }));
    let newCampaign = {
      name: name,
      ratingType: ratingType,
      envelopes: newEnvelopes,
    };

    API({ method: "POST", url: "campaigns", data: newCampaign })
      .then((response) => {
        openNotification({
          message: "Sucesso",
          description: "Campanha criada com sucesso",
          status: "success",
        });

        setName("");
        setEnvelopes([]);
        setCampaignType("");
        console.log(response);
        navigate("/campaigns");
      })
      .catch((error) => {
        openNotification({
          message: "Erro",
          description: "Erro ao criar campanha",
          status: "error",
        });
        console.log(error);
      });
  }

  function newItem() {
    let pathNewComponent = {
      emailTemplate: "templates",
      chatTemplate: "chat-templates",
      form: "forms",
    };
    let path = `/campaigns/${pathNewComponent[componentToAdd]}/new`;
    navigate(path);
  }

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
    console.log("===========================");
    console.log(ratingTypes);
    API({ method: "GET", url: "config/langs" }).then((data) => {
      let newObjectLanguages = {};
      data.value.forEach((language) => {
        newObjectLanguages[language.code] = language.name;
      });
      setLanguages(data.value);
      setObjectLanguages(newObjectLanguages);
      let newRatingTypeName = ratingTypes.find(
        (type) => type.subName === ratingType
      );
      if (newRatingTypeName) {
        setRatingTypeName(newRatingTypeName.name);
        return;
      }
      navigate("/campaigns");
    });
  });

  return (
    <FormWrapper>
      <FormCampaignWrapper>
        <div className="form-campaign-box">
          {/* Campo de inserção de nome e tipo de campanha no topo da página */}
          <div className="global-informations">
            <div className="icon-ratingtype-wrapper">
              <div className="icon-ratingtype">
                <img src={IconRating} alt="Tipo de avaliação" />
              </div>
              <div className="text-ratingType">{ratingTypeName}</div>
            </div>
            <div className="input-name-wrapper">
              <div className="mb-3 pt-1 rounded input-name">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Nome da campanha
                </label>
                <input
                  className="input-form shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  placeholder="Campanha..."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="menu-languages-wrapper">
            <div
              className="block text-gray-700 text-lg font-bold mb-2 label-languages"
              htmlFor="name"
            >
              Idiomas
            </div>

            {/* Listagem de idiomas */}
            <div className="languages-list">
              {envelopes && envelopes.length > 0 ? (
                envelopes.map((envelope) => (
                  <LanguageItem
                    changeComponentFunction={setChangingInformations}
                    content={envelope}
                    deleteLanguageComponent={deleteLanguageComponent}
                    openModalAlert={openModalAlert}
                  />
                ))
              ) : (
                <div className="no-languegs">Nenhum idioma registrado</div>
              )}
            </div>
            {/* Listagem de idiomas */}

            <button
              className="button-add-envelope"
              onClick={() => setIsModalSelLangOpened(true)}
            >
              <div className="icon-add">
                <PlusCircleOutlined />
              </div>
              Adicionar Idioma
            </button>
          </div>
          <div className="wrapper-btn-save">
            <button onClick={onSaveCampaign} className="btn-save">
              Salvar
            </button>
          </div>
        </div>

        {/* Modais */}

        <ModalSelectLanguage
          languages={languages}
          visible={isModalSelLangOpened}
          onOk={onAddLanguage}
          onCancel={() => setIsModalSelLangOpened(false)}
          addComponentHandler={() => setIsModalAddCompOpened(true)}
        />

        <ModalInserItem
          visible={isModalAddCompOpened}
          onOk={onAddComponent}
          newItem={newItem}
          onCancel={() => setIsModalAddCompOpened(false)}
          component={componentToAdd}
          envelope={changingLanguage}
        />
      </FormCampaignWrapper>
    </FormWrapper>
  );
}
