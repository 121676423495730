import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import FormForms from "components/home/formularios/Form";
import { FormWrapper, ScreenTitle } from "styles/Global";

export default function New() {
  const body = (
    <>
      <FormWrapper>
        <ScreenTitle>
          <span>
            <FontAwesomeIcon icon={faAlignLeft} />
          </span>
          Novo Formulário
        </ScreenTitle>
        <FormForms />
      </FormWrapper>
    </>
  );
  return <>{body}</>;
}
