import styled from "styled-components";

// Global wrappper
export const PlansBannerWrapper = styled.div`
  min-height: 90vh;
  min-height: fit-content;
  background-color: #f5f5f5;
  margin: 0 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px 10px 50px 10px;

  /* Header descrição do banner de planos */
  .header-plans {
    margin: 3% 0 5% 0;
    .title-plans {
      font-size: 2.5rem;
      text-align: center;
      margin-bottom: 10%;
      border-bottom: 5px solid rgba(0, 0, 0, 0.5);
    }
    .subtitle-plans {
      font-size: 1.2rem;
      text-align: center;
      line-height: 2rem;
    }
  }

  .wrapper-plans {
    min-height: 400px;
    height: fit-content;
    display: flex;

    /* Cartão de visualização do plano */
    .card-plan {
      border: 1px solid #e5e5e5;
      padding: 10px 15px;
      margin: 0 10px;
      border-radius: 10px;
      height: fit-content;
      //min-height: 100%;
      width: 100%;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      :nth-child(2) {
        transform: translateY(-30px);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      }
      .price-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 120px;
        font-size: 1.9rem;
        font-family: "Bakbak One", cursive;
        border-bottom: 1px solid #e5e5e5;
      }
      .title-box {
        background-color: #00df48;
        font-family: "Karla", sans-serif;
        text-align: center;
        padding: 10px 0;
        font-size: 1.5rem;
        font-weight: bold;
        color: #fff;
      }
      .decription-box {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        li {
          padding: 0 20px;
          margin-bottom: 10px;
          font-weight: bold;
          border-bottom: 1px solid #e5e5e5;
        }
      }
      .wrapper-button-plan {
        display: flex;
        justify-content: center;
        padding: 5px 0;
        .link-button {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
`;
