import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API } from "services/api";
import { Pagination } from "antd";

// Styles
import { PageWrapper } from "styles/ListItems";
import { BtnOptMenu, ScreenTitle, TableListWrapper } from "styles/Global";

// Components
import ItemTemplate from "components/home/templates/List/Item";
import SkeletonList from "components/home/global/skeletons/skeletonList";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function List() {
  const [loaded, setLoaded] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [total, setTotal] = useState(100);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loadedTemplates, setLoadedTemplates] = useState(false);

  const onChange = (_page) => {
    setPage(_page);
    console.log(_page);
    API({
      window: window,
      method: "GET",
      url: `templates?page=${_page}&per_page=${perPage}`,
    }).then(({ data, total }) => {
      setTemplates(data);
      setTotal(total);
    });
  };

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
    API({ method: "GET", url: "templates" }).then(({ data, total }) => {
      setTemplates(data);
      setLoadedTemplates(true);
      setTotal(total);
    });
  });
  return (
    <>
      <PageWrapper>
        <ScreenTitle>
          <span>
            <FontAwesomeIcon icon={faEnvelope} />
          </span>
          Templates
        </ScreenTitle>
        {loadedTemplates ? (
          <>
            {templates.length ? (
              <>
                <div className="menu-options">
                  <Link to="/campaigns/templates/new">
                    <BtnOptMenu className="text-gray-800 font-extrabold rounded my-2 md:my-2 py-2 px-2 w-40 mx-2 shadow-sm">
                      Criar novo
                    </BtnOptMenu>
                  </Link>
                </div>
                <TableListWrapper>
                  <table className="w-full text-md bg-white shadow-md rounded mb-4">
                    <tbody>
                      <tr className="border-b">
                        <th className="px-4 py-3">Id</th>
                        <th className="px-4 py-3">Nome</th>
                        <th className="px-4 py-3">Idioma</th>
                        <th className="px-4 py-3"></th>
                      </tr>
                      {templates.map((template) => (
                        <ItemTemplate
                          key={template.id}
                          id={template.id}
                          name={template.name}
                          language={template.language}
                          component="emailTemplate"
                        />
                      ))}
                    </tbody>
                  </table>
                </TableListWrapper>
                <div className="wrapper-paginator">
                  <Pagination
                    simple
                    defaultCurrent={page}
                    total={total}
                    onChange={onChange}
                    pageSize={perPage}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="notFound-message-wrapper">
                  <div className="message-nnotFound">
                    Nenhum template encontrado
                  </div>
                  <div>
                    <Link to="/campaigns/templates/new">
                      <BtnOptMenu className="text-gray-800 font-extrabold rounded my-2 md:my-2 py-2 px-2 w-40 mx-2 shadow-sm">
                        Criar novo
                      </BtnOptMenu>
                    </Link>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <SkeletonList />
        )}
      </PageWrapper>
    </>
  );
}
