import styled from "styled-components";

export const ModalInserItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 300px;
  .search-field {
    width: 100%;
    margin-bottom: 4px;
    display: flex;
    input {
      width: 95%;
    }
    .icon-search {
      display: flex;
      cursor: pointer;
      font-weight: bold;
      font-size: 1.2rem;
      justify-content: center;
      align-items: center;
      margin-left: 2px;
      width: 8%;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
    }
    .icon-new {
      width: 16%;
      margin-left: 2px;
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 0.7rem;
      font-weight: bold;
      cursor: pointer;
      .icon-add {
        display: flex;
        justify-content: center;
        margin-bottom: 4px;
      }
    }
  }
  .select-items {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    .item {
      display: flex;
      justify-content: space-between;
      padding: 5px 50px;
      cursor: pointer;
      text-align: center;
      border-bottom: 1px solid #e6e6e6;
      font-size: 1rem;
      :hover {
        background-color: #f5f5f5;
      }
      :active {
        background-color: #e3e3e3;
      }
      .id-wrapper {
        width: 20%;
        //border: 1px solid black;
        text-align: left;
      }
    }
    .selected {
      background-color: #b2df50;
      :hover {
        background-color: #a0c947;
      }
    }
  }
`;
