import React from "react";
import { Link } from "react-router-dom";
import { BtnOptMenu } from "styles/Global";

import { ItemWrapper } from "./styles";

export default function ItemCampaign(props) {
  const { id, name, ratingType } = props;
  return (
    <ItemWrapper className="text-gray-700">
      <td className="itemMiddle px-4 py-3 border">
        <div className="flex items-center text-sm">
          <span className="font-semibold text-black">{id}</span>
        </div>
      </td>
      <td className="itemMiddle px-4 py-3 text-md border ">{name}</td>
      <td className="px-4 py-3 text-xs border">
        <div className="flex justify-center">
          <span className="px-2 py-1 font-semibold leading-tight text-orange-700 bg-gray-100 rounded-sm mx-1">
            {ratingType}
          </span>
        </div>
      </td>
      <td className="px-4 py-3 text-sm border border">
        <div className="flex justify-center">
          <Link to={`${id}`}>
            <BtnOptMenu
              type="button"
              className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
            >
              Visualizar
            </BtnOptMenu>
          </Link>
        </div>
      </td>
    </ItemWrapper>
  );
}
