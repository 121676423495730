import styled from "styled-components";

export const WrapperPage = styled.div`
  background-color: #ffff;
  padding-top: 5px;
  font-family: karla;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  height: auto;
  @media (max-width: 768px) {
    height: 90vh;
  }
  .ant-tabs-content {
    height: 100%;
  }
`;

export const QuickyRatingsWrapper = styled.div`
  min-height: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper-main-box {
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    height: 500px;
    width: 65%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 1030px) {
      display: flex;
      flex-direction: column;
      height: auto;
    }
    .wrapper-form-selector {
      padding: 10px;
      padding-top: 20%;
      @media (max-width: 1030px) {
        padding-top: 0;
      }
      .title-main-box {
        font-size: 1.8rem;
        font-family: karla;
        margin-bottom: 10%;
        text-align: center;
        @media (max-width: 1030px) {
          padding-top: 5%;
          margin-bottom: 10%;
        }
      }
      .subtitles {
        margin-bottom: 10%;
        margin-left: 15px;
        font-size: 0.95rem;
        display: flex;
        flex-direction: column;
        gap: 25px;
        .sub-title {
          padding: 5px;
          display: flex;
          align-items: center;
          gap: 20px;
          .icon {
            font-size: 1.2rem;
          }
          .text {
          }
        }
      }

      .campaign-selector {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
        .selector-title {
          font-weight: bold;
          margin-right: 10px;
        }
      }
    }

    .wrapper-result {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .border-box {
        height: 85%;
        border-radius: 20px;
        padding: 10px;
        width: 95%;
        .box-result {
          border-radius: 20px;
          padding: 5px;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: white;
          @media (max-width: 1030px) {
            padding: 30px 0;
          }
          .qrcode {
            margin-bottom: 5px;
          }
          .link {
            margin-bottom: 30px;
            margin-top: 10px;
            text-align: center;
            border: 1px solid #23b68d;
            border-radius: 8px;
            padding: 3px 5px;
          }
          .buttons {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 10px;
            button {
              width: 55%;
              gap: 10px;
              @media (max-width: 1300px) {
                font-size: 0.7rem;
              }
              @media (max-width: 1030px) {
                font-size: 0.7rem;
                padding: 0;
              }
            }
          }
        }
      }
    }
    .placeholder-qrcode {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: bold;
      .text-placeholder {
        width: 50%;
      }
    }
  }
`;
