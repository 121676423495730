import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { API } from "services/api";
import { useNotification } from "contexts/session";
import { getSubdomain } from "utils";

// Images
import LogoLoupenBranca from "assets/LogoLoupenBranca.png";

// Styles
import { ButtonBlueDefault, InputDefault } from "styles/Global";
import { WrapperPageRating, FooterLogo } from "./styles";

//Components
import RatingNps from "./components/RatingNps";
import { LabelDefault } from "styles/Form";

export default function AnswerRating() {
  const params = useParams();
  const { ratingId } = params;

  // Dados
  const [form, setForm] = useState(null);
  const [ratingGrade, setRatingGrade] = useState(null);
  const [minGrade, setMinGrade] = useState(0);
  const [maxGrade, setMaxGrade] = useState(10);
  const [typeRating, setTypeRating] = useState("");

  // Estados
  const [loaded, setLoaded] = useState(false);
  const [successToSend, setSuccessToSend] = useState(false);

  // Hooks
  const { openNotification } = useNotification();
  const { register, handleSubmit } = useForm();

  // Página
  const [queryParams, setQueryParams] = useState({});
  const [language, setLanguage] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [subdomain, setSubdomain] = useState("");

  const onSubmit = async (data) => {
    if (ratingGrade === null) {
      openNotification({
        message: "Preencha os campos",
        description: "Por favor dê uma nota para o atendimento",
        status: "error",
      });
      return;
    }
    let responseForm = { responses: form.questions };
    let answersForm = [];
    const subdomain = getSubdomain();

    const headers = {
      Authorization: subdomain,
    };

    Object.values(data).forEach((response, index) => {
      responseForm.responses[index].response = response;
      answersForm.push(response);
    });

    if (ratingId === "sa") {
      let metadata = null;

      if (queryParams["md"]) {
        const metaDataArray = decodeURIComponent(queryParams["md"]);

        metaDataArray.split(";").forEach((param) => {
          const [key, value] = param.split(":");
          metadata = { ...metadata, [key]: value };
        });
      }
      let ratingForm = {
        responses: Object.values(responseForm.responses).map(
          ({ response }) => response
        ),
        value: ratingGrade,
        campaignId,
        language,
        metadata,
      };

      API({
        method: "POST",
        url: `ratings/sa`,
        data: ratingForm,
        headers,
      })
        .then((response) => {
          if (response.message === "Answered") {
            openNotification({
              message: "Sucesso",
              description: "Avaliação enviada com sucesso!",
              status: "success",
            });
            setSuccessToSend(true);
            if (window.parent !== window) {
              window.parent.postMessage(
                {
                  func: "closeModal",
                },
                "*"
              );
            }
          }
        })
        .catch((error) => {
          console.log("Erro");
          console.log(error);
          openNotification({
            message: "Erro ao enviar",
            description: "Por favor, tente enviar novamente",
            status: "error",
          });
        });
    } else {
      API({
        method: "POST",
        url: `ratings/form/${ratingId}`,
        data: responseForm,
        headers,
      }).then((response) => {
        if (response.message === "Answered") {
          API({
            method: "POST",
            url: `ratings/grade/${ratingId}`,
            data: {
              grade: ratingGrade,
            },
            headers,
          }).then((data) => {
            console.log(data);
            openNotification({
              message: "Sucesso",
              description: "Avaliação enviada com sucesso!",
              status: "success",
            });
            setSuccessToSend(true);
            if (window.parent !== window) {
              window.parent.postMessage(
                {
                  func: "closeModal",
                },
                "*"
              );
            }
          });
        } else {
          openNotification({
            message: "Erro ao enviar",
            description: "Por favor, tente enviar novamente",
            status: "error",
          });
        }
      });
    }
  };

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);

    const subdomain = getSubdomain();
    setSubdomain(subdomain);

    const headers = {
      Authorization: subdomain,
    };

    // Pega query params da url
    let qParams = {};
    let urlParams = new URLSearchParams(window.location.search);
    let listParams = urlParams.toString().split("&");
    for (let p of listParams) {
      var param = p.split("=");
      qParams[param[0]] = param[1];
    }
    setQueryParams(qParams);
    console.log("===== qparams");
    console.log(qParams);

    (async () => {
      let res;

      // Busca informações do formulário

      // É um formulário avulso, que não é previamente gerado
      if (ratingId === "sa") {
        const { form, type, language } = await API({
          method: "GET",
          url: `ratings/sa?c=${qParams["c"]}&lang=${qParams["lang"]}`,
          headers,
        }).catch((err) => {
          console.log(err);
          setForm({ status: "Not Found" });
        });
        res = { form, type };
        setLanguage(language);
        setCampaignId(qParams["c"]);

        // É um formulário já gerado obtido por um link
      } else {
        res = await API({
          method: "GET",
          url: `ratings/${ratingId}`,
          headers,
        }).catch((err) => {
          console.log(err);
          setForm({ status: "Not Found" });
        });

        if (res.status == "Answered") {
          setForm(res);
          return;
        }
        if (qParams.grade) {
          API({
            method: "POST",
            url: `ratings/grade/${ratingId}`,
            data: {
              grade: parseInt(qParams.grade),
            },
            headers,
          })
            .then(() => {
              openNotification({
                message: "Nota registrada",
                status: "success",
                description: "",
              });
              setRatingGrade(qParams.grade);
            })
            .catch(() => {
              openNotification({
                message: "Erro",
                description:
                  "Problema no registro, preencha o formulário e tente novamente",
                status: "error",
              });
            });
        }
      }

      API({
        method: "GET",
        url: `ratingtypes/${res.type}`,
        headers,
      })
        .then((typerating) => {
          setMinGrade(typerating.minValue);
          setMaxGrade(typerating.maxValue);
          if (ratingGrade == null)
            setRatingGrade(subdomain === "magalu" ? null : typerating.minValue);
          setTypeRating(res.type);
          if (res.type == "simounao") setRatingGrade(3);
          setForm(res.form);
        })
        .catch((err) => {
          openNotification({
            message: "Erro",
            description:
              "Houve um problema ao obter as informações, atualize a página",
            status: "error",
          });
          setForm({ status: "Not Found" });
        });
    })();
  });

  return (
    <WrapperPageRating className="gradient">
      <div className="wrapper-main-section">
        <main className="box-form-colored bg-white mx-auto px-5 pb-5 md:px-8 md:pb-5 md:pt-3  pt-3 mb-4 mt-5 rounded-lg shadow-2xl">
          <section className="mt-2">
            {successToSend ? (
              <>
                <div className="result-submit">
                  <div className="title-result">Enviado</div>
                  <div className="message-result">Obrigado por responder!</div>
                </div>
              </>
            ) : (
              <>
                {form ? (
                  <>
                    {form.status === "Answered" ? (
                      <div className="loading-questions">
                        A avaliação já foi respondida
                      </div>
                    ) : form.status === "Not Found" ? (
                      <div className="loading-questions">
                        A avaliação não foi encontrada
                      </div>
                    ) : (
                      <>
                        <div className="inicial-text">{form.inicialText}</div>

                        <div className="wrapper-rating-field">
                          <div className="rating-nps">
                            <RatingNps
                              typeRating={typeRating}
                              min={minGrade}
                              max={maxGrade}
                              value={ratingGrade}
                              onChange={(value) => setRatingGrade(value)}
                            />
                          </div>
                        </div>
                        <form
                          className="flex flex-col"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          {form.questions.map((question, index) => (
                            <div key={index} className="question-label">
                              <LabelDefault htmlFor={`question-${index}`}>
                                <div className=" text-gray-700 text-sm font-bold ">
                                  {question.question}
                                </div>
                                <InputDefault
                                  id={`question-${index}`}
                                  type="text"
                                  autoComplete="off"
                                  {...register(`question-${index}`, {
                                    required: question.required,
                                  })}
                                />
                                {question.required && (
                                  <div className="required-message">
                                    * Obrigatória
                                  </div>
                                )}
                              </LabelDefault>
                            </div>
                          ))}
                          <div className="button-wrapper">
                            <ButtonBlueDefault>Enviar</ButtonBlueDefault>
                          </div>
                        </form>
                      </>
                    )}
                  </>
                ) : (
                  <div className="loading-questions">Carregando...</div>
                )}
              </>
            )}
          </section>
        </main>
      </div>
      <FooterLogo>
        <div className="develophedby-text">Developed by</div>
        <a href="https://loupen.com.br">
          <img src={LogoLoupenBranca} alt="Loupen" />
        </a>
      </FooterLogo>
    </WrapperPageRating>
  );
}
