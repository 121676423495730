import React from "react";
import { RatingTypeWrapper } from "./styles";
import IconRating from "assets/IconClassRating.png";

export default function RatingType(props) {
  const { name, selected, onSelect } = props;

  return (
    <RatingTypeWrapper selected={selected} onClick={() => onSelect()}>
      <div className="boxOption">
        <div className="rating-icon">
          <img src={IconRating} alt="rating" />
        </div>
        <div className="connection-type-name">{name}</div>
      </div>
    </RatingTypeWrapper>
  );
}
