import React, { useEffect, useState } from "react";
import { useSession } from "../../../contexts/session";

// Components
import Sidebar from "./sidebar";

//Styles
import { LayoutWrapper } from "./styles";

export default function Layout({ children }) {
  const [loaded, setLoaded] = useState(false);

  const { validateLoginAdmin } = useSession();

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
    validateLoginAdmin();
  });
  return (
    <LayoutWrapper>
      <Sidebar />
      {children}
    </LayoutWrapper>
  );
}
