import React, { useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import axios from "axios";

// Components
import Footer from "components/landings/footer";
import WhiteLoading from "components/global/WhiteLoading";

// Images
import LogoNPS from "assets/LogoNpsAPPV2.png";

// Styles
import { ButtonBlueDefault } from "styles/Global";
import { FormRegister, WrapperPageRegister } from "./styles";
import { API } from "services/api";
import { useNotification } from "contexts/session";

import { getSubdomain } from "utils";

export default function ConfirmRegistration() {
  const { token } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { openNotification } = useNotification();
  const navigate = useNavigate();

  const [loaded, setLoaded] = React.useState(false);
  const [linkOptions, setLinkOptions] = React.useState(false);

  const [requestLoading, setRequestLoading] = React.useState(false);

  const onSubmit = (data) => {
    setRequestLoading(true);
    if (data.password !== data.repassword) {
      openNotification({
        message: "As senhas não conferem",
        description: "Elas devem se iguais",
        status: "error",
      });
      return;
    }
    let confirmationData = {
      name: linkOptions.name,
      email: linkOptions.email,
      password: data.password,
      token: linkOptions.token,
    };
    const subdomain = getSubdomain();

    API({
      window: window,
      method: "POST",
      url: "users/confirm",
      data: confirmationData,
      headers: { Authorization: subdomain },
    })
      .then((response) => {
        setRequestLoading(false);
        console.log(response);
        if (response === "Success") {
          openNotification({
            message: "Cadastro realizado com sucesso",
            description: "Você já pode se logar",
            status: "success",
          });
          navigate("/login");
        } else if (response === "Duplicated") {
          openNotification({
            message: "Email já cadastrado",
            description: "Existe um usuário com o mesmo e-mail",
            status: "error",
          });
        } else if (response === "Expirated") {
          openNotification({
            message: "Link expirado",
            description: "Solicite um novo link",
            status: "error",
          });
        }
        setRequestLoading(false);
      })
      .catch((error) => {
        setRequestLoading(false);
        console.log(error);
        openNotification({
          message: "Erro ao realizar o cadastro",
          description: "Algo não deu certo",
          status: "error",
        });
      });
  };

  const checkErrors = () => {
    if (Object.keys(errors).length > 0) {
      openNotification({
        message: "Preencha todos os campos",
        description: "Precisamos saber de tudo.",
        status: "info",
      });
    }
  };

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
    const subdomain = getSubdomain();
    API({
      method: "GET",
      url: `registration/${token}`,
      headers: { Authorization: subdomain },
    }).then((response) => {
      console.log(response);
      if (response === "Expired") {
        openNotification({
          message: "Link expirado",
          description: "Por favor, solicite um novo cadastro.",
          status: "info",
        });
        return;
      }
      if (response === "Not found") {
        openNotification({
          message: "Link inválido",
          description: "Por favor, solicite um novo cadastro.",
          status: "info",
        });
        return;
      }
      console.log(response);
      setLinkOptions(response);
    });
  });

  return (
    <WrapperPageRegister className="gradient  min-h-screen pt-5 pb-6 px-2 md:px-0">
      {requestLoading && <WhiteLoading />}
      <header className="max-w-lg mx-auto justify-center flex">
        <Link to="/">
          <div className="m-0 p-0 cursor-pointer">
            <img alt="Home" src={LogoNPS} width="200" height="50" />
          </div>
        </Link>
      </header>

      <main className="bg-white max-w-lg mx-auto px-8 pb-8 md:px-12 md:pb-8 md:pt-5  pt-5 mb-4 mt-5 rounded-lg shadow-2xl box-form-window">
        <section className="justify-center flex">
          {linkOptions ? (
            <h3 className="font-bold text-2xl">Bem vindo!</h3>
          ) : (
            <h3 className="font-bold text-2xl">Buscando informações</h3>
          )}
        </section>

        {linkOptions && (
          <section className="mt-2">
            <FormRegister
              className="flex flex-col"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mb-3 pt-1 rounded ">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Senha
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder=""
                  {...register("password", { required: true })}
                />
              </div>

              <div className="mb-3 pt-1 rounded ">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="repassword"
                >
                  Confirmação de senha
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="repassword"
                  type="password"
                  placeholder=""
                  {...register("repassword", { required: true })}
                />
              </div>

              <div className="flex align-center justify-center">
                <ButtonBlueDefault
                  disabled={requestLoading}
                  type="submit"
                  onClick={checkErrors}
                >
                  Começar
                </ButtonBlueDefault>
              </div>
            </FormRegister>
          </section>
        )}
      </main>

      <div className="max-w-lg mx-auto text-center mt-12 mb-6">
        <p className="text-white">
          Já tem uma conta?{" "}
          <Link to="/" className="font-bold hover:underline">
            Entrar
          </Link>
        </p>
        <Footer />
      </div>
    </WrapperPageRegister>
  );
}
