import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import FormTemplate from "components/home/templates/Form";
import { FormWrapper, ScreenTitle } from "styles/Global";

export default function New() {
  const body = (
    <>
      <FormWrapper>
        <ScreenTitle>
          <span>
            <FontAwesomeIcon icon={faEnvelope} />
          </span>
          Novo Template de E-mail
        </ScreenTitle>
        <FormTemplate />
      </FormWrapper>
    </>
  );
  return <>{body}</>;
}
