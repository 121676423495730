import styled from "styled-components";

export const ContainerLoading = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .box-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30%;
    height: 50%;
    .spinner {
      height: 200px;
      width: 200px;
      .image-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .image {
          width: 60%;
        }
      }
    }
  }
`;
