import React, { useEffect, useState } from "react";

import { LanguageItemWrapper } from "./styles";
import {
  PlusOutlined,
  MailOutlined,
  MessageOutlined,
  FileTextOutlined,
  ExpandOutlined,
} from "@ant-design/icons";
import { API } from "services/api";

export default function LanguageViewItem(props) {
  const { content, getLanguageName } = props;

  // Componentes do envelope
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [chatTemplate, setChatTemplate] = useState(null);
  const [form, setForm] = useState(null);

  useEffect(() => {
    API({
      window: window,
      method: "GET",
      url: `templates/${content.templateId}`,
    }).then((res) => {
      setEmailTemplate(res);
    });
    // API(window, "GET", `templates/${content.templateChatId}`).then((res) => {
    //   console.log(res);
    //   setEmailTemplate(res);
    // });
    API({ method: "GET", url: `forms/${content.formId}` }).then((res) => {
      setForm(res);
    });
  }, [content]);

  return (
    <LanguageItemWrapper>
      <div className="language-icon">{getLanguageName(content.language)}</div>

      <div className="language-components">
        {/* Template de email */}
        <div
          className={`language-component ${
            emailTemplate ? "" : "disabled-component"
          }`}
        >
          <div className="language-component-icon">
            <MailOutlined />
          </div>
          <div className="language-component-name">
            {emailTemplate ? emailTemplate.name : "Nenhum template de e-mail"}
          </div>
          {/* <div className={`delete-component ${emailTemplate ? "" : "hidden"}`}>
            <ExpandOutlined />
          </div> */}
        </div>

        {/* Template de chat */}
        <div
          className={`language-component ${
            chatTemplate ? "" : "disabled-component"
          }`}
        >
          <div className="language-component-icon">
            <MessageOutlined />
          </div>
          <div className="language-component-name">
            {chatTemplate ? chatTemplate.name : "Nenhum template de chat"}
          </div>
          {/* <div className={`delete-component ${chatTemplate ? "" : "hidden"}`}>
            <ExpandOutlined />
          </div> */}
        </div>

        {/* Formulário */}
        <div
          className={`language-component ${form ? "" : "disabled-component"}`}
        >
          <div className="language-component-icon">
            <FileTextOutlined />
          </div>
          <div className="language-component-name">
            {form ? form.name : "Nenhum formulário"}
          </div>
          {/* <div className={`delete-component ${form ? "" : "hidden"}`}>
            <ExpandOutlined />
          </div> */}
        </div>
      </div>
    </LanguageItemWrapper>
  );
}
