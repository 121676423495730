import styled from "styled-components";

export const SidebarBody = styled.aside`
  height: 100vh;
  width: 75px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .wrapper-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    padding: 0.7rem 5px;
  }
  .wrapper-items {
    width: 100%;
    .item {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 2.1rem;
      margin: 2rem 0px;
      color: white;
      transition: all 0.5s ease-in-out;
      :hover {
        transform: translateX(5px);
        transition: all 0.1s ease-in-out;
      }
    }

    .active-item {
      color: rgb(200, 200, 200);
    }
  }

  .wrapper-options {
    display: flex;
    justify-content: center;
    padding: 15px 0px;
  }

  /* .active-nav-link {
    background: rgba(116, 130, 133, 0.5);
  }
  .nav-item:hover {
    background: rgba(116, 130, 133, 0.2);
  } */
`;

export const OptionsMenu = styled.div`
  .account-link:hover {
    background: rgba(116, 130, 133, 0.5);
  }
  .account-link {
    color: black !important;
  }
`;
