import React, { useEffect, useState } from "react";
import { Spinner } from "loading-animations-react";
import { getSubdomain } from "utils";

import { ContainerLoading } from "./styles";
import LogoNps from "assets/LogoNpsAPPV2.png";
import LogoMagalu from "assets/LogoMagalu.png";

export default function Loading() {
  const [logo, setLogo] = useState(LogoNps);
  useEffect(() => {
    const subdomain = getSubdomain();
    if (subdomain === "magalu") setLogo(LogoMagalu);
  });

  return (
    <ContainerLoading className="gradient">
      <div className="box-animation">
        <div className="spinner">
          <Spinner
            color1="#2f8de4"
            color2="#0ff607"
            textColor="rgba(0,0,0, 0.5)"
            text={
              <div className="image-wrapper">
                <img src={logo} alt="Logo NPS" className="image" />
              </div>
            }
          />
        </div>
      </div>
    </ContainerLoading>
  );
}
