import React, { useEffect, useState } from "react";
import { API } from "../../../services/api";

// Styles
import { DashboardWrapper } from "./styles";

export default function Dashboard() {
  // Estados
  const [loaded, setLoaded] = useState(false);

  // Dados
  const [tenants, setTenants] = useState([]);

  function updateTenantList() {
    API({
      method: "GET",
      url: "tenants",
    })
      .then((data) => {
        setTenants(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Busca as contas cadastradas
  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
    updateTenantList();
  });
  return <DashboardWrapper></DashboardWrapper>;
}
