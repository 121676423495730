import styled from "styled-components";

export const NoConnectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 10%;
  overflow: visible;
  .no-con-box-instructions {
    background-color: white;
    padding: 5px 20px 15px 20px;
    border-radius: 10px;
  }
`;

export const WrapperConnections = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
