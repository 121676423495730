import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormWrapper, ScreenTitle } from "styles/Global";
import FormCampaign from "../components/Form";

export default function New() {
  return (
    <>
      <FormWrapper>
        <ScreenTitle>
          <span>
            <FontAwesomeIcon icon={faStar} />
          </span>
          Nova Campanha
        </ScreenTitle>
        <FormCampaign />
      </FormWrapper>
    </>
  );
}
