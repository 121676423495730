import React from "react";

import { LanguageItemWrapper } from "./styles";
import {
  PlusOutlined,
  MailOutlined,
  MessageOutlined,
  FileTextOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

export default function LanguageItem(props) {
  const {
    content,
    changeComponentFunction,
    deleteLanguageComponent,
    openModalAlert,
  } = props;
  return (
    <LanguageItemWrapper>
      <div className="language-icon">{content.name}</div>
      <div className="language-components">
        {content.components.emailTemplate ? (
          <div className="language-component">
            <div className="language-component-icon">
              <MailOutlined />
            </div>
            <div className="language-component-name">
              {content.components.emailTemplate.name}
            </div>
            <div
              className="delete-component"
              onClick={() =>
                openModalAlert({
                  message: `Desvincular o componente ${content.components.emailTemplate.name}?`,
                  onConfirm: () => () => {
                    console.log("Removeu");
                    deleteLanguageComponent(
                      content.languageId,
                      "emailTemplate"
                    );
                  },
                })
              }
            >
              <DeleteOutlined />
            </div>
          </div>
        ) : (
          <div
            className="add-language-component"
            onClick={() =>
              changeComponentFunction("emailTemplate", content.languageId)
            }
          >
            <div className="icon-add-item">
              <PlusOutlined />
            </div>
            Template de email
          </div>
        )}

        {/* {content.components.chatTemplate ? (
          <div className="language-component">
            <div className="language-component-icon">
              <MessageOutlined />
            </div>
            <div className="language-component-name">
              {content.components.chatTemplate.name}
            </div>
            <div
              className="delete-component"
              onClick={() =>
                openModalAlert({
                  message: `Desvincular o componente ${content.components.chatTemplate.name}?`,
                  onConfirm: () => () => {
                    console.log("Removeu");
                    deleteLanguageComponent(content.languageId, "chatTemplate");
                  },
                })
              }
            >
              <DeleteOutlined />
            </div>
          </div>
        ) : (
          <div
            className="add-language-component"
            onClick={() =>
              changeComponentFunction("chatTemplate", content.languageId)
            }
          >
            <div className="icon-add-item">
              <PlusOutlined />
            </div>
            Template de chat
          </div>
        )} */}

        {content.components.form ? (
          <div className="language-component">
            <div className="language-component-icon">
              <FileTextOutlined />
            </div>
            <div className="language-component-name">
              {content.components.form.name}
            </div>
            <div
              className="delete-component"
              onClick={() =>
                openModalAlert({
                  message: `Desvincular o componente ${content.components.form.name}?`,
                  onConfirm: () => () => {
                    console.log("Removeu");
                    deleteLanguageComponent(content.languageId, "form");
                  },
                })
              }
            >
              <DeleteOutlined />
            </div>
          </div>
        ) : (
          <div
            className="add-language-component"
            onClick={() => changeComponentFunction("form", content.languageId)}
          >
            <div className="icon-add-item">
              <PlusOutlined />
            </div>
            Formulário
          </div>
        )}
      </div>
    </LanguageItemWrapper>
  );
}
