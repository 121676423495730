import React from "react";
import { useNavigate } from "react-router-dom";

// Fonts
import "assets/fonts/fonts.css";

// Images
import LogoLoupenAzul from "assets/LogoLoupenAzul.png";

// Styles
import { LandingBanner, NavbarLanding, LandingWrapper } from "./styles";

// Components
import PlansBanner from "components/purchase/plansBanner";
import { useSession } from "contexts/session";

export default function PricesPage() {
  let navigate = useNavigate();
  const [registerEmail, setRegisterEmail] = React.useState("");
  const { logout } = useSession();

  return (
    <LandingWrapper className="gradient">
      <NavbarLanding>
        <div className="wrapper-nav">
          <div className="wrapper-logo-navbar">
            <img src={LogoLoupenAzul} className="logoNavbar" alt="" />
          </div>
          <div className="navbar-box-login">
            <button onClick={() => logout()}>Sair</button>
          </div>
        </div>
      </NavbarLanding>

      {/* Banner inicial */}
      <div style={{ height: "65px" }}></div>

      <PlansBanner />
    </LandingWrapper>
  );
}
