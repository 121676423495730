import React from "react";

import { FormTriggersDeskContainer } from "./styles";

export default function FormTriggersDesk(props) {
  const { connection, ticketType, setTicketType } = props;

  return (
    <FormTriggersDeskContainer>
      <div className="field-box mb-3 pt-1 rounded ">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="name"
        >
          Tipo de ticket
        </label>
        <select
          className=" select-language input-form shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="language"
          type="text"
          value={ticketType}
          defaultValue="all"
          onChange={(e) => {
            setTicketType(e.target.value);
            console.log(e.target.value);
          }}
        >
          <option className="option-languege h-10" key="all" value="all">
            Todos
          </option>

          {connection.fields !== undefined ? (
            connection.fields[0].values.map((type) => (
              <option className="option-languege h-10" key={type} value={type}>
                {type}
              </option>
            ))
          ) : (
            <></>
          )}
        </select>
      </div>
    </FormTriggersDeskContainer>
  );
}
