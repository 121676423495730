import styled from "styled-components";

export const ViewUserWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 2.5fr;
  padding: 20px;
  width: 100%;
  height: 70%;
  .image-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    img {
      width: 10rem;
      border-radius: 50%;
    }
    @media (max-width: 890px) {
      padding-right: 20px;
    }
  }
  .user-info {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .title-sections {
      width: 90%;
      font-size: 1.5rem;
      text-align: left;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 10px;
    }
    .input-box {
      padding-top: 0.25rem;
      color: rgb(55, 65, 81);
      font-size: 0.875rem /* 14px */;
      line-height: 1.25rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
      label {
        display: block;
        color: rgb(55, 65, 81);
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
      }
    }
    .alert-form-error {
      color: #f6f2f1;
      background-color: #ee6041;
      border: 1px solid rgba(209, 60, 28, 0.5);
      padding: 8px 5px;
      border-radius: 5px;
      text-align: center;
    }
    /* sessão de alteração de nome de usuário */
    .profile-fields {
      width: 70%;
      @media (max-width: 890px) {
        width: 100%;
        margin-left: 30px;
      }
    }
    /* sessão de alteração de senha */
    .change-password-fields {
      width: 70%;
      @media (max-width: 890px) {
        width: 100%;
        margin-left: 30px;
      }
    }
    .button-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
      button {
        width: 15rem;
      }
    }
  }
`;
