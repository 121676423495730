import styled from "styled-components";

export const WrapperPreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .field-preview {
    width: 80%;
  }
  .title-field-preview {
    font-family: karla;
    font-weight: bold;
    font-size: 1.1rem;
    margin: 0;
  }

  .wraper-content {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .box-languages {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .language-snippet {
    background-color: #00acae;
    width: fit-content;
    padding: 5px 10px;
    color: white;
    font-weight: bold;
    border-radius: 10px;
  }
`;

export const WrapperButtonsEdit = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: right;

  button {
    width: 100px;
  }
`;
