import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API } from "services/api";
import { useNotification } from "contexts/session";
import { Select, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

// Icones
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNetworkWired,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

// Code Highlights for input
import CodeHighlight from "code-highlight";
import "code-highlight/lib/style.css";
import "highlight.js/styles/xcode.css";

// Estilos
import { FormWrapper, ScreenTitle, ButtonBlueDefault } from "styles/Global";
import { WrapperUpdateFields, WrapperTriggers } from "./styles";

// Componentes
import ModalNewTrigger from "components/home/connections/ModalNewTrigger";

export default function ViewConnection() {
  const params = useParams();
  const { Option } = Select;

  // Notificações
  const { openNotification } = useNotification();

  // Estados
  const [connection, setConnection] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [modalNewTriggerOpened, setModalNewTriggerOpened] = useState(false);
  const [titleWasNull, setTitleWasNull] = useState(false);

  // Campo padrão
  const [codeAddForm] = useState(
    `<script src="https://npsapp.com.br/scripts/npsapp_embeddedPage_v1.js"></script>`
  );

  // Formulario
  const [title, setTitle] = useState("");
  const [useCustomUrl, setUseCustomUrl] = useState(false);
  const [customUrl, setCustomUrl] = useState("");
  const [statusTrigger, setStatusTrigger] = useState(5);
  const [statusToChange, setStatusToChange] = useState(5);
  const [syncDeskRatings, setSyncDeskRatings] = useState(false);
  const [syncChatRatings, setSyncChatRatings] = useState(false);
  const [deskApiKey, setDeskApiKey] = useState("");
  const [chatApiKey, setChatApiKey] = useState("");

  const [chatApiKeyPlaceholder, setUsingChatApiKeyPlaceholder] = useState(
    "Chave de API Freshdesk Messaging"
  );
  const [deskApiKeyPlaceholder, setUsingDeskApiKeyPlaceholder] = useState(
    "Chave de API Freshdesk"
  );

  function validateUrl(url) {
    var regexp =
      /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(url);
  }

  function onSave(data) {
    let formUpdateConnectionsonfo = {
      title: title,
      customPageUrl: customUrl,
    };

    // Validações dos campos do formulário
    if (useCustomUrl) {
      if (customUrl === "" || !validateUrl(customUrl)) {
        openNotification({
          message: "Valor inválido",
          description: "Preencha corretamente a url da sua página.",
          status: "info",
        });
        return;
      }
    } else {
      formUpdateConnectionsonfo.customPageUrl = "";
    }
    if (title === "" && !titleWasNull) {
      openNotification({
        message: "Valor inválido",
        description: "Preencha corretamente o título da sua página.",
        status: "info",
      });
      return;
    }

    // Status que ativa envio
    if (connection.type === "desk") {
      formUpdateConnectionsonfo.statusTrigger = statusTrigger;
    }

    // Campos de chave de API
    if (syncChatRatings && chatApiKey === "") {
      openNotification({
        message: "Valor inválido",
        description:
          "Preencha corretamente a chave da sua API Freshdesk Messaging.",
      });
      return;
    }

    if (syncDeskRatings && deskApiKey === "") {
      openNotification({
        message: "Valor inválido",
        description: "Preencha corretamente a chave da sua API Freshdesk.",
      });
      return;
    }

    // informações das chaves de API
    formUpdateConnectionsonfo.deskApiKey = deskApiKey;
    formUpdateConnectionsonfo.chatApiKey = chatApiKey;

    API({
      window: window,
      method: "PATCH",
      url: `connections/inf/${params.id}`,
      data: formUpdateConnectionsonfo,
    })
      .then((response) => {
        openNotification({
          message: "Sucesso",
          description: "As novas informações da conexão foram salvas.",
          status: "success",
        });
      })
      .catch((error) => {
        openNotification({
          message: "Erro",
          description: "Não foi possível salvar as informações.",
          status: "error",
        });
        console.log(error);
      });
  }

  function deleteTrigger(trigger) {
    API({
      window: window,
      method: "DELETE",
      url: `connections/${params.id}/trigger/${trigger}`,
    })
      .then((response) => {
        openNotification({
          message: "Sucesso",
          description: "O gatilho da conexão foi removido.",
          status: "success",
        });
        loadConnection();
      })
      .catch((error) => {
        openNotification({
          message: "Erro",
          description: "Não foi possível remover o gatilho.",
          status: "error",
        });
        console.log(error);
      });
  }

  function loadConnection() {
    const { id } = params;
    API({ method: "GET", url: `connections/${id}` }).then((connectionData) => {
      setConnection(connectionData);
      setUseCustomUrl(connectionData.customPageUrl !== "");

      if (connectionData.customPageUrl !== "")
        setCustomUrl(connectionData.customPageUrl);

      if (connectionData.title === "") {
        setTitleWasNull(true);
      } else {
        setTitle(connectionData.title);
      }
      if (connectionData.usingDeskSync) {
        setSyncDeskRatings(true);
        setUsingDeskApiKeyPlaceholder(
          "Chave já registrada, insira outra chave para alterar"
        );
      }
      if (connectionData.usingChatSync) {
        setSyncChatRatings(true);
        setUsingChatApiKeyPlaceholder(
          "Chave já registrada, insira outra chave para alterar"
        );
      }

      setStatusTrigger(connectionData.statusTrigger || 5);
      setStatusToChange(connectionData.statusToChange || 5);
    });
  }

  function openModalExcludeTrigger(trigger) {
    Modal.confirm({
      title: "Excluir gatilho?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Ao excluir a ação não será mais ativada.\n Ainda será nescessário atualizar as informações no aplicativo.",
      okText: "Excluir",
      cancelText: "Cancelar",
      onOk: () => {
        console.log(trigger);
        deleteTrigger(trigger);
      },
    });
  }

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
    loadConnection();
  });

  return (
    <>
      <FormWrapper style={{ height: "auto" }}>
        <ScreenTitle>
          <span>
            <FontAwesomeIcon icon={faNetworkWired} />
          </span>
          {connection.name}
        </ScreenTitle>
        <div onSubmit={onSave}>
          <WrapperUpdateFields>
            <div className="fields-update">
              <div className="wrapper-inputs">
                <div className="field-form  mb-3 pt-1 rounded ">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="name"
                  >
                    Título
                  </label>
                  <input
                    className="input-form shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                    id="name"
                    type="text"
                    placeholder="Título descritivo"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                {connection.type === "desk" ? (
                  <div>
                    <div className="field-form  mb-3 pt-1 rounded ">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="statusTrigger"
                      >
                        Enviar avaliação quando o status do ticket mudar para:
                      </label>
                      <Select
                        defaultValue={statusTrigger}
                        id="statusTrigger"
                        style={{
                          width: 300,
                          borderRadius: "0.25rem",
                        }}
                        className="select-default"
                        onChange={(value) => setStatusTrigger(value)}
                      >
                        {connection.ticketStatuses.map((status) => (
                          <Option value={status.code}>{status.name}</Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className=" obs-chat-sending block text-gray-700 text-sm font-bold mb-2">
                      <div className="asterisk">*</div>
                      Por padrão, a avaliação será enviada quando a conversa for
                      resolvida.
                    </div>
                  </>
                )}
                <div className="field-form  mb-3 pt-1 rounded ">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="radio-yes-desksync"
                  >
                    Sincronizar com avaliações do Freshdesk
                  </label>
                  <label
                    className="input-radio-wrapper mb-2"
                    htmlFor="radio-yes-desk-sync"
                  >
                    <input
                      id="radio-yes-desk-sync"
                      type="radio"
                      checked={syncDeskRatings}
                      onChange={(e) => setSyncDeskRatings(e.target.checked)}
                    />
                    Sim
                  </label>
                  <label
                    htmlFor="radio-no-desk-sync"
                    className="input-radio-wrapper "
                  >
                    <input
                      id="radio-no-desk-sync"
                      type="radio"
                      checked={!syncDeskRatings}
                      onChange={(e) => {
                        setSyncDeskRatings(!e.target.checked);
                        setDeskApiKey("");
                      }}
                    />
                    Não
                  </label>
                </div>
                {syncDeskRatings && (
                  <div className="dynamic-field-form">
                    <textarea
                      className="input-form shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                      id="api-key-desk"
                      placeholder={deskApiKeyPlaceholder}
                      value={deskApiKey}
                      onChange={(e) => setDeskApiKey(e.target.value)}
                    />
                  </div>
                )}
                <div className="field-form  mb-3 pt-1 rounded ">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="radio-yes-desk-sync"
                  >
                    Sincronizar com avaliações do Freshdesk Messaging
                  </label>
                  <label
                    className="input-radio-wrapper mb-2"
                    htmlFor="radio-yes-chat-sync"
                  >
                    <input
                      id="radio-yes-chat-sync"
                      type="radio"
                      checked={syncChatRatings}
                      onChange={(e) => setSyncChatRatings(e.target.checked)}
                    />
                    Sim
                  </label>
                  <label
                    htmlFor="radio-no-chat-sync"
                    className="input-radio-wrapper "
                  >
                    <input
                      id="radio-no-chat-sync"
                      type="radio"
                      checked={!syncChatRatings}
                      onChange={(e) => {
                        setChatApiKey("");
                        setSyncChatRatings(!e.target.checked);
                      }}
                    />
                    Não
                  </label>
                </div>
                {syncChatRatings && (
                  <div className="dynamic-field-form">
                    <textarea
                      className="input-form shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                      id="api-key-chat"
                      placeholder={chatApiKeyPlaceholder}
                      value={chatApiKey}
                      onChange={(e) => setChatApiKey(e.target.value)}
                    />
                  </div>
                )}
              </div>
              <div className="wrapper-field-code">
                <div className="field-form  mb-3 pt-1 rounded ">
                  <div className="block text-gray-700 text-sm font-bold mb-2">
                    Disponibilizar formulários em:
                  </div>
                  <label
                    htmlFor="radio-internal-page"
                    className="input-radio-wrapper mb-2"
                  >
                    <input
                      id="radio-internal-page"
                      type="radio"
                      checked={!useCustomUrl}
                      onChange={(e) => setUseCustomUrl(!e.target.checked)}
                    />
                    Uma página do NPS-App
                  </label>
                  <label
                    htmlFor="radio-custom-page"
                    className="input-radio-wrapper "
                  >
                    <input
                      id="radio-custom-page"
                      type="radio"
                      checked={useCustomUrl}
                      onChange={(e) => setUseCustomUrl(e.target.checked)}
                    />
                    Na minha própria página
                  </label>
                </div>
                <div className="field-form  mb-3 pt-1 rounded ">
                  <label
                    className={`block text-gray-700 text-sm font-bold mb-2 ${
                      useCustomUrl ? "" : "disabled-title"
                    }`}
                    htmlFor="name"
                  >
                    Url da página
                  </label>
                  <input
                    className={`input-form shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight ${
                      useCustomUrl ? "" : "disabled-input"
                    }`}
                    id="name"
                    disabled={!useCustomUrl}
                    type="url"
                    name="customUrl"
                    placeholder="http://url da sua página.com"
                    value={customUrl}
                    onChange={(e) => setCustomUrl(e.target.value)}
                  />
                </div>
                <div
                  className={`block text-gray-700 text-sm font-bold mb-2 ${
                    useCustomUrl ? "" : "disabled-title"
                  }`}
                >
                  Página de visualização do formulário
                </div>
                <div className="code-field-wrapper">
                  <CodeHighlight
                    language="html"
                    classes={{ pre: "pre-code-view" }}
                    className="input-form shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight code-view-input"
                  >
                    {codeAddForm}
                  </CodeHighlight>
                </div>
                <div className="obs-code">
                  <div className="asterisk">*</div>
                  Para que os formulários sejam exibidos, adicione o techo acima
                  no código da sua página.
                </div>
              </div>
            </div>
            <div className="wrapper-button-save">
              <ButtonBlueDefault onClick={onSave}>Salvar</ButtonBlueDefault>
            </div>
          </WrapperUpdateFields>
        </div>

        <WrapperTriggers>
          <div className="title-box-triggers">Gatilhos</div>
          <div className="wrapper-triggers">
            <div className="wrapper-triggers-box">
              {connection.triggers &&
                connection.triggers.length > 0 &&
                connection.triggers.map((trigger) => (
                  <div className="trigger-box trigger">
                    <div className="trigger-box-params">
                      <div className="header-box-trigger">
                        <div className="title-params">Quando</div>
                        <div
                          className="icon-remove-trigger"
                          onClick={() => openModalExcludeTrigger(trigger._id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                      </div>
                      <div className="wrapper-items">
                        {trigger.params.map((condition) => (
                          <div className="trigger-box-params-item">
                            <div className="condition">
                              <div className="condition-piece">
                                {
                                  condition.field.split(".")[
                                    condition.field.split(".").length - 1
                                  ]
                                }
                              </div>
                              <div className="conective-text">for igual a</div>
                              <div className="condition-piece">
                                {connection.type === "desk" ||
                                condition.value === "all"
                                  ? condition.value
                                  : connection.chatGroups.find(
                                      (group) => group.code === condition.value
                                    ).name}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="title-params second-title">
                      Usar campanha
                    </div>
                    <div className="campaign-name-wrapper">
                      {trigger.campaign.name}
                    </div>
                  </div>
                ))}
              <div
                className="trigger-box new-trigger"
                onClick={() => setModalNewTriggerOpened(true)}
              >
                <div className="icon-new">
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                Novo gatilho de campanha
              </div>
            </div>
          </div>
        </WrapperTriggers>
      </FormWrapper>

      <ModalNewTrigger
        isOpen={modalNewTriggerOpened}
        onRequestClose={() => setModalNewTriggerOpened(false)}
        connection={connection}
        reloadConnection={loadConnection}
      />
    </>
  );
}
