import styled from "styled-components";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-height: 100%;
  height: auto;
  padding: 0;

  .form-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 100%;
    padding-bottom: 10px;
    padding: 0 0 2% 0;
  }

  .screen-title {
    font-size: 1.5rem;
    margin-bottom: 3px;
    display: flex;
    padding: 0.3rem 1rem;
    border-bottom: 1px solid #e0e0e0;
  }
  .screen-title span {
    color: #9cbbd8;
    margin-right: 0.5rem;
  }
  .wrapper-paginator {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .notFound-message-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;

    .message-nnotFound {
      font-weight: bold;
      font-size: 1.2rem;
      margin: 20px 0;
    }
  }
`;
