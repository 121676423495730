import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

// Components
import Footer from "components/landings/footer";

// Fonts
import "assets/fonts/fonts.css";

//Styles
import { FormRedirectAccount, WrapperPageRegister } from "./styles";
import { ButtonBlueDefault } from "styles/Global";

// Images
import LogoNPS from "assets/LogoNpsAPPV2.png";
import { API } from "services/api";
import { useNotification } from "contexts/session";

export default function Register(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loginError] = useState("");
  const [checkingSession, setCheckeingsession] = useState(false);
  const { openNotification } = useNotification();

  function openNotificationError(typeError) {
    const descriptionsErrors = {
      notFilled: "Preencha os campos antes de continuar",
      loginInformations: "Confira as informações de login e senha.",
      unableToRedirect: "Não foi possível acessar sua conta. Tente novamente.",
      notRegistered:
        "A conta não foi encontrada. Verifique o nome da organização.",
    };

    openNotification({
      message: "Oops! Não deu certo",
      description:
        descriptionsErrors[typeError] || "Ocorreu um erro, tente novamente.",
      status: "error",
    });
  }

  const validateTenant = (subdomain) => {
    return new Promise((res, rej) => {
      const headers = {
        Authorization: subdomain,
      };

      API({
        window: window,
        method: "GET",
        url: "auth/validateTenant",
        headers: headers,
      })
        .then((resp) => {
          if (resp) {
            res("Success");
          } else {
            res("notRegistered");
          }
        })
        .catch((error) => {
          console.log("NETWORK ERROR");
          rej("notRegistered");
          console.log(error);
        });
    });
  };

  const onSubmit = (data) => {
    const subdomain = data.subdomain;
    validateTenant(subdomain)
      .then((status) => {
        if (status === "Success") {
          window.location.href = `${process.env.REACT_APP_FRONT_PROTOCOL}://${subdomain}.${process.env["REACT_APP_FRONT_URL"]}/login`;
        } else {
          openNotificationError(status);
        }
      })
      .catch(() => {
        openNotificationError("notRegistered");
      });
  };

  const checkErrors = () => {
    if (Object.keys(errors).length > 0) {
      openNotificationError("notFilled");
    }
  };

  useEffect(() => {
    if (!checkingSession) {
      setCheckeingsession(true);
      const tenantId = window.location.origin.split(".")[0].split("//")[1];
      validateTenant(tenantId).then((status) => {
        if (status === "Success") {
          window.location.href = `${process.env.REACT_APP_FRONT_PROTOCOL}://${tenantId}.${process.env["REACT_APP_FRONT_URL"]}/login`;
        }
      });
    }
  });

  return (
    <WrapperPageRegister className="gradient  min-h-screen pt-5 pb-6 px-2 md:px-0">
      <header className="max-w-lg mx-auto justify-center flex mt-12 md:mt-12 mb-10 md:mb-10">
        <Link to="/">
          <div className="m-0 p-0 cursor-pointer">
            <img
              className="logo"
              alt="Home"
              src={LogoNPS}
              width="200"
              height="50"
            />
          </div>
        </Link>
      </header>

      <main className="bg-white max-w-lg mx-auto px-8 pb-8 md:px-12 md:pb-8 md:pt-5  pt-5 mb-4 mt-5 rounded-lg shadow-2xl box-form-window">
        <section className="justify-center flex mb-3 md:mb-4">
          <h3 className="font-bold text-2xl">Bem vindo!</h3>
        </section>

        <section className="mt-2">
          <FormRedirectAccount
            className="flex flex-col"
            onSubmit={handleSubmit(onSubmit)}
          >
            {loginError}
            <div className="mb-5 pt-1 rounded ">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="dominio"
              >
                Domínio
              </label>
              <div className="flex items-center py-2">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="dominio"
                  type="text"
                  placeholder="seu-dominio"
                  {...register("subdomain", { required: true })}
                />
                <span className="ml-1">.npsapp.com</span>
              </div>
            </div>
            <div className="wrapper-button">
              <ButtonBlueDefault type="submit" onClick={checkErrors}>
                Entrar
              </ButtonBlueDefault>
            </div>
          </FormRedirectAccount>
        </section>
      </main>

      <div className="max-w-lg mx-auto text-center mt-7 mb-6">
        <p className="text-white">
          Ainda não tem uma conta?{" "}
          <Link to="/register" className="font-bold hover:underline">
            Registrar-se
          </Link>
        </p>
        <Footer />
      </div>
    </WrapperPageRegister>
  );
}
