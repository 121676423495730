import React, { useEffect, useState } from "react";
import { API } from "services/api.js";

// Styles
import { NoConnectionsWrapper, WrapperConnections } from "./styles.js";
import { PageWrapper } from "styles/ListItems";
import { ScreenTitle } from "styles/Global";

// Images
import LogoFreshdesk from "assets/LogoFreshdesk.png";
import LogoFreshdeskMessaging from "assets/LogoFreshdeskMessaging.png";
import LogoAPI from "assets/LogoAPI.png";

// Icones
import { faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SkeletonConnections from "components/home/global/skeletons/skeletonConnections/index.js";
import Connection from "components/home/connections/Connection";

export default function Connections() {
  // Connections list
  const [connections, setConnections] = useState([]);
  const [loadedConnections, setLoadedConnections] = useState(false);
  const [loadedConnectionsList, setLoadedConnectionsList] = useState(false);

  useEffect(() => {
    if (loadedConnections) return;
    setLoadedConnections(true);
    API({ method: "GET", url: "connections" })
      .then((data) => {
        console.log(data);
        data.map((connection) => {
          if (connection.type === "desk") {
            connection.logo = LogoFreshdesk;
            connection.toolName = "Freshdesk";
          } else if (connection.type === "chat") {
            connection.logo = LogoFreshdeskMessaging;
            connection.toolName = "Freshdesk Messaging";
          } else {
            connection.logo = LogoAPI;
            connection.toolName = "Custom API";
          }
          return connection;
        });
        setLoadedConnectionsList(true);
        setConnections(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [connections]);

  const NoConnections = (
    <NoConnectionsWrapper>
      <h1>Nenhuma conexão registrada</h1>
      <div className="no-con-box-instructions shadow-2xl">
        <div className="title-box">
          Configure suas conexões para criar rotinas de envio de avaliações.
        </div>
      </div>
    </NoConnectionsWrapper>
  );

  return (
    <>
      <PageWrapper>
        <ScreenTitle>
          <span>
            <FontAwesomeIcon icon={faNetworkWired} />
          </span>
          Conexões
        </ScreenTitle>
        {loadedConnectionsList ? (
          <>
            {connections.length > 0 ? (
              <>
                <WrapperConnections>
                  {connections.map((connection, idx) => (
                    <Connection connection={connection} key={idx} />
                  ))}
                </WrapperConnections>
              </>
            ) : (
              NoConnections
            )}
          </>
        ) : (
          <SkeletonConnections />
        )}
      </PageWrapper>
    </>
  );
}
