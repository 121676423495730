import styled from "styled-components";

export const FormRegisterForm = styled.form`
  .editorWrapper {
    width: 100%;
  }
  .option-languege {
    height: 90px;
    border: none;
  }
  .box-preview {
    border: 1px solid rgba(40, 40, 40, 0.2);
    border-radius: 3px;
    padding: 5px;
    min-height: 20px;
  }
  .text-comment-quests {
    color: #4f4f4f;
  }

  .confirm-wrapper {
    display: flex;
    justify-content: center;
  }

  .btn-form {
    width: 100px;
  }
`;

export const QuestionsWrapper = styled.div`
  .button-add-wrapper {
    display: flex;

    width: 100%;

    .wrapper-questions {
    }

    button {
      background-color: #00adac;
      color: white;
      font-weight: bold;
      padding: 5px 10px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      .icon {
        margin-left: 5px;
      }
    }
  }
`;
