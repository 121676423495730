import React from "react";
import { ButtonBlueDefault } from "styles/Global";
import { ViewUserWrapper } from "./styles";
import { Modal } from "antd";

// Icones
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function ViewForm(props) {
  const { userData, deleteUser } = props;

  function openModalExcludeUser() {
    Modal.confirm({
      title: "Excluir usuário?",
      icon: <ExclamationCircleOutlined />,
      content:
        "O usuário perderá acesso a ferramenta e seus dados pessoais serão perdidos.",
      okText: "Excluir",
      cancelText: "Cancelar",
      onOk: () => {
        deleteUser();
      },
    });
  }

  return (
    <ViewUserWrapper>
      <div className="image-wrapper">
        <img
          src={`https://ui-avatars.com/api/?name=${userData.name.replace(
            " ",
            "+"
          )}&background=82bec2&size=400`}
          alt="Perfil"
        />
      </div>
      <div className="user-info">
        <div className="user-fields">
          <div className="mb-3 pt-1 rounded half-input">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Nome
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              readOnly
              type="name"
              placeholder="Nome do usuário"
              value={userData.name}
            />
          </div>
          <div className="mb-3 pt-1 rounded half-input">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              E-mail
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              readOnly
              type="name"
              placeholder="Nome do usuário"
              value={userData.email}
            />
          </div>
        </div>
        <div className="user-buttons">
          {/* <ButtonBlueDefault>Editar</ButtonBlueDefault> */}
          <ButtonBlueDefault
            style={{ backgroundColor: "#CD6155" }}
            onClick={openModalExcludeUser}
          >
            Excluir
          </ButtonBlueDefault>
        </div>
      </div>
    </ViewUserWrapper>
  );
}
