import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  .wrapper-skeletons {
    display: flex;
    gap: 30px;
    .card {
      margin-top: 20px;
    }
  }
`;
