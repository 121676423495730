import axios from "axios";
import { parseCookies } from "nookies";

/**
 *
 * @param method - method to call
 * @param url - url to call
 * @param data - data to send
 * @param headers - headers to send
 */
export function API(props) {
  const { method, url, data, headers } = props;
  const cookies = parseCookies();
  const { JWT } = cookies;
  return new Promise((resolve, reject) => {
    const requestUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env["REACT_APP_BACK_URL"]}/${url}`;
    const _headers = {
      headers: headers || {
        Authorization: `Bearer ${JWT}`,
      },
    };

    let apiCall;
    if (method === "POST") {
      apiCall = axios.post(requestUrl, data, _headers);
    } else if (method === "PATCH") {
      apiCall = axios.patch(requestUrl, data, _headers);
    } else if (method === "GET") {
      apiCall = axios.get(requestUrl, _headers);
    } else if (method === "DELETE") {
      apiCall = axios.delete(requestUrl, _headers);
    } else {
      console.log(`Unknow request method. url= ${url}`);
      reject("unknowRequestMethod");
    }
    apiCall
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
