import styled from "styled-components";

export const ViewUserWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  padding: 20px;
  width: 100%;
  height: 70%;
  .image-wrapper {
    img {
      width: 20rem;
      border-radius: 50%;
    }
  }
  .user-info {
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .user-buttons {
      display: flex;
      padding: 0 10%;
      justify-content: space-between;
      button {
        width: 40%;
      }
    }
    .half-input {
      width: 60%;
      @media (max-width: 890px) {
        width: 100%;
      }
    }
  }
`;
