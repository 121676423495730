import React from "react";
import { Outlet } from "react-router";

// Components
import Layout from "./layout";

export default function AdminTool() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}
