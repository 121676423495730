import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box } from "styles/Global";

// Styles
import { CheckoutPageContainer } from "./styles";

function CheckoutPage() {
  const params = useParams();
  useEffect(() => {}, []);

  return (
    <CheckoutPageContainer className="gradient">
      <div className="wrapper-form">
        <Box className="box-form">
          <h1>Checkout</h1>
        </Box>
      </div>
    </CheckoutPageContainer>
  );
}

export default CheckoutPage;
