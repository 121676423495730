import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { ScreenTitle } from "styles/Global";
import { PageWrapper } from "styles/ListItems";

import FormNewUser from "components/home/admin/users/createForm";

export default function New() {
  return (
    <PageWrapper>
      <ScreenTitle>
        <span>
          <FontAwesomeIcon icon={faUser} />
        </span>
        Novo Usuário
      </ScreenTitle>
      <FormNewUser />
    </PageWrapper>
  );
}
