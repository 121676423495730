import styled from "styled-components";

export const FooterPageRegisters = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  .footer-logo {
    margin-top: 20px;
  }
`;

export const BtnOptMenu = styled.button`
  background-image: linear-gradient(300deg, #6ad4e7 0%, #44a9bb 90%);
  float: right;
  color: white;
  font-weight: bold;
`;

export const ButtonBlueDefault = styled.button`
  font-family: "Roboto", sans-serif;
  color: white;
  font-weight: 600;
  padding: 0 30px;
  border-radius: 30px;
  height: 35px;
  background-color: #00adac;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  :hover {
    background-color: #00adac;
  }
`;

export const DivFontKarla = styled.div`
  font-family: karla;
`;

export const ScreenTitle = styled.div`
  font-size: 1.5rem;
  margin-bottom: 3px;
  display: flex;
  padding: 0.3rem 1rem;
  border-bottom: 1px solid #e0e0e0;

  span {
    color: #9cbbd8;
    margin-right: 0.5rem;
  }
`;

export const FormWrapper = styled.div`
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0 0 2% 0;
`;

export const InputSearch = styled.div`
  display: flex;
  align-items: center;
  input {
    width: 90%;
    z-index: 4;
    border-right: 1px solid #ffff;
  }
  button {
    width: 10%;
    padding: 5px 2px;
    border-radius: 0 5px 5px 0;
    margin-left: 0px;
    z-index: 0;
  }
`;

export const ResultList = styled.div`
  position: absolute;
  width: 90%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 5px 5px;
  .result-item {
    width: 100%;
    background-color: white;
    margin: 2px 0;
    padding: 8px 5px;
    cursor: pointer;
    z-index: 0;
  }
  .result-item:hover {
    background-color: #f5f5f5;
  }
`;

export const TableListWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

export const Box = styled.div`
  padding: 1rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

export const InputDefault = styled.input`
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  appearance: none;
  border-width: 1px;
  border-radius: 0.25rem;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  color: rgba(55, 65, 81, 1);
  line-height: 1.25;
  outline: 2px solid transparent;
  outline-offset: 2px;
`;
