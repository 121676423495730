import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { SearchOutlined, PlusCircleOutlined } from "@ant-design/icons";

import { API } from "services/api";
import { ModalInserItemWrapper } from "./styles";

export default function ModalInserItem(props) {
  const { component, newItem, visible, onOk, onCancel } = props;
  const [titleModal, setTitleModal] = useState("");

  const [searchField, setSearchField] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  // Dados
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [countItems, setCountItems] = useState(0);

  function findComponents(component) {
    if (component === "") return;
    setItems([]);
    setFilteredItems([]);
    setCountItems(0);
    API({
      window: window,
      method: "GET",
      url: `${component}?page=1&per_page=10`,
    })
      .then((response) => {
        console.log(response);
        setItems(response.data);
        setFilteredItems(response.data);
        setCountItems(response.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    let componentDict = {
      emailTemplate: {
        title: "Adicionar template de e-mail",
        endpoint: "templates",
      },
      chatTemplate: {
        title: "Adicionar template de chat",
        endpoint: "chat-template",
      },
      form: { title: "Adicionar formulário", endpoint: "forms" },
    };
    setTitleModal(
      componentDict[component] ? componentDict[component].title : ""
    );
    findComponents(
      componentDict[component] ? componentDict[component].endpoint : ""
    );
  }, [component]);

  function filteritems(value) {
    let newItems;
    if (value === "") newItems = items;
    else
      newItems = items.filter((language) =>
        language.name.toLowerCase().includes(value.toLowerCase())
      );
    setFilteredItems(newItems);
  }

  return (
    <Modal
      title={titleModal}
      centered
      width={700}
      visible={visible}
      onOk={() => onOk(selectedItem)}
      onCancel={onCancel}
    >
      <ModalInserItemWrapper>
        <div className="search-field">
          <input
            className="input-form shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            placeholder="Nome do componente"
            value={searchField}
            autoComplete="off"
            onChange={(e) => {
              setSearchField(e.target.value);
              filteritems(e.target.value);
            }}
          />
          <div className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline icon-search">
            <SearchOutlined />
          </div>
          <div
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline icon-new"
            onClick={() => newItem(component)}
          >
            <div className="icon-add">
              <PlusCircleOutlined />
            </div>
            Criar novo
          </div>
        </div>
        <div className="select-items">
          {filteredItems &&
            filteredItems.map((item, index) => (
              <div
                key={index}
                className={`item ${item === selectedItem ? "selected" : ""}`}
                onClick={() => setSelectedItem(item)}
              >
                {item.name}
                <div className="id-wrapper">id: {item.id}</div>
              </div>
            ))}
        </div>
      </ModalInserItemWrapper>
    </Modal>
  );
}
