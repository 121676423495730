import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import Landing from "pages/public/landing";
import YourSpace from "pages/public/yourSpace";
import Register from "pages/public/register";
import AccountRegistration from "pages/public/confirmRegistrationTenant";
import PurchasePage from "pages/private/purchase";
import ToolAdmin from "pages/admin";
import ToolAdminDashboard from "pages/admin/dashboard";
import PricesPage from "pages/private/purchase/prices";
import CheckoutPage from "pages/private/purchase/checkout";

// Rotas que não dependem de uma sessão ativa
export default function PublicRoutes(props) {
  const navigate = useNavigate();
  useEffect(() => {
    if (props.route) {
      navigate(props.route);
    }
  }, []);
  return (
    <Routes>
      <Route path="" element={<Landing />} />
      <Route path="register" element={<Register />} />
      <Route path="yourSpace" element={<YourSpace />} />
      <Route path="account/confirm/:token" element={<AccountRegistration />} />
      <Route path="purchase" element={<PurchasePage />}>
        <Route path="" element={<PricesPage />} />
        <Route path="checkout/:id" element={<CheckoutPage />} />
      </Route>
      <Route path="admin" element={<ToolAdmin />}>
        <Route path="" element={<ToolAdminDashboard />} />
      </Route>
      <Route path="*" element={<Landing />} />
    </Routes>
  );
}
