import styled from "styled-components";

export const QuestionStyle = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;

  .wrapper-question {
    width: 100%;
    .wrapper-input {
      display: flex;
      width: 70%;
      align-items: center;
      button {
        font-size: 1.1rem;
        margin-left: 5px;
        background-color: #ff0000;
        height: fit-content;
        color: white;
        padding: 9px 12px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .required-checkbox-wrapper {
      margin-top: 10px;
    }
  }
`;
