import React from "react";
import { useNavigate } from "react-router-dom";

// Fonts
import "assets/fonts/fonts.css";

// Images
import LogoLoupenAzul from "assets/LogoLoupenAzul.png";

import LogoNPS from "assets/LogoNpsAPPV2.png";
import PlansBanner from "components/purchase/plansBanner";

// Styles
import { LandingBanner, NavbarLanding, LandingWrapper } from "./styles";

export default function Landing() {
  let navigate = useNavigate();
  const [registerEmail, setRegisterEmail] = React.useState("");

  function goToRegister() {
    localStorage.setItem("registerEmail", registerEmail);
    navigate("/register");
  }

  return (
    <LandingWrapper className="gradient">
      <NavbarLanding>
        <div className="wrapper-nav">
          <div className="wrapper-logo-navbar">
            <img src={LogoLoupenAzul} className="logoNavbar" alt="" />
          </div>
          <div className="navbar-box-login">
            <button onClick={() => navigate("/yourSpace")}>Entrar</button>
          </div>
        </div>
      </NavbarLanding>

      {/* Banner inicial */}
      <LandingBanner>
        <div className="banner-landing-box-logo">
          <img src={LogoNPS} alt="logo" />
        </div>
        <div className="banner-landing-text">
          Veja sua empresa pelos olhos dos seus clientes.
        </div>

        <div className="banner-landing-box-form">
          <div className="banner-landing-form">
            <div className="banner-landing-form-title">Começar agora</div>
            <input
              value={registerEmail}
              onChange={(e) => setRegisterEmail(e.target.value)}
              type="text"
              placeholder="Seu E-mail"
            />
            <div className="banner-landing-form-box-button">
              <button onClick={goToRegister}>Continuar</button>
            </div>
          </div>
        </div>
      </LandingBanner>
      {/* <PlansBanner /> */}
    </LandingWrapper>
  );
}
