import axios from "axios";

export const getSubdomain = () => {
  const url = new URL(window.location.origin);
  const subdomain = url.hostname.split(".")[0];
  return ["home", "localhost", "www.", "npsapp"].includes(subdomain)
    ? ""
    : subdomain;
};

export const navigateToHome = () => {
  window.location.href = `${process.env["REACT_APP_FRONT_PROTOCOL"]}://${process.env["REACT_APP_FRONT_URL"]}`;
};

export const getTenantContext = (tenantId) => {
  return new Promise((resolve, reject) => {
    if (!tenantId) resolve(false);
    const requestUrl = `${process.env["REACT_APP_API_PROTOCOL"]}://${process.env["REACT_APP_BACK_URL"]}/tenants/validate`;

    axios
      .get(requestUrl, {
        headers: {
          Authorization: tenantId,
        },
      })
      .then((res) => {
        if (res.data.status === "Not Found") return reject(undefined);
        resolve(res.data.status);
      })
      .catch(() => {
        reject(false);
      });
  });
};
