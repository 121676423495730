import React from "react";
import { Link } from "react-router-dom";

import LogoLoupenBranca from "../../assets/LogoLoupenBranca.png";
import { FooterPageRegisters } from "../../styles/Global";

export default function Footer() {
  return (
    <FooterPageRegisters>
      <div className="wrapper-content-footer">
        <Link to="#" className="hover:underline">
          Contato
        </Link>
        <span className="mx-3">•</span>
        <Link to="#" className="hover:underline">
          Política
        </Link>
      </div>
      <div className="footer-logo">
        <a href="https://loupen.com.br">
          <img src={LogoLoupenBranca} alt="Loupen" />
        </a>
      </div>
    </FooterPageRegisters>
  );
}
