import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { API } from "../../../../../services/api";
import { useNotification } from "../../../../../contexts/session";
import { useNavigate } from "react-router-dom";

// Styles
import { FormNewUserWrapper } from "./styles";
import { ButtonBlueDefault } from "../../../../../styles/Global";

export default function FormNewUser() {
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm();
  const navigate = useNavigate();

  const [sendingEmail, setSendingEmail] = useState(false);

  const { openNotification } = useNotification();

  function onSubmit(data) {
    let tenantId = window.location.origin.split(".")[0].split("//")[1];
    setSendingEmail(true);
    let emailUserData = {
      email: data.email,
      name: data.name,
      tenant: tenantId,
    };

    console.log(data);
    API({
      window: window,
      method: "POST",
      url: "users/send-email",
      data: emailUserData,
    })
      .then((res) => {
        if (res === "Duplicated") {
          openNotification({
            message: "Erro",
            description: "O e-mail já está sendo usado",
            status: "error",
          });
          setSendingEmail(false);

          return;
        }
        if (res === "Error") {
          openNotification({
            message: "Erro",
            description: "Erro ao registrar usuário",
            status: "error",
          });
          setSendingEmail(false);

          return;
        }
        openNotification({
          message: "Sucesso",
          description: "Um e-mail de confirmação foi enviado ao usuário",
          status: "success",
        });
        navigate("/admin/users");
        console.log(res);
        setSendingEmail(false);
      })
      .catch((err) => {
        openNotification({
          message: "Erro",
          description: "O e-mail já está sendo usado",
          status: "error",
        });
        console.log(err);
        setSendingEmail(false);
      });
  }

  function checkErrors() {
    if (Object.keys(errors).length > 0)
      openNotification({
        message: "Preencha os campos",
        description: "Todos os campos precisam ser preenchidos",
        status: "info",
      });
  }

  return (
    <FormNewUserWrapper onSubmit={handleSubmit(onSubmit)}>
      <div className="fields-wrapper">
        <div className="fields-box">
          <div className="mb-3 pt-1 rounded half-input">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Nome
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="name"
              placeholder="Nome do usuário"
              {...register("name", {
                required: true,
              })}
            />
          </div>
          <div className="mb-3 pt-1 rounded half-input">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              E-mail
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="novousuario@email.com"
              {...register("email", { required: true })}
            />
          </div>
        </div>
      </div>
      <div className="wrapper-button">
        <ButtonBlueDefault
          onClick={checkErrors}
          type="submit"
          disabled={sendingEmail}
          className={` ${sendingEmail && "disabled"}`}
        >
          Registrar
        </ButtonBlueDefault>
      </div>
    </FormNewUserWrapper>
  );
}
