import React from "react";
import { Link } from "react-router-dom";
import { BtnOptMenu } from "../../../../../../styles/Global";

import { ItemWrapper } from "./styles";

export default function ItemUser(props) {
  const { name, accesslevel, email } = props;
  return (
    <ItemWrapper className="text-gray-700">
      <td className="itemMiddle px-4 py-3 border">
        <div className="flex items-center text-sm">
          <span className="font-semibold text-black">{name}</span>
        </div>
      </td>
      <td className="itemMiddle px-4 py-3 text-md  border">{email}</td>
      <td className="itemMiddle px-4 py-3 text-md  border">{accesslevel}</td>

      <td className="px-4 py-3 text-sm border">
        <div className="flex justify-center">
          <Link to={`${email}`}>
            <BtnOptMenu
              type="button"
              className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
            >
              Visualizar
            </BtnOptMenu>
          </Link>
        </div>
      </td>
    </ItemWrapper>
  );
}
