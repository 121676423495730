import React from "react";
import { HomeLayout } from "components/home/mainLayout";
import { Outlet, useLocation } from "react-router-dom";

export default function Home() {
  const location = useLocation();
  return (
    <>
      <HomeLayout page={location.pathname}>
        <Outlet />
      </HomeLayout>
    </>
  );
}
