import React from "react";

import { Skeleton } from "antd";
import { Container } from "./styles";

export default function SkeletonDashboard() {
  return (
    <Container>
      <div className="wrapper-skeletons">
        <div className="graph">
          <Skeleton.Input
            active={true}
            size="default"
            shape="default"
            block={false}
            style={{ width: 450, height: 380, borderRadius: "20px" }}
          />
        </div>
        <div>
          <div className="card">
            <Skeleton.Input
              active={true}
              size="default"
              shape="default"
              block={false}
              style={{
                width: 300,
                height: 100,
                borderRadius: "20px",
                marginRight: "30px",
              }}
            />
            <Skeleton.Input
              active={true}
              size="default"
              shape="default"
              block={false}
              style={{ width: 300, height: 100, borderRadius: "20px" }}
            />
          </div>
          <div className="card">
            <Skeleton.Input
              active={true}
              size="default"
              shape="default"
              block={false}
              style={{
                width: 300,
                height: 100,
                borderRadius: "20px",
                marginRight: "30px",
              }}
            />
            <Skeleton.Input
              active={true}
              size="default"
              shape="default"
              block={false}
              style={{ width: 300, height: 100, borderRadius: "20px" }}
            />
          </div>
          <div className="card">
            <Skeleton.Input
              active={true}
              size="default"
              shape="default"
              block={false}
              style={{
                width: 300,
                height: 100,
                borderRadius: "20px",
                marginRight: "30px",
              }}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
