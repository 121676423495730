import styled from "styled-components";

export const WrapperPage = styled.div`
  height: 85vh;
  background-color: #ffff;
  padding-top: 5px;
  font-family: karla;
  @media (max-width: 768px) {
    height: 90vh;
  }
  .ant-tabs-content {
    height: 100%;
  }
`;

export const DashboardWrapper = styled.div`
  height: 100%;
  .campaign-selector {
    padding-left: 10px;
    .selector-title {
      font-weight: bold;
      margin-right: 10px;
    }
  }
  .card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    margin: 5px;
    .card-title {
      padding: 5px 10px;
      margin: 0 1%;
      font-size: 1rem;
      border-bottom: 1px solid #e8e8e8;
      font-weight: bold;
      width: 98%;
      display: flex;
    }
  }
  .geral-graph {
    width: 100%;
    display: flex;
    padding-top: 5px;
    .pie-chart {
      width: 45%;
      height: 60vh;
      .select-ratingType-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
      .chart-body {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 90%;
      }
    }
    .informations {
      width: 50%;
      height: 100%;
      .informations-body {
        margin: 5px 10px;
        table {
          width: 100%;
          .main-information {
            font-weight: bold;
            font-size: 1.2rem;
            margin-bottom: 10px;
          }
          .information {
            width: 100%;
            td {
              width: 50%;
            }
          }
        }
      }
    }
    .npsNote {
      height: 100%;
      display: flex;
      @media (max-width: 930px) {
        width: 790%;
      }
      .grade-icon-wrapper {
        .note {
          margin: 10px 5px 0 10px;
          width: 80px;
          height: 80px;
          font-size: 2rem;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          color: white;
        }
      }
      .nps-class-name {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        font-size: 1.75rem;
        @media (max-width: 1090px) {
          font-size: 1.2rem;
          margin-left: 5px;
        }
      }
    }
  }
`;
