import styled from "styled-components";

export const WrapperUpdateFields = styled.div`
  padding: 10px 15px;

  .field-form {
    width: 300px;
  }
  .dynamic-field-form {
    width: 400px;
  }

  .input-radio-wrapper {
    display: flex;
    align-items: center;
    input {
      margin-right: 10px;
    }
  }
  .fields-update {
    display: grid;
    grid-template-columns: 10fr 2fr;

    .wrapper-inputs {
    }

    .wrapper-field-code {
      max-width: 100%;
    }

    .obs-code {
      padding: 0 10px;
      display: flex;
      color: rgb(100, 100, 100);
      .asterisk {
        color: rgba(255, 0, 0, 0.7);
        font-weight: bold;
        font-size: 1rem;
        margin-right: 5px;
      }
    }
  }
  .wrapper-button-save {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    button {
      width: 100px;
    }
  }
  .disabled-title {
    color: rgba(0, 0, 0, 0.4);
  }
  .disabled-input {
    cursor: not-allowed;
  }
  .pre-code-view {
    margin: 5px 0 !important;
  }
  .code-view-input {
    font-size: 0.7rem !important;
  }
  .obs-chat-sending {
    display: flex;
    width: 300px;
    margin-top: 30px;
    .asterisk {
      color: rgba(255, 0, 0, 0.7);
      margin-right: 5px;
    }
  }
`;

export const WrapperTriggers = styled.div`
  border-top: 1px solid #e6e6e6;
  margin: 0 10px;
  padding: 10px 15px;
  .title-box-triggers {
    font-family: karla;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 1.1rem;
  }
  .wrapper-triggers {
    display: flex;
    justify-content: center;
  }
  .wrapper-triggers-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    .trigger-box {
      margin: 10px 5px;
    }
    .trigger {
      display: flex;
      flex-direction: column;
      border: 1px solid rgb(200, 200, 200);
      padding: 15px 20px;
      border-radius: 8px;
      justify-content: center;
      min-width: 230px;
      .second-title {
        margin-top: 10px;
      }
      .header-box-trigger {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgb(200, 200, 200);
        .icon-remove-trigger {
          color: rgba(150, 0, 0, 0.7);
          :hover {
            cursor: pointer;
          }
        }
      }
      .title-params {
        font-size: 1rem;
        font-weight: bold;
      }
      .campaign-name-wrapper {
        display: flex;
        justify-content: center;
        padding: 2px 10px;
        font-weight: bold;
        box-shadow: 0px 0px 0px 1px rgb(200, 250, 200);
      }
      .trigger-box-params {
        .wrapper-items {
          .trigger-box-params-item {
            .condition {
              display: flex;
              align-items: center;
              .condition-piece {
                padding: 2px 10px;
                font-weight: bold;
                box-shadow: 0px 0px 0px 1px #e6e6e6;
              }
              .conective-text {
                font-size: 0.8rem;
                margin: 0 7px;
              }
            }
          }
        }
      }
    }
    .new-trigger {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid rgb(200, 200, 200);
      padding: 10px 20px;
      border-radius: 8px;
      width: 230px;
      height: 90px;
      cursor: pointer;
      .icon-new {
        font-size: 1.3rem;
      }
    }
  }
`;
