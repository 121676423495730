import React, { useState } from "react";
import {
  CalendarOutlined,
  FilterOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { Menu, Dropdown, Modal, Table } from "antd";
import { Calendar } from "antd";
import { API } from "services/api.js";
import { ConfigProvider } from "antd";
import ptBR from "antd/lib/locale/pt_BR";
import { ExportToCsv } from "export-to-csv";

// Styles
import { DataListWrapper } from "./styles.js";

export default function DataList() {
  // Estados da tabela
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchField, setSearchField] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [columns, setColumns] = useState([]);

  // Estados gerais
  const [loadeedData, setLoadeedData] = useState(false);
  const [personalStartDate, setPersonalStartDate] = useState(new Date());
  const [personalEndDate, setPersonalEndDate] = useState(new Date());

  // Estado dos botões de filtro
  const [textFilterDate, setTextFilterDate] = useState("Data");

  // Estados da modal
  const [modalTitle, setModalTitle] = useState("");
  const [isModalExportVisible, setIsModalExportVisible] = useState(false);
  const [isModalDatesVisible, setIsModalDatesVisible] = useState(false);

  // Dados
  const [dados, setDados] = useState([]);
  const [totalDados, setTotalDados] = useState(0);

  function formatDate(date) {
    return date
      ? `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
      : null;
  }

  /**
   * @description Seleciona o intervalo de datas do filtro dos ratings
   */
  function selectDate(date, start, end) {
    setIsModalDatesVisible(false);
    let startDate;
    let endDate;
    switch (date) {
      case "Hoje":
        startDate = new Date();
        endDate = null;
        break;
      case "Ontem":
        startDate = new Date(new Date().setDate(new Date().getDate() - 1));
        endDate = null;
        break;
      case "Últimos 7 dias":
        startDate = new Date(new Date().setDate(new Date().getDate() - 7));
        endDate = new Date();
        break;
      case "Últimos 30 dias":
        startDate = new Date(new Date().setDate(new Date().getDate() - 30));
        endDate = new Date();
        break;
      case "Este mês":
        startDate = new Date(new Date().setDate(1));
        endDate = new Date();
        break;
      case "Mês passado":
        startDate = new Date(
          new Date().setDate(new Date().getDate() - 30),
          new Date().setMonth(new Date().getMonth() - 1)
        );
        let date = new Date();
        startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        break;
      case "Desde o início":
        startDate = null;
        endDate = null;
        break;
      case "Personalizado":
        startDate = start;
        endDate = end;
        let textFormattedStartDate = formatDate(startDate);
        let textFormattedEndDate = formatDate(endDate);
        setTextFilterDate(
          `${textFormattedStartDate} a ${textFormattedEndDate}`
        );
        break;
      default:
        startDate = new Date();
        endDate = new Date();
        break;
    }
    console.log("Datas");
    console.log(startDate, endDate);
    setPersonalStartDate(startDate);
    setPersonalEndDate(endDate);

    if (date !== "Personalizado") {
      setTextFilterDate(date);
    }

    searchRatings(startDate, endDate);
  }

  /**
   * @description Requisita as avaliações que atendam ao intervalo de dados e aos filtros aplicados
   */
  function searchRatings(startDate, endDate, all = false) {
    console.log("DATE INTERVAL");
    console.log({ startDate, endDate });
    let queryParams = `?_page=${page}&_per_page=${perPage}&key=${searchField}&keyValue=${searchValue}&startDate=${startDate}&endDate=${endDate}`;
    if (all) {
      setIsModalExportVisible(false);
      queryParams = `?_page=${1}&_per_page=${
        totalDados + 10
      }&key=${searchField}&keyValue=${searchValue}&startDate=${startDate}&endDate=${endDate}`;
    }
    API({ method: "GET", url: `ratings/table${queryParams}` })
      .then((response) => {
        console.log("DADOS DE RESPOSTA");
        console.log(response);
        let newColumns = response.columns.map((column) => {
          let title = column.slice(0, 16);
          if (column.length > 16) title += "...";
          return {
            title: title,
            dataIndex: column,
            key: column,
            minWidth: 400,
            width: 300,
          };
        });

        setColumns(newColumns);

        let newData = response.ratings.map((data, index) => ({
          ...data,
          key: index,
          ["Created At"]: formatDate(new Date(data["Created At"])),
        }));

        setTotalDados(response.total);
        setData(newData);
        if (all) {
          exportData(response);
        }
      })
      .catch((err) => {
        console.log(err);
        setColumns([]);
        setData([]);
      });
  }

  /**
   * @description Gera um CSV com todos os dados correspondentes aos filtros
   */
  function exportData(data) {
    let todayDate = new Date();
    let textFormattedStartDate = formatDate(personalStartDate);
    let textFormattedEndDate = formatDate(personalEndDate);
    let textFormattedToday = formatDate(todayDate);

    let title = `NPSappDados-${textFormattedToday}`;
    if (personalStartDate === null) {
      if (personalEndDate === null) {
        title = `NPSappDados-Histórico-${textFormattedToday}`;
      } else {
        title = `NPSappDados-${textFormattedStartDate}`;
      }
    }
    title = `NPSappDados-${textFormattedStartDate}a${textFormattedEndDate}`;

    const csvExporter = new ExportToCsv({
      filename: title,
      showLabels: true,
      useKeysAsHeaders: true,
    });
    csvExporter.generateCsv(data.ratings);
  }

  const menuData = (
    <Menu>
      <Menu.Item onClick={() => selectDate("Hoje")}>Hoje</Menu.Item>
      <Menu.Item onClick={() => selectDate("Ontem")}>Ontem</Menu.Item>
      <Menu.Item onClick={() => selectDate("Últimos 7 dias")}>
        Últimos 7 dias
      </Menu.Item>
      <Menu.Item onClick={() => selectDate("Últimos 30 dias")}>
        Últimos 30 dias
      </Menu.Item>
      <Menu.Item onClick={() => selectDate("Este mês")}>Este mês</Menu.Item>
      <Menu.Item onClick={() => selectDate("Mês passado")}>
        Mês passado
      </Menu.Item>
      <Menu.Item onClick={() => selectDate("Desde o início")}>
        Desde o início
      </Menu.Item>
      <Menu.Item onClick={() => setIsModalDatesVisible(true)}>
        Personalizado
      </Menu.Item>
    </Menu>
  );

  const menuFilters = (
    <Menu>
      <Menu.Item>Formulário</Menu.Item>
      <Menu.Item>Plataforma</Menu.Item>
      <Menu.Item>Cliente</Menu.Item>
      <Menu.Item disabled>Mais em breve</Menu.Item>
    </Menu>
  );

  return (
    <DataListWrapper>
      <div className="data-filters">
        <Dropdown overlay={menuData}>
          <div className="button-select-filter">
            <CalendarOutlined />
            <div className="text-name">{textFilterDate}</div>
          </div>
        </Dropdown>
        <Dropdown overlay={menuFilters}>
          <div className="button-select-filter">
            <FilterOutlined />

            <div className="text-name">Filtros</div>
          </div>
        </Dropdown>

        <div
          className="button-select-filter"
          onClick={() => setIsModalExportVisible(true)}
        >
          <ExportOutlined />

          <div className="text-name">Exportar</div>
        </div>
      </div>
      <div className="data-table">
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: 1500, y: 300 }}
        />
      </div>

      <ConfigProvider locale={ptBR}>
        {/* MODAL DE EXPORTAÇÃO*/}
        <Modal
          title="Exportar"
          visible={isModalExportVisible}
          onOk={() => searchRatings(personalStartDate, personalEndDate, true)}
          onCancel={() => setIsModalExportVisible(false)}
        >
          <div className="text-center">
            Gerar arquivo com os filtros aplicados?
          </div>
        </Modal>

        <Modal
          title="Data personalizada"
          visible={isModalDatesVisible}
          onOk={() =>
            selectDate("Personalizado", personalStartDate, personalEndDate)
          }
          onCancel={() => setIsModalDatesVisible(false)}
          width={1080}
        >
          <div className="flex w-full justify-between">
            <div className="calendar" style={{ width: "45%" }}>
              <Calendar
                fullscreen={false}
                onPanelChange={(value, mode) => console.log(value, mode)}
                onSelect={(value) => setPersonalStartDate(new Date(value._d))}
              />
            </div>
            <div className="calendar " style={{ width: "45%" }}>
              <Calendar
                fullscreen={false}
                onPanelChange={(value, mode) => console.log(value, mode)}
                onSelect={(value) => {
                  console.log("SELECIONOU");
                  console.log(value._d);
                  setPersonalEndDate(new Date(value._d));
                }}
              />
            </div>
          </div>
        </Modal>
      </ConfigProvider>
    </DataListWrapper>
  );
}
