import styled from "styled-components";

export const SkeletonListContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  .first-line {
    width: 100%;
    display: flex;
    justify-content: right;
    padding-right: 10px;
  }
  .second-line {
    width: 100%;
    margin-top: 20px;
    display: flex;
    padding: 0 10px;
    justify-content: space-between;
  }
  .third-line {
    .sub-line {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 3px;
    }
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    align-items: center;
    width: 100%;
    span.ant-skeleton-input {
      margin-bottom: 15 px;
    }
  }
`;
