import React, { useState, useEffect } from "react";

// Styles
import { FormWrapper } from "styles/Global";
import { FormCampaignWrapper } from "../Form/styles";

// Images
import IconRating from "assets/IconClassRating.png";
import LanguageViewItem from "./LanguageViewItem";

export default function FormViewCampaign(props) {
  const { campaign, ratingTypes, languages } = props;

  // Dados
  const [ratingType, setRatingType] = useState("");

  function getLanguageName(languageId) {
    const language = languages.find((language) => language.code === languageId);
    return language ? language.name : "";
  }

  useEffect(() => {
    if (!ratingTypes) return;

    let ratingType = ratingTypes.find(
      (type) => type.subName === campaign.ratingType
    );
    let ratingName = ratingType ? ratingType.name : "";

    setRatingType(ratingName);
  }, [languages, campaign, ratingTypes]);

  return (
    <FormWrapper id="TESTEFULL">
      <FormCampaignWrapper>
        <div className="form-campaign-box">
          <div className="global-informations">
            <div className="icon-ratingtype-wrapper">
              <div className="icon-ratingtype">
                <img src={IconRating} alt="Tipo de avaliação" />
              </div>
              <div className="text-ratingType">{ratingType}</div>
            </div>
            <div className="input-name-wrapper">
              <div className="mb-3 pt-1 rounded input-name">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Nome da campanha
                </label>
                <input
                  className="input-form shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  readOnly
                  type="text"
                  placeholder="Campanha..."
                  value={campaign.name}
                />
              </div>
            </div>
          </div>
          <div className="menu-languages-wrapper">
            <div
              className="block text-gray-700 text-lg font-bold mb-2 label-languages"
              htmlFor="name"
            >
              Idiomas
            </div>

            {/* Listagem de idiomas */}
            <div className="languages-list">
              {campaign &&
                campaign.envelopes &&
                campaign.envelopes.map((envelope) => (
                  <LanguageViewItem
                    content={envelope}
                    getLanguageName={getLanguageName}
                  />
                ))}
            </div>
          </div>
        </div>
      </FormCampaignWrapper>
    </FormWrapper>
  );
}
