import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { useForm } from "react-hook-form";
import { notification } from "antd";
import FormWrapper from "../../../../styles/FormWrapper";
import { Parser as ReactParser } from "html-to-react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { useNavigate } from "react-router";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//drag drop
import { DragDropContext, Droppable } from "react-beautiful-dnd";

// Text editor components
import { API } from "../../../../services/api";

// Styles
import { FormRegisterForm, QuestionsWrapper } from "./styles";

// Components
import Question from "./Question";
import { useCampaign } from "../../../../contexts/campaignRegister";
import { Navigate } from "react-router";
import { useNotification } from "../../../../contexts/session";

const Context = React.createContext({ name: "Default" });

Modal.setAppElement("#root");

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const customStyles = {
  content: {
    display: "absolute",
    top: "35%",
    left: "50%",
    right: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
  },
};

export default function FormForms() {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  // Dados
  const [loadedLanguages, setLoadedLanguages] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [nquestions, setNquestions] = useState(0);

  // Estados
  const [visibleModalPreview, setVisibleModalPreview] = useState(false);

  // Componentes
  const [preview, setPreview] = useState();

  // Contextos
  const { changingLanguage } = useCampaign();
  const navigate = useNavigate();
  const { openNotification } = useNotification();

  // Registra o formulário
  const onSubmit = (data) => {
    let questionsForm = questions.map((question) => ({
      question: question.value,
      required: question.required,
    }));
    data["questions"] = questionsForm;

    API({ method: "POST", url: "forms", data: data })
      .then(({ id }) => {
        openNotification({
          description: "Novo formulário cadastrado",
          message: "Cadastrado",
          status: "error",
        });
        if (changingLanguage) navigate("campaigns/new");
        else navigate(`campaigns/forms`);
      })
      .catch((err) => {
        openNotification({
          description: "Não foi possível cadastrar",
          message: "Erro",
          status: "error",
        });
      });
  };

  const checkErrors = (e) => {
    if (Object.keys(errors).length > 0) {
      openNotification({
        description: "Preencha todos os campos antes de continuar",
        message: "Oops",
        status: "info",
      });
    }
  };

  // Altera a ordem das perguntas ao soltar o drag
  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const _questions = reorder(
      questions,
      result.source.index,
      result.destination.index
    );
    setQuestions(_questions);
  }

  function addQuestion(e) {
    e.preventDefault();
    if (nquestions >= 3) return;
    let questionId = Math.floor(Math.random() * (9999 - 1000 + 1) + 1000);
    while (questions.find((q) => q.id === questionId)) {
      questionId = Math.floor(Math.random() * (9999 - 1000 + 1) + 1000);
    }
    let question = {
      id: `${questionId}`,
      value: "",
      required: false,
    };
    let oldQuestions = questions;
    oldQuestions.push(question);
    setNquestions(nquestions + 1);
    setQuestions(oldQuestions);
  }

  function onChangeQuestions(question) {
    let { value, id, required } = question;
    console.log(question);
    let newQuestions = questions.map((q) => {
      if (q.id === id) {
        if (value !== undefined) q.value = value;
        if (required !== undefined) q.required = required;
      }
      return q;
    });
    setQuestions(newQuestions);
  }

  function onRemoveQuestion(questionId) {
    let newQuestions = questions.filter((q) => q.id !== questionId);
    setNquestions(nquestions - 1);
    setQuestions(newQuestions);
  }

  // Renderiza o preview do formulário
  function renderPreview(e) {
    e.preventDefault();
    const htmlToReactParser = new ReactParser();
    let inicialText = getValues("inicialText");
    let htmlPreview = `
      <div>
        <div style="display: flex; justify-content: center; font-weight: bold; font-size: 1.2rem; margin-bottom: 10px;">
          Obrigado por responder a pesquisa. 
        </div>
        <div style="display: flex; justify-content: center; margin-bottom: 10px;">
          ${inicialText}
        </div>
      </div>
      <div id="box-questions">
    `;

    for (let question of questions) {
      htmlPreview += `
        <div style="margin-bottom: 15px">
          <div style="font-weight: bold">
            ${question.value}
          </div>
          <input type="text" class="input-form shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
        </div>
      `;
    }

    htmlPreview += `
      </div>
      <div style="display: flex; justify-content: center; margin-top: 20px;">
        <button
          type="submit"
          className="py-1 px-3 rounded btn-form button-submit"
          onClick="(e)=>{e.preventDefault()}"
        >
          Enviar
        </button>
      </div>
    `;

    setPreview(htmlToReactParser.parse(htmlPreview));
    setVisibleModalPreview(true);
  }

  useEffect(() => {
    if (loadedLanguages) return;
    setLoadedLanguages(true);
    API({ method: "GET", url: "config/langs" }).then((data) => {
      setLanguages(data.value);
      setValue("language", "pt-BR");
    });
  }, [languages]);

  return (
    <FormWrapper>
      <div className=" form-body w-full">
        <FormRegisterForm onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="mb-3 pt-1 rounded ">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                Nome
              </label>
              <input
                className="input-form shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                placeholder="Nome"
                {...register("name", { required: true })}
              />
            </div>
          </div>

          {!changingLanguage && (
            <div className="third-row">
              <div className="mb-3 pt-1 rounded ">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Idioma
                </label>
                <select
                  className="input-form shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="language"
                  type="text"
                  {...register("language", { required: true })}
                >
                  {languages.map((language) => (
                    <option
                      className="option-languege h-10"
                      key={language.code}
                      value={language.code}
                    >
                      {language.name}
                    </option>
                  ))}
                </select>
              </div>
              <div></div>
              <div></div>
            </div>
          )}

          <div>
            <div className="mb-3 pt-1 rounded ">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                Texto inicial
              </label>
              <textarea
                className="input-form shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                placeholder=""
                {...register("inicialText", { required: true })}
              />
            </div>
          </div>

          <div>
            <div className="mb-3 pt-1 rounded ">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                Perguntas
              </label>
              <QuestionsWrapper>
                <div className="wrapper-questions">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {questions.length ? (
                            questions.map((item, index) => (
                              <Question
                                quote={item.value}
                                required={item.required}
                                index={index}
                                id={item.id}
                                key={item.id}
                                onChange={onChangeQuestions}
                                onRemove={onRemoveQuestion}
                              />
                            ))
                          ) : (
                            <div className="text-comment-quests">
                              <p>*Adicione perguntas ao formulário</p>
                            </div>
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                <div className="button-add-wrapper">
                  <button onClick={(e) => addQuestion(e)}>
                    Adicionar pergunta{" "}
                    <FontAwesomeIcon className="icon" icon={faPlus} />
                  </button>
                </div>
              </QuestionsWrapper>
            </div>
          </div>

          <div className="confirm-wrapper ">
            <button
              type="submit"
              className="py-1 px-3 rounded btn-form mr-5"
              onClick={renderPreview}
            >
              Visualizar
            </button>
            <button
              type="submit"
              className="py-1 px-3 rounded btn-form"
              onClick={checkErrors}
            >
              Salvar
            </button>
          </div>
        </FormRegisterForm>
      </div>

      <Modal
        isOpen={visibleModalPreview}
        onRequestClose={() => setVisibleModalPreview(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {preview}
      </Modal>
    </FormWrapper>
  );
}
