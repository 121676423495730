import styled from "styled-components";

export const FormCampaignWrapper = styled.div`
  padding: 5px 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  .form-campaign-box {
    border: 1px solid #e6e6e6;
    padding: 10px;
    width: 90%;
    border-radius: 7px;
    // Nome da campanha e icone de tipo de avaliação
    .global-informations {
      display: grid;
      grid-template-columns: 1.3fr 2.7fr;
      .icon-ratingtype-wrapper {
        display: flex;
        border: 1.5px solid #e6e6e6;
        border-radius: 10px;
        padding: 8px 10px;
        margin-right: 10px;
        .icon-ratingtype {
          img {
            width: 80px;
          }
        }
        .text-ratingType {
          text-align: center;
          margin: 0px auto;
          height: 100%;
          font-size: 2rem;
          display: flex;
          align-items: center;
        }
        @media (max-width: 768px) {
          flex-direction: column;
          .text-ratingType {
            font-size: 1.5rem;
          }
          .icon-ratingtype {
            display: flex;
            justify-content: center;
            img {
              width: 50px;
            }
          }
        }
        @media (max-width: 500px) {
          .text-ratingType {
            font-size: 1rem;
          }
        }
      }
      .input-name-wrapper {
        display: flex;
        justify-content: center;
        .input-name {
          width: 80%;
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
    // Listagem de idiomas e botão de adição
    .menu-languages-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .label-languages {
        width: 100%;
        text-align: center;
        //border-bottom: 1px solid #e6e6e6;
      }
      .button-add-envelope {
        background-color: white;
        color: black;
        padding: 10px 20px;
        border: 1px solid #d1d1d1;
        border-radius: 10px;
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon-add {
          font-size: 1.2rem;
          margin-bottom: 5px;
        }
        :hover {
          box-shadow: 0px 0px 10px 0px #d1d1d1;
        }
        transition: all 0.3s ease;
      }
      // Listagem de idiomas
      .languages-list {
        width: 90%;
        margin: 30px 20px;
        .no-languegs {
          border: 1px solid #d1d1d1;
          background-color: #f4f4f4;
          border-radius: 5px;
          padding: 10px 5px;
          display: flex;
          justify-content: center;
        }
      }
    }
    // Botão de salvar
    .wrapper-btn-save {
      .btn-save {
        background-color: #00b3af;
        color: white;
        padding: 7px 20px;
        border: 1px solid #009f9b;
        border-radius: 50px;
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }
  /* OLD */
`;
