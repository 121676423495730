import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

import { WhiteLoadingWrapper } from "./styles";

export default function WhiteLoading() {
  return (
    <WhiteLoadingWrapper>
      <LoadingOutlined />
    </WhiteLoadingWrapper>
  );
}
