import styled from "styled-components";

export const CheckoutPageContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper-form {
    width: 40%;
    height: 40%;
    .box-form {
      height: 100%;
    }
  }
`;
