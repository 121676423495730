import React, { useEffect } from "react";
import { Tabs, Dropdown, Menu, Button } from "antd";
import {
  DashboardOutlined,
  UnorderedListOutlined,
  CalendarOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Pie } from "@ant-design/charts";
import { API } from "services/api";
import { useNotification } from "contexts/session";
import { QRCodeSVG } from "qrcode.react";
import LogoNPS from "assets/LogoNpsAPPV2.png";

import {
  CopyOutlined,
  ExportOutlined,
  QrcodeOutlined,
  RocketOutlined,
} from "@ant-design/icons";
import { useSession } from "contexts/session";
import { QRCode } from "react-qrcode-logo";

// Styles
import { WrapperPage, QuickyRatingsWrapper } from "./styles";
import { ButtonBlueDefault } from "styles/Global";

export default function QuickyRatings() {
  // Dados
  const [campaigns, setCampaigns] = React.useState([]);

  // Hooks
  const { openNotification } = useNotification();
  const { activeTenant } = useSession();

  // Estados
  const [loaded, setLoaded] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(true);
  const [globalChartText] = React.useState("Loading...");
  const [selectedCampaign, setSelectedCampaign] = React.useState({
    name: "Carregando",
    ratingType: "nps",
    id: "0000",
  });

  const [linkText, setLinkText] = React.useState(
    `${process.env.REACT_APP_FRONT_PROTOCOL}://${activeTenant}.${process.env.REACT_APP_FRONT_URL}`
  );

  function download(href) {
    let download = document.createElement("a");
    download.href = href;
    download.download = "img.png";
    download.click();
    download.remove();
  }

  // Save QR code
  const saveQrCode = () => {
    const svgElem = document.getElementById("qrCodeEl");
    const serializer = new XMLSerializer();
    let svgData = serializer.serializeToString(svgElem);
    svgData = '<?xml version="1.0" standalone="no"?>\r\n' + svgData;
    const svgBlob = new Blob([svgData], {
      type: "image/svg+xml;charset=utf-8",
    });
    let DOMURL = window.URL || window.webkitURL || window;
    const url = DOMURL.createObjectURL(svgBlob);

    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const domRect = svgElem.getBBox();
      canvas.width = 510;
      canvas.height = 510;
      console.log("DINESIONS");
      console.log(domRect);
      ctx.drawImage(img, 5, 5, 500, 500);
      DOMURL.revokeObjectURL(url);

      const imgURI = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      download(imgURI);
    };
    img.onerror = (e) => {
      console.error("Image not loaded", e);
    };

    img.src = url;
  };

  function loadCampaigns() {
    return new Promise((res, rej) => {
      API({ method: "GET", url: "campaigns" })
        .then(({ data }) => {
          res(data);
          setCampaigns(data);
        })
        .catch(() => {
          rej({ res: "Load campaigns" });
        });
    });
  }

  function selectCampaign({ key }) {
    const campaign = campaigns.find((e) => e.id == key);
    setSelectedCampaign(campaign);
    setLinkText(
      `${process.env.REACT_APP_FRONT_PROTOCOL}://${activeTenant}.${process.env.REACT_APP_FRONT_URL}/ratings/sa?c=${campaign.id}`
    );
    setLoadingData(true);
  }

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
    loadCampaigns()
      .then((data) => {
        setCampaigns(data);
        const [campaign] = data;
        if (campaign) setSelectedCampaign(campaign);

        console.log("TENANT");
        console.log(activeTenant);
        setLinkText(
          `${process.env.REACT_APP_FRONT_PROTOCOL}://${activeTenant}.${process.env.REACT_APP_FRONT_URL}/ratings/sa?c=${campaign.id}`
        );
      })
      .catch((err) => {
        openNotification({
          message: "Oops",
          description: "Não foi possível carregar as campanhas",
          status: "INFO",
        });
      });
  }, []);

  useEffect(() => {
    console.log("SELECTED CAMPAIGN");
    console.log(activeTenant);
    console.log(
      `${process.env.REACT_APP_FRONT_PROTOCOL}://${activeTenant}.${process.env.REACT_APP_FRONT_URL}/ratings/sa?c=${selectCampaign.id}`
    );
    setLinkText(
      `${process.env.REACT_APP_FRONT_PROTOCOL}://${activeTenant}.${process.env.REACT_APP_FRONT_URL}/ratings/sa?c=${selectCampaign.id}`
    );
  }, [activeTenant]);

  return (
    <WrapperPage>
      <QuickyRatingsWrapper>
        <div className="wrapper-main-box">
          <div className="wrapper-form-selector">
            <div className="title-main-box">Avaliações rápidas</div>
            <div className="subtitles">
              <div className="sub-title">
                <div className="icon">
                  <RocketOutlined />
                </div>
                <div className="text">
                  As avalções rápidas são uma maneira simples e fácil de
                  avaliar.
                </div>
              </div>
              <div className="sub-title">
                <div className="icon">
                  <QrcodeOutlined />
                </div>
                <div className="text">
                  Use um link ou um QR code para coletar avaliações de uma
                  campanha de forma rápida e anônima.
                </div>
              </div>
            </div>
            <div className="campaign-selector">
              <span className="selector-title">Campanha</span>
              <Dropdown
                overlay={
                  <Menu onClick={selectCampaign}>
                    {campaigns.length > 0 &&
                      campaigns.map((campaign) => (
                        <Menu.Item key={campaign.id}>{campaign.name}</Menu.Item>
                      ))}
                  </Menu>
                }
              >
                <Button>
                  {selectedCampaign.name} <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </div>
          {campaigns.length > 0 ? (
            <div className="wrapper-result">
              <div className="border-box gradient">
                <div className="box-result">
                  <div className="qrcode">
                    <QRCodeSVG
                      value={linkText}
                      id="qrCodeEl"
                      renderAs="canvas"
                    />
                    {/* <QRCode value={linkText} /> */}
                  </div>
                  <div className="link">
                    <div className="link-text">{linkText}</div>
                  </div>
                  <div className="buttons">
                    <ButtonBlueDefault
                      onClick={() => {
                        navigator.clipboard.writeText(linkText);
                        openNotification({
                          message: "Copiado",
                          description:
                            "Link copiado para a área de transferência",
                          status: "success",
                        });
                      }}
                    >
                      Copiar Link <CopyOutlined />
                    </ButtonBlueDefault>
                    <ButtonBlueDefault
                      onClick={() => {
                        saveQrCode();
                      }}
                    >
                      Exportar QRCode
                      <ExportOutlined />
                    </ButtonBlueDefault>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="placeholder-qrcode">
              <div className="text-placeholder">
                Selecione uma campanha para gerar um link rápido.
              </div>
            </div>
          )}
        </div>
      </QuickyRatingsWrapper>
    </WrapperPage>
  );
}
