import styled from "styled-components";

// Global wrappper
export const LandingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

// Barra fixada no topo da página
export const NavbarLanding = styled.nav`
  width: 100%;
  height: 60px;
  z-index: 200;
  box-shadow: 0 0.5px 0.5px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0 5px;

  .wrapper-nav {
    height: 100%;
    display: flex;
    .wrapper-logo-navbar {
      display: flex;
      min-width: 20%;
      align-items: center;
      height: 100%;

      .logoNavbar {
        height: 30px;
        width: auto;
      }
    }

    .navbar-box-login {
      display: flex;
      width: 78%;
      justify-content: right;
      align-items: center;

      button {
        font-family: "Roboto", sans-serif;
        color: white;
        font-weight: 600;
        padding: 0 30px;
        border-radius: 30px;
        float: right;
        height: 60%;
        background-color: #00adac;
      }
    }
  }
`;

// Banner com logo form e frase
export const LandingBanner = styled.section`
  min-height: 100vh;
  height: auto;
  padding-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "banner-left banner-right"
    "banner-left-bottom banner-right";

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  .banner-landing-box-logo {
    display: flex;
    height: 100%;
    padding-top: 60px;
    justify-content: center;
    align-items: center;

    img {
      height: 120px;
    }
  }

  .banner-landing-text {
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-size: 2.8rem;
    color: rgba(255, 255, 255, 0.75);
    grid-area: banner-right;
    @media (max-width: 768px) {
      padding: 25% 0;
      font-size: 1.8rem;
      text-align: center;
      margin: 10px 0;
    }
  }
  .banner-landing-box-form {
    grid-area: banner-left-bottom;
    display: flex;
    justify-content: center;
    height: 100%;
    padding-top: 50px;
    @media (max-width: 600px) {
      padding-top: 0;
    }
  }
  .banner-landing-form {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    width: 60%;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 10px;
    height: fit-content;

    @media (max-width: 600px) {
      width: 95%;
    }

    .banner-landing-form-title {
      font-family: "Roboto", sans-serif;
      text-align: center;
      margin-bottom: 10px;
      font-size: 1.3rem;
      color: rgba(90, 90, 90, 0.9);
    }
    input {
      font-family: "Roboto", sans-serif;
      padding: 5px 10px;
      border-radius: 5px;
    }
    .banner-landing-form-box-button {
      display: flex;
      justify-content: center;
      padding: 20px 0 10px 0;
      button {
        //background-color: #ffb005;
        background-color: #00adac;
        padding: 5px 30px;
        color: white;
        font-family: "Roboto", sans-serif;
        border-radius: 30px;
        font-size: 1.1rem;
      }
    }
  }
`;
