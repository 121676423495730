import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API } from "services/api";
import { useNotification } from "contexts/session";

// Styles
import { ScreenTitle } from "styles/Global";
import { PageWrapper } from "styles/ListItems";

// Icones
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import ViewForm from "components/home/admin/users/viewForm";

export default function Viewuser() {
  const params = useParams();
  const { email } = params;
  const { openNotification } = useNotification();
  const [user, setUser] = useState({});
  const [loaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  function deleteUser() {
    API({ method: "DELETE", url: `users/${email}` })
      .then((data) => {
        openNotification({
          message: "Sucesso",
          description: "Usuário deletado com sucesso!",
          status: "success",
        });
        navigate("/admin/users");
      })
      .catch(() => {
        openNotification({
          message: "Erro",
          description: "Erro ao deletar usuário!",
          status: "error",
        });
      });
  }

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
    API({ method: "GET", url: `users/${email}` })
      .then((res) => {
        setUser(res);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        openNotification({
          message: "Erro",
          description: "Usuário não encontrado.",
          status: "error",
        });
      });
  }, [user, email, openNotification, loaded]);

  return (
    <PageWrapper>
      <ScreenTitle>
        <span>
          <FontAwesomeIcon icon={faUser} />
        </span>
        {user.name || "Carregando ..."}
      </ScreenTitle>
      {user.name && <ViewForm userData={user} deleteUser={deleteUser} />}
    </PageWrapper>
  );
}
