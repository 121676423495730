import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { Parser as ReactParser } from "html-to-react";

// Service
import { API } from "../../../../services/api";

// Styles
import {
  FormWrapper,
  ScreenTitle,
  ButtonBlueDefault,
} from "../../../../styles/Global";
import { WrapperButtonsEdit, WrapperPreview } from "./styles";

export default function View() {
  const [loaded, setLoaded] = React.useState(false);
  const params = useParams();
  const [template, setTemplate] = React.useState({});

  const htmlToReactParser = new ReactParser();

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
    const { id } = params;

    API({ method: "GET", url: `templates/${id}` }).then((data) => {
      console.log("Carregou");
      console.log(data);
      setTemplate(data);
    });
  });

  const body = (
    <>
      <FormWrapper>
        <ScreenTitle>
          <span>
            <FontAwesomeIcon icon={faEnvelope} />
          </span>
          {template.name}
        </ScreenTitle>
        <WrapperPreview>
          <div className="field-preview">
            <h2 className="title-field-preview">Preview</h2>
            <div className="wraper-content">
              {htmlToReactParser.parse(template.content)}
            </div>
          </div>
          <div className="field-preview mt-3">
            <h2 className="title-field-preview">Idioma</h2>
            <div className="box-languages">
              <div className="language-snippet">Português</div>
            </div>
          </div>
        </WrapperPreview>
        {/* <WrapperButtonsEdit>
          <ButtonBlueDefault>Editar</ButtonBlueDefault>
        </WrapperButtonsEdit> */}
      </FormWrapper>
    </>
  );
  return <>{body}</>;
}
