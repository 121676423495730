import styled from "styled-components";

export const SkeletonConnectionsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  .ant-skeleton {
    margin: 10px;
  }
`;
