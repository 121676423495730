import React, { useEffect, useState } from "react";
import { Pagination, Modal, ConfigProvider, Tabs } from "antd";
import { API } from "services/api.js";
import ptBR from "antd/lib/locale/pt_BR";
import { useNotification } from "contexts/session";
import { useCampaign } from "contexts/campaignRegister";
import { useNavigate } from "react-router";

// Styles
import { PageWrapper } from "styles/ListItems";
import { BtnOptMenu, ScreenTitle } from "styles/Global";

// Components
import ItemCampaign from "../components/List/Item";
import RatingType from "../components/List/ratingTypeItem";
import ConnectionType from "../components/List/connectionTypeItem";

import EmailTemplatesList from "pages/private/templates/List";
import ChatTemplatesList from "pages/private/templatesChat/List";
import FormsList from "pages/private/formularios/List";

// Icones
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SkeletonList from "components/home/global/skeletons/skeletonList";

const { TabPane } = Tabs;

export default function List() {
  const navigate = useNavigate();
  // Contextos
  const { openNotification } = useNotification();

  // Estados da listagem
  const [loaded, setLoaded] = useState(false);
  const [loadedRatingTypes, setLoadedRatingTypes] = useState(false);
  const [total, setTotal] = useState(100);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  // Estados das modais
  const [loadedCampaigns, setLoadedCampaigns] = useState(false);
  const [modalRatingTypeActive, setModalRatingTypeActive] = useState(false);
  const [modalDestinyTypeActive, setModalDestinyTypeActive] = useState(false);

  // Dados
  const [campaigns, setCampaigns] = useState([]);
  const [connectionTypes, setConnectionTypes] = useState([]);

  // Dados da vova campanha
  const { ratingType, setRatingType, ratingTypes, setRatingTypes } =
    useCampaign();

  const onChange = (_page) => {
    setPage(_page);
    API({
      window: window,
      method: "GET",
      url: `templates?page=${_page}&per_page=${perPage}`,
    }).then(({ data, total }) => {
      setCampaigns(data);
      setLoadedCampaigns(true);
      setTotal(total);
    });
  };

  function registerNewCampaign() {
    if (ratingType !== "") {
      navigate("/campaigns/new");
    } else {
      openNotification({
        message: "Não selecionado",
        description: "Selecione um tipo de avaliação",
        status: "info",
      });
    }
  }

  function getRatingType(typeCode, codes = null) {
    if (connectionTypes.length > 0) codes = connectionTypes;
    console.log("TESTE");
    console.log(codes);
    const type = codes.find((language) => language.subName === typeCode);
    return type ? type.name : "";
  }

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
    API({ method: "GET", url: "ratingtypes" })
      .then((ratignTypes) => {
        setRatingTypes(ratignTypes);
        API({ method: "GET", url: `campaigns` }).then(({ data, total }) => {
          data = data.map((item) => {
            item.ratingTypeName = getRatingType(item.ratingType, ratignTypes);
            return item;
          });
          setCampaigns(data);
          setLoadedCampaigns(true);
          setTotal(total);
        });
      })
      .catch((err) => {
        openNotification({
          message: "Erro",
          description:
            "Não foi possível carregar os dados nescessários, atualize a página",
          status: "error",
        });
        console.log(err.message);
      });
  });

  useEffect(() => {
    if (loadedRatingTypes) return;
    setLoadedRatingTypes(true);

    API({ method: "GET", url: "connectionTypes" })
      .then((connTypes) => {
        setConnectionTypes(connTypes);
      })
      .catch((err) => {
        openNotification({
          message: "Erro",
          description:
            "Não foi possível carregar os dados nescessários, atualize a página",
          status: "error",
        });
        console.log(err);
      });
  });

  return (
    <>
      <PageWrapper>
        <Tabs
          style={{ height: "100%", marginTop: "5px" }}
          defaultActiveKey="1"
          centered
          type="card"
        >
          <TabPane tab={<span>Campanhas</span>} key="1">
            <ScreenTitle>
              <span>
                <FontAwesomeIcon icon={faStar} />
              </span>
              Campanhas
            </ScreenTitle>

            {loadedCampaigns ? (
              <>
                {campaigns.length ? (
                  <>
                    <div className="menu-options">
                      <BtnOptMenu
                        className="text-gray-800 font-extrabold rounded my-2 md:my-2 py-2 px-2 w-40 mx-2 shadow-sm"
                        onClick={() => setModalRatingTypeActive(true)}
                      >
                        Criar nova
                      </BtnOptMenu>
                    </div>
                    <table className="w-full text-md bg-white shadow-md rounded mb-4">
                      <tbody>
                        <tr className="border-b">
                          <th className="px-4 py-3">Id</th>
                          <th className="px-4 py-3">Nome</th>
                          <th className="px-4 py-3">Tipo de avaliação</th>
                          <th className="px-4 py-3"></th>
                        </tr>
                        {campaigns.map((campaign, index) => (
                          <ItemCampaign
                            key={index}
                            id={campaign.id}
                            name={campaign.name}
                            ratingType={campaign.ratingTypeName}
                          />
                        ))}
                      </tbody>
                    </table>
                    <div className="wrapper-paginator">
                      <Pagination
                        simple
                        defaultCurrent={page}
                        total={total}
                        onChange={onChange}
                        pageSize={perPage}
                      />
                    </div>
                  </>
                ) : (
                  <div className="notFound-message-wrapper">
                    <div className="message-nnotFound">
                      Nenhuma campanha cadastrada
                    </div>
                    <div>
                      <BtnOptMenu
                        onClick={() => setModalRatingTypeActive(true)}
                        className="text-gray-800 font-extrabold rounded my-2 md:my-2 py-2 px-2 w-40 mx-2 shadow-sm"
                      >
                        Criar nova
                      </BtnOptMenu>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <SkeletonList />
            )}
          </TabPane>
          <TabPane tab={<span>Templates de e-mail</span>} key="2">
            <EmailTemplatesList />
          </TabPane>
          {/* <TabPane tab={<span>Templates de chat</span>} key="3">
            <ChatTemplatesList />
          </TabPane> */}
          <TabPane tab={<span>Formulários</span>} key="4">
            <FormsList />
          </TabPane>
        </Tabs>
      </PageWrapper>

      {/* Modais */}
      <ConfigProvider locale={ptBR}>
        {/* Modal de seleção de tipo de avaliação */}
        <Modal
          title="Tipo de Avaliação"
          centered
          visible={modalRatingTypeActive}
          onOk={() => registerNewCampaign()}
          onCancel={() => setModalRatingTypeActive(false)}
          width={1000}
        >
          <div className="flex justify-center flex-wrap">
            {ratingTypes.map((type, idex) => (
              <RatingType
                key={idex}
                name={type.name}
                selected={ratingType == type.subName}
                onSelect={() => setRatingType(type.subName)}
              />
            ))}
          </div>
        </Modal>
      </ConfigProvider>
    </>
  );
}
