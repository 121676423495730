import styled from "styled-components";

const FormWrapper = styled.div`
  min-height: 100%;
  height: auto;
  .form-body {
    padding: 0 5%;
  }
  hr {
    margin: 10px 5px;
  }

  // Row 1/1
  .full-row {
    display: flex;
    justify-content: center;
  }
  .full-row div {
    width: 98.5%;
  }

  // Row 1/2
  .half-row {
    display: flex;
    justify-content: center;
  }
  .half-row div {
    width: 49%;
    margin-right: 0;
  }
  .half-row div:first-child {
    margin-right: 0.5%;
  }

  // Row 1/3
  .third-row {
    display: flex;
    justify-content: center;
  }
  .third-row div {
    width: 33%;
    margin-right: 0;
  }
  .third-row div:first-child {
    width: 65%;
    margin-right: 0.5%;
  }

  // Phone Row
  .phone-row {
    display: flex;
    justify-content: center;
  }

  .phone-row div {
    width: 57.5%;
  }
  .phone-row div:first-child {
    width: 7%;
    margin-right: 0.5%;
  }
  .phone-row div:nth-child(2) {
    width: 30%;
    margin-right: 3%;
  }

  // Confirmation div
  .confirm-wrapper {
    padding: 10px 1.5rem;
    height: auto;
  }
  .confirm-wrapper button {
    font-weight: bold;
    color: white;
    float: right;
    font-size: 1.02rem;
    background-image: linear-gradient(300deg, #6ad4e7 0%, #44a9bb 90%);
  }
  .editor-body {
    border: 1px solid rgba(204, 204, 204, 0.5);
    border-radius: 5px;
    min-height: 220px;
    padding: 5px;
  }
  .DraftEditor-root {
    height: 100% !important;
  }
  .public-DraftStyleDefault-block {
    margin: 0;
  }
  .template-snippet {
    background-color: rgba(245, 245, 245, 0.5);
    width: fit-content !important;
    height: fit-content;
    padding: 2px 7px;
    margin: 0 2px;
    border-radius: 5px;
    border: 3px solid rgba(245, 245, 245, 0.8);
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.2s ease-in-out;
  }
  .template-snippet:hover {
    background-color: rgba(245, 245, 245, 0.8);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.2s ease-in-out;
  }
  .default-fields-wrapper {
    border: 2px solid rgba(245, 245, 245, 0.8);
    width: 100%;
    padding: 5px 10px;
    margin-bottom: 10px;
    display: flex;
    border-radius: 5px;
  }
`;

export default FormWrapper;
