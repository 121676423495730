import React, { useState } from "react";
import Modal from "react-modal";
import { useNotification } from "../../../../contexts/session";
import { API } from "../../../../services/api.js";

// Icones
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

// Styles
import { ModalBody, BoxFormTriggers, BoxSelectedCampaign } from "./styles.js";
import {
  InputSearch,
  ResultList,
  ButtonBlueDefault,
} from "../../../../styles/Global";

// Components
import FormTriggersChat from "./FormTriggersChat";
import FormTriggersDesk from "./FormTriggersDesk";

const modalCustomStyle = {
  content: {
    display: "absolute",
    top: "50%",
    left: "50%",
    right: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "80%",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  },
};

export default function ModalNewTrigger(props) {
  const { isOpen, onRequestClose, connection, reloadConnection } = props;

  // Timeout do campo de busca
  const [timeoutForm, setTimeoutForm] = useState(null);

  // Dados
  const [searchField, setSearchField] = useState("");
  const [resultCampaigns, setResultCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [groupId, setGroupId] = useState("all");
  const [ticketType, setTicketType] = useState("all");

  // Estados
  const [visibleResults, setVisibleResults] = useState(false);
  const [visibleMessageSelectCampaign, setVisibleMessageSelectCampaign] =
    useState(false);

  // Notificações
  const { openNotification } = useNotification();

  function searchCampaign(e) {
    let value = e.target.value;
    setSearchField(value);
    let timeNow = Date.now();
    if (timeNow - timeoutForm <= 2000 || value === "") {
      return;
    }
    setTimeoutForm(timeNow);
    API({
      window: window,
      method: "GET",
      url: `campaigns?key=${value}&page=1&per_page=5`,
    })
      .then(({ data }) => {
        setResultCampaigns(data);
        if (data.length > 0) {
          setVisibleResults(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function onSaveTrigger() {
    if (!selectedCampaign) {
      setVisibleMessageSelectCampaign(true);
      return;
    }
    let trigger;
    if (connection.type === "chat") {
      trigger = {
        params: [
          {
            field: "conversation.assigned_group_id",
            value: groupId,
          },
        ],
        campaign: { id: selectedCampaign.id, name: selectedCampaign.name },
      };
    } else if (connection.type === "desk") {
      trigger = {
        params: [
          {
            field: "ticket.type",
            value: ticketType,
          },
        ],
        campaign: { id: selectedCampaign.id, name: selectedCampaign.name },
      };
    }
    API({
      window: window,
      method: "PATCH",
      url: `connections/addtrigger/${connection.name}`,
      data: trigger,
    })
      .then((data) => {
        if (data === "Success") {
          reloadConnection();
          setSelectedCampaign(null);
          setSearchField("");
          openNotification({
            message: "Sucesso",
            description: "O novo gatilho foi registrado",
            status: "success",
          });
          onRequestClose();
        }
      })
      .catch((err) => {
        openNotification({
          message: "Erro",
          description: "Houve um erro ao registrar o gatilho",
          status: "error",
        });
        console.log(err);
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={modalCustomStyle}
      contentLabel="Novo gatilho"
    >
      <ModalBody>
        <div className="modal-title">
          <h1>Novo gatilho</h1>
        </div>
      </ModalBody>
      <BoxFormTriggers>
        <div className="form">
          {connection.type === "desk" ? (
            <FormTriggersDesk
              connection={connection}
              ticketType={ticketType}
              setTicketType={setTicketType}
            />
          ) : (
            <FormTriggersChat
              connection={connection}
              groupId={groupId}
              setGroupId={setGroupId}
            />
          )}
          <div className="campaign-selector-wrapper">
            <div className="campaign-selector-title">Campanha</div>
            <InputSearch>
              <input
                type="text"
                autoComplete="off"
                onFocus={() => {
                  if (resultCampaigns.length > 0) setVisibleResults(true);
                  setVisibleMessageSelectCampaign(false);
                }}
                onBlur={(e) => setTimeout(() => setVisibleResults(false), 200)}
                value={searchField}
                onChange={searchCampaign}
                id="template-input"
                className="input-form shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <button className="shadow appearance-none border rounded">
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </InputSearch>
            {visibleMessageSelectCampaign && (
              <div className="error-select-campaign">
                Nenhuma campanha foi selecionada
              </div>
            )}
            <label htmlFor="template-input">
              {visibleResults && (
                <ResultList>
                  {resultCampaigns.map((campaign) => (
                    <div
                      className="result-item"
                      key={campaign.id}
                      onClick={() => {
                        setSelectedCampaign(campaign);
                        console.log(campaign);
                      }}
                    >
                      <strong>{campaign.name}</strong>
                      <div className=" float-right">{campaign.id}</div>
                    </div>
                  ))}
                </ResultList>
              )}
              <BoxSelectedCampaign>
                {selectedCampaign ? (
                  <div className="selected-campaign">
                    <div className="campaign-name">{selectedCampaign.name}</div>
                    <div className="campaign-id">{selectedCampaign.id}</div>
                  </div>
                ) : (
                  <div className="message-select-campaign">
                    Selecione uma campanha
                  </div>
                )}
              </BoxSelectedCampaign>
            </label>
          </div>
          <div className="wrapper-button-save">
            <ButtonBlueDefault onClick={onSaveTrigger}>
              Salvar
            </ButtonBlueDefault>
          </div>
        </div>
      </BoxFormTriggers>
    </Modal>
  );
}
