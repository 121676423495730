import React, { useEffect, useState } from "react";
import { useSession, useModalAlert } from "../../../contexts/session";
import Modal from "react-modal";
import { CloseCircleOutlined } from "@ant-design/icons";

// Components
import Sidebar from "./sidebar";
import Header from "./header";

// Styles
import { ButtonBlueDefault } from "../../../styles/Global";
import { WrapperLayout, ModalAlertBody } from "./styles.js";
import Loading from "components/global/Loading";
import { useNavigate } from "react-router-dom";

const modalCustomStyle = {
  content: {
    display: "absolute",
    top: "50%",
    left: "50%",
    right: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    height: "250px",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  },
};

export const HomeLayout = ({ children, page }) => {
  const navigate = useNavigate();
  const { userData, validateLogin } = useSession();
  const {
    modalAlertOpened,
    modalAlertMessage,
    modalFunction,
    setModalAlertOpened,
  } = useModalAlert();
  const [loadingSession, setLoadingSesion] = useState(false);

  useEffect(() => {
    if (loadingSession) return;
    setLoadingSesion(true);
    (async () => {
      const validSession = await validateLogin();
      if (!validSession) {
        navigate("login");
      }
    })();
  });

  return (
    <>
      {userData.active === true ? (
        <WrapperLayout>
          <Sidebar page={page} />
          <div className="wrapper-page">
            <Header />
            <div className="wrapper-content">
              <main>{children}</main>
            </div>
          </div>

          {/* Modal de confirmação geral */}
          <Modal isOpen={modalAlertOpened} style={modalCustomStyle}>
            <ModalAlertBody>
              <div className="close-modal-wrapper">
                <CloseCircleOutlined
                  onClick={() => setModalAlertOpened(false)}
                />
              </div>
              <div className="modalMessage">{modalAlertMessage}</div>
              <div className="wrapper-button">
                <ButtonBlueDefault
                  onClick={() => {
                    modalFunction();
                    setModalAlertOpened(false);
                  }}
                >
                  Confirmar
                </ButtonBlueDefault>
              </div>
            </ModalAlertBody>
          </Modal>
        </WrapperLayout>
      ) : (
        <Loading />
      )}
    </>
  );
};
