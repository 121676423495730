import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { useForm } from "react-hook-form";
import { notification, Tooltip } from "antd";
import { Editor } from "react-draft-wysiwyg";
import { Modifier, EditorState, convertToRaw, ContentState } from "draft-js";
import FormWrapper from "../../../../styles/FormWrapper";
import axios from "axios";

// Text editor components
import { FormRegisterTemplate } from "./styles";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { Parser as ReactParser } from "html-to-react";
import { API } from "../../../../services/api";
import { useNavigate } from "react-router";
import { useCampaign } from "../../../../contexts/campaignRegister";
import { useNotification } from "../../../../contexts/session";

// Styles
import {
  InputDefault,
  ButtonSquaredDefault,
  LabelContent,
} from "../../../../styles/Form";

const Context = React.createContext({ name: "Default" });

export default function FormTemplate() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  // Objeto de estado do editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // Estado e contexto das notificações
  const [api, contextHolder] = notification.useNotification();
  // Valores padrão das tags
  const defaultFields = [
    "{{Nome do Solicitante}}",
    "{{Id do ticket}}",
    "{{Assunto do ticket}}",
    "{{Avaliação}}",
  ];

  // HTML que será salvo no banco
  const [html, setHtml] = useState("");
  const [htmlEditable, setHtmlEditable] = useState("");

  // Valores do select de idiomas
  const [languages, setLanguages] = useState([]);
  const [loadedLanguages, setLoadedLanguages] = useState(false);
  const [renderedContent, setRenderedContent] = useState("");

  // Contextos
  const { changingLanguage } = useCampaign();
  const { openNotification } = useNotification();

  const onSubmit = (data) => {
    // Adiciona o corpo do template ao formulário
    data["content"] = html;
    data["contentEditable"] = htmlEditable;

    API({ method: "POST", url: "templates", data: data })
      .then(({ id }) => {
        openNotification({
          description: "Novo template cadastrado",
          message: "Cadastrado",
          status: "error",
        });
        if (changingLanguage) navigate(`campaigns/new`);
        else navigate(`/campaigns/templates`);
      })
      .catch((err) => {
        openNotification({
          description: "Não foi possível cadastrar",
          message: "Erro",
          status: "error",
        });
      });
  };

  const addSnippet = (SnippetName) => {
    const snippet = `<span>${SnippetName}</span><span style="background-color: rgba(255, 255, 255, 1)"> </span></span>`;
    const _editorState = editorState;

    let { contentBlocks, entityMap } = htmlToDraft(snippet);
    let contentState = Modifier.replaceWithFragment(
      _editorState.getCurrentContent(),
      _editorState.getSelection(),
      ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
    );

    const es = EditorState.push(_editorState, contentState, "insert-fragment");

    setEditorState(es);
  };

  const checkErrors = (e) => {
    if (Object.keys(errors).length > 0) {
      openNotification({
        description: "Preencha todos os campos antes de continuar",
        message: "Oops",
        status: "info",
      });
    }
  };

  /**
   * Gambiarras abaixo, substituir futuramente a geração dos templates de
   * avaliação trazendo as informações do cadastro de tipos de avaliação do banco
   * na coleção ratingTypes
   */

  const changeEditorState = (state) => {
    setEditorState(state);
    const rawContentState = convertToRaw(state.getCurrentContent());
    let markup = draftToHtml(
      rawContentState,
      {
        trigger: "#",
        separator: " ",
      },
      true
    );

    const htmlToReactParser = new ReactParser();

    let npsRating = `
      <div
        style="
          display: flex;
          height: 28px;
        "
      >`;

    [1, 2, 3, 4, 5].map(
      (item) =>
        (npsRating += `
            <a style="text-decoration: none;"href="<<<link-ption${item}>>>">
              <div
              align="center"
              width: 50;
              height: 35;
              style="
                padding-top: 20%;
                justify-content: center;
                border: 1px solid #989898;
                width: 50px;
                height: 35px;
                font-weight: bold;
                color: white;
                font-size: 16px;
                background-color: #bcb9b9;
              "
              >  
                ${item}
              </div>
            </a>
          `)
    );
    npsRating += `</div>`;

    setHtmlEditable(markup);
    markup = markup.replace("{{Avaliação}}", npsRating);

    setHtml(markup);
    setRenderedContent(htmlToReactParser.parse(markup));
  };

  useEffect(() => {
    if (loadedLanguages) return;
    setLoadedLanguages(true);
    const subdomain = window.location.origin.split(".")[0];
    const requestUrl = `${subdomain}.${process.env["REACT_APP_BACK_URL"]}/config/langs`;

    if (changingLanguage) {
      setValue("language", changingLanguage);
      return;
    }

    axios.get(requestUrl).then(({ data }) => {
      setLanguages(data.value);
      setValue("language", "pt-BR");
    });
  }, [languages]);

  return (
    <FormWrapper>
      <div className="form-body" style={{ width: "100%" }}>
        <FormRegisterTemplate onSubmit={handleSubmit(onSubmit)}>
          <div className="third-row">
            <div className="box-field">
              <label htmlFor="name">
                <LabelContent>Nome</LabelContent>
              </label>
              <InputDefault
                id="name"
                type="text"
                placeholder="Nome"
                {...register("name", { required: true })}
              />
            </div>
            <div></div>
            <div></div>
          </div>

          {!changingLanguage && (
            <div className="third-row">
              <div className="box-field">
                <label htmlFor="name">Idioma</label>
                <select
                  className="input-form shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="language"
                  type="text"
                  {...register("language", { required: true })}
                >
                  {languages.map((language) => (
                    <option
                      className="option-languege h-10"
                      key={language.code}
                      value={language.code}
                    >
                      {language.name}
                    </option>
                  ))}
                </select>
              </div>
              <div></div>
              <div></div>
            </div>
          )}
          <div className="box-field">
            <div>
              <LabelContent>Campos padrão:</LabelContent>
            </div>
            <div className="default-fields-wrapper">
              {defaultFields.map((field, index) => (
                <div
                  className="template-snippet"
                  key={index}
                  onClick={() => addSnippet(field)}
                  defaultChecked={index === 0}
                  style={
                    field === "{{Avaliação}}"
                      ? {
                          backgroundColor: "#00A9B3",
                          color: "white",
                        }
                      : {}
                  }
                >
                  {field}
                </div>
              ))}
            </div>
          </div>
          <div className="editorWrapper">
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editor-body"
              onEditorStateChange={changeEditorState}
            />
          </div>
          <div className="box-field">
            <div>Pré visualização:</div>
            <div className="box-preview">{renderedContent}</div>
          </div>
          <div className="confirm-wrapper">
            <ButtonSquaredDefault type="submit" onClick={checkErrors}>
              Salvar
            </ButtonSquaredDefault>
          </div>
        </FormRegisterTemplate>
      </div>
    </FormWrapper>
  );
}
