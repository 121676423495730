import React, { useState, useEffect } from "react";

// Icones
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { WrapperRating } from "./styles.js";

import { getSubdomain } from "utils";

import RatingFreshdesk from "../RatingFreshdesk";

export default function RatingNps(props) {
  const { min, max, value, onChange, typeRating } = props;
  const [arrayValues, setArrayValues] = useState([1]);
  const [subdomain, setSubdomain] = useState("");

  useEffect(() => {
    let arrayNotes = [];
    for (let i = min; i <= max; i++) arrayNotes.push(i);
    setArrayValues(arrayNotes);
    setSubdomain(getSubdomain());
  }, [min, max]);

  return (
    <WrapperRating>
      {/* {subdomain} */}
      {subdomain === "magalu" ? (
        <RatingFreshdesk onChange={onChange} value={value} />
      ) : (
        <>
          {typeRating === "simounao" ? (
            <>
              <div className="respYN">
                <div
                  className={`btnResp ${value == 1 ? "btnSelected" : ""}`}
                  onClick={() => onChange(1)}
                >
                  SIM
                </div>
                <div
                  className={`btnResp ${value == 0 ? "btnSelected" : ""}`}
                  onClick={() => onChange(0)}
                >
                  NÃO
                </div>
              </div>
            </>
          ) : (
            <>
              {arrayValues.map((v, index) => (
                <FontAwesomeIcon
                  key={v}
                  className={`rating-star ${
                    value >= v && value != null
                      ? `star-selected`
                      : `star-unselected`
                  }`}
                  icon={faStar}
                  onClick={() => onChange(v)}
                />
              ))}
              <div className="rating-value">
                <span>{value == null ? "?" : value}</span>
                <FontAwesomeIcon
                  className={`rating-star-value star-selected`}
                  icon={faStar}
                />
              </div>
            </>
          )}
        </>
      )}
    </WrapperRating>
  );
}
