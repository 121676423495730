import styled from "styled-components";

export const SidebarBody = styled.aside`
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .wrapper-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    padding: 0.7rem 5px;

    .box-image-logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .wrapper-items {
    width: 100%;
    .item {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 2.1rem;
      margin: 2rem 0px;
      color: white;
      transition: all 0.5s ease-in-out;
      :hover {
        transform: translateX(5px);
        transition: all 0.1s ease-in-out;
      }
    }

    .active-item {
      color: rgb(200, 200, 200);
    }
  }

  .wrapper-options {
    display: flex;
    justify-content: center;
    padding: 15px 0px;
  }
`;

export const OptionsMenu = styled.div`
  width: 8rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  .account-link {
    color: black !important;
    display: block;
    padding: 0.5rem 1rem;
    :hover {
      background: rgba(116, 130, 133, 0.5);
    }
  }
`;
