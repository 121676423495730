import styled from "styled-components";

export const InputDefault = styled.input`
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  appearance: none;
  border-width: 1px;
  border-radius: 0.25rem;
  width: 100%;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  color: rgb(55, 65, 81);
  line-height: 1.25;
  outline: 2px solid transparent;
  outline-offset: 2px;
  :focus {
    box-shadow: 0px 0px 0px 1px #55afd3;
  }
`;

export const LabelDefault = styled.label`
  display: block;
  color: rgb(55, 65, 81);
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

export const SubtitleFormDefault = styled.div`
  display: block;
  color: rgb(55, 65, 81);
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  width: 100%;
  text-align: center;
`;

export const FormSectionDefault = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  padding-top: 5px;
  width: 100%;
`;

export const ButtonSquaredDefault = styled.button`
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
`;

export const LabelContent = styled.div`
  display: block;
  color: rgb(55, 65, 81);
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;
// Estilos de forms
