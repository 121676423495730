import React from "react";
import { Link } from "react-router-dom";
import { useSession } from "../../../../contexts/session";
import { DotChartOutlined, MoneyCollectOutlined } from "@ant-design/icons";
import { Dropdown, Tooltip } from "antd";

// Assets
import IconeLogo from "../../../../assets/IconeLogoLoupen.png";
import LogoNPS from "../../../../assets/LogoNpsAPPV2.png";

// Styles
import { SidebarBody, OptionsMenu } from "./styles";

export default function Sidebar() {
  const { validateLogin, activePage, setActivePage } = useSession();
  const pages = [
    {
      name: "Admin Dashboard",
      path: "/admin",
      icon: <DotChartOutlined />,
    },
    {
      name: "Planos",
      path: "/admin/plans",
      icon: <MoneyCollectOutlined />,
    },
  ];

  function updatePage(pageName) {
    validateLogin();
    setActivePage(pageName);
  }

  const optionsHelpMenu = (
    <OptionsMenu className="w-32 bg-white rounded-lg shadow-lg py-2">
      <Link
        to="#"
        className="menu-link block px-4 py-2 account-link hover:text-white"
      >
        Documentação
      </Link>
      <a
        href="https://suporte.loupen.com.br/support/tickets/new"
        target="_blank"
        rel="noreferrer"
        className="menu-link block px-4 py-2 account-link hover:text-white"
      >
        Suporte
      </a>
    </OptionsMenu>
  );

  return (
    <SidebarBody className="gradient">
      <div className="wrapper-logo">
        <Link to="/home" onClick={() => validateLogin()}>
          <div className="flex justify-center items-center">
            <img alt="Home" src={LogoNPS} width="70" height="90" />
          </div>
        </Link>
      </div>
      <nav className="wrapper-items">
        {pages.map((page, index) => (
          <Tooltip
            title={page.name}
            key={index}
            placement="right"
            color="green"
          >
            <Link
              className={` item ${page.name === activePage && "active-item"}`}
              to={page.path}
              onClick={() => updatePage(page.name)}
            >
              {page.icon}
            </Link>
          </Tooltip>
        ))}
      </nav>
      <div className="wrapper-options">
        <Dropdown overlay={optionsHelpMenu} placement="topLeft">
          <img src={IconeLogo} alt="Loupen" width={40} height={40} />
        </Dropdown>
      </div>
    </SidebarBody>
  );
}
