import React, { useEffect } from "react";
import { Tabs, Dropdown, Menu, Button } from "antd";
import {
  DashboardOutlined,
  UnorderedListOutlined,
  CalendarOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Pie } from "@ant-design/charts";
import { API } from "services/api";
import { useNotification } from "contexts/session";

// Componentes
import DataList from "./DataList";

// Styles
import { WrapperPage, DashboardWrapper, DataListWrapper } from "./styles";
import SkeletonDashboard from "components/home/global/skeletons/skeletonDashboard";

const { TabPane } = Tabs;

export default function Dashboard() {
  // Estado inicial do gráfico
  var [globalChartData, setGlobalChartData] = React.useState([
    {
      type: "Loading...",
      value: 100,
    },
  ]);

  // Dados
  const [totalRatings, setTotalRatings] = React.useState(0);
  const [awseredRatings, setAwseredRatings] = React.useState(0);
  const [npsGrade, setNpsGrade] = React.useState(0);
  const [npsClassification, setNpsClassification] = React.useState({
    class: "Excelente",
    color: "#82E0AA",
  });
  const [campaigns, setCampaigns] = React.useState([]);

  const statusNps = [
    { class: "Excelente", color: "#82E0AA" },
    { class: "Muito Bom", color: "#85C1E9" },
    { class: "Razoavel", color: "#C39BD3" },
    { class: "Ruim", color: "#D98880" },
    { class: "Não avaliado", color: "#F7DC6F" },
  ];

  // Estados
  const [loaded, setLoaded] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(true);
  const [globalChartText] = React.useState("Loading...");
  const [selectedCampaign, setSelectedCampaign] = React.useState({
    name: "Carregando",
    ratingType: "nps",
  });

  // Hooks
  const { openNotification } = useNotification();

  const [globalChartConfig, setGlobalChartConfig] = React.useState({
    appendPadding: 10,
    data: globalChartData,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: globalChartText,
      },
    },
  });

  function loadData({ campaign }) {
    API({ method: "POST", url: "ratings/dashboard", data: { campaign } }).then(
      (response) => {
        // Dados
        let averageGlobalNotes = 0;
        let npsClasses = {
          promotores: 0,
          neutros: 0,
          detratores: 0,
        };
        console.log("RESPONSE");
        console.log(response);
        setTotalRatings(response.total);

        let newGlobalData = response.global.map((grade) => ({
          type: `Nota ${grade._id}`,
          value: grade.total,
          color: "#CD6155",
        }));
        let totalRatings = response.global.reduce((acc, grade) => {
          return acc + grade.total;
        }, 0);
        setAwseredRatings(totalRatings);
        response.global.forEach((element) => {
          averageGlobalNotes += (element.total * element._id) / totalRatings;
          if (element._id >= 9) npsClasses.promotores += element.total;
          else if (element._id < 9 && element._id >= 7)
            npsClasses.neutros += element.total;
          else if (element._id < 6 && element._id >= 0)
            npsClasses.detratores += element.total;
        });

        // Carrega primeiro os dados do gráfico para Dar uma resposta mais rápida
        setGlobalChartConfig({
          ...globalChartConfig,
          data: newGlobalData,
          statistic: {
            ...globalChartConfig.statistic,
            content: {
              ...globalChartConfig.statistic.content,
              content: `Média\n${averageGlobalNotes.toFixed(2)}`,
            },
          },
        });
        let newNpsGrade =
          ((npsClasses.promotores - npsClasses.detratores) / totalRatings) *
          100;
        setNpsGrade(newNpsGrade.toFixed(0));

        if (newNpsGrade >= 75 && newNpsGrade <= 100)
          setNpsClassification(statusNps[0]);
        else if (newNpsGrade >= 50 && newNpsGrade <= 75)
          setNpsClassification(statusNps[1]);
        else if (newNpsGrade >= 0 && newNpsGrade < 50)
          setNpsClassification(statusNps[2]);
        else if (newNpsGrade >= -100 && newNpsGrade <= -1)
          setNpsClassification(statusNps[3]);
        else if (isNaN(newNpsGrade)) {
          setNpsClassification(statusNps[4]);
          setNpsGrade(0);
        }
        setLoadingData(false);
      }
    );
  }

  function loadCampaigns() {
    return new Promise((res, rej) => {
      API({ method: "GET", url: "campaigns" })
        .then(({ data }) => {
          res(data);
          setCampaigns(data);
        })
        .catch(() => {
          rej({ res: "Load campaigns" });
        });
    });
  }

  function selectCampaign({ key }) {
    const campaign = campaigns.find((e) => e.id == key);
    setSelectedCampaign(campaign);
    setLoadingData(true);
    loadData({ campaign: campaign.id });
  }

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
    loadCampaigns()
      .then((data) => {
        setCampaigns(data);
        const [campaign] = data;
        setSelectedCampaign(campaign);
        loadData({ campaign: campaign.id });
      })
      .catch((err) => {
        openNotification({
          message: "Oops",
          description: "Não foi possível carregar as campanhas",
          status: "INFO",
        });
      });
  }, []);

  return (
    <WrapperPage>
      <Tabs
        style={{ height: "100%" }}
        defaultActiveKey="1"
        centered
        type="card"
      >
        <TabPane
          tab={
            <span>
              <DashboardOutlined />
              Dashboard
            </span>
          }
          key="1"
        >
          {loadingData ? (
            <SkeletonDashboard />
          ) : (
            <DashboardWrapper>
              <div className="campaign-selector">
                <span className="selector-title">Campanha</span>
                <Dropdown
                  overlay={
                    <Menu onClick={selectCampaign}>
                      {campaigns.map((campaign, idx) => (
                        <Menu.Item key={campaign.id}>{campaign.name}</Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <Button>
                    {selectedCampaign.name} <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
              <div>
                <div className="geral-graph">
                  <div className="pie-chart card">
                    <div className="card-title">Notas</div>
                    <div className="chart-body">
                      <Pie {...globalChartConfig} />
                    </div>
                  </div>
                  <div className="flex flex-col w-50" style={{ width: "55%" }}>
                    <div
                      className="flex w-100 "
                      style={{ height: "130px", marginBottom: "10px" }}
                    >
                      <div className="informations card">
                        <div className="card-title">Avaliações</div>
                        <div className="informations-body">
                          <table>
                            <tbody>
                              <tr className="main-information information">
                                <td>Enviadas</td>
                                <td>{totalRatings}</td>
                              </tr>
                              <tr className="information">
                                <td>Respondidas</td>
                                <td>{awseredRatings}</td>
                              </tr>
                              <tr className="information">
                                <td>Em aberto</td>
                                <td>{totalRatings - awseredRatings}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="informations card">
                        <div className="card-title">Formulários</div>
                        <div className="informations-body">
                          <table>
                            <tbody>
                              <tr className="main-information information">
                                <td>Enviados</td>
                                <td>{totalRatings}</td>
                              </tr>
                              <tr className="information">
                                <td>Respondidos</td>
                                <td>{awseredRatings}</td>
                              </tr>
                              <tr className="information">
                                <td>Em aberto</td>
                                <td>{totalRatings - awseredRatings}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div>
                      {selectedCampaign.ratingType === "nps" && (
                        <div
                          className="informations card"
                          style={{ height: "150px" }}
                        >
                          <div className="card-title">NPS</div>
                          <div
                            className="informations-body"
                            style={{ height: "65%" }}
                          >
                            <div className="npsNote">
                              <div className="grade-icon-wrapper">
                                <div
                                  className="note"
                                  style={{
                                    backgroundColor: npsClassification.color,
                                  }}
                                >
                                  {npsGrade}
                                </div>
                              </div>
                              <div className="nps-class-name">
                                {npsClassification.class}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </DashboardWrapper>
          )}
        </TabPane>
        <TabPane
          tab={
            <span>
              <UnorderedListOutlined />
              Dados
            </span>
          }
          key="2"
        >
          <DataList />
        </TabPane>
      </Tabs>
    </WrapperPage>
  );
}
