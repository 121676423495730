import styled from "styled-components";

export const WrapperRating = styled.form`
  display: flex;
  align-items: center;
  .rating-star {
    font-size: 1.15rem;
    cursor: pointer;
    transform: scale(1);
  }
  .rating-star-value {
    font-size: 1.2rem;
  }
  .rating-star:hover {
    transform: scale(1.3);
  }
  .star-selected {
    color: #ffc107;
  }
  .star-unselected {
    color: #e4e5e9;
  }
  .rating-value {
    border: 1px solid #d2d3d6;
    border-radius: 5px;
    padding: 3px 5px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    span {
      margin-right: 5px;
    }
  }
  .respYN {
    display: flex;
    gap: 10px;
    .btnResp {
      color: white;
      font-weight: 600;
      padding: 8px 30px;
      border-radius: 30px;
      background-color: #00adac;
      width: 80%;
      display: flex;
      cursor: pointer;
      :hover {
        background-color: #0d9291;
      }
    }
  }
  .btnSelected {
    background-color: #0d7877 !important;
  }
  .ratingFreshdeskDefault {
  }
`;
