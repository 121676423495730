import React, { createContext, useContext, useState } from "react";

/**
 * Contexto para armazenar os dados do cadastro de campanha
 */

const NewCampaignContext = createContext({ name: "NewCampaign" });
export function CampaignRegisterProvider({ children }) {
  const [name, setName] = useState("");
  const [ratingType, setRatingType] = useState("");
  const [envelopes, setEnvelopes] = useState([]);
  const [ratingTypes, setRatingTypes] = useState([]);

  const [changingLanguage, setChangingLanguage] = useState("");

  return (
    <NewCampaignContext.Provider
      value={{
        name,
        setName,
        ratingType,
        setRatingType,
        envelopes,
        setEnvelopes,
        ratingTypes,
        setRatingTypes,
        changingLanguage,
        setChangingLanguage,
      }}
    >
      {children}
    </NewCampaignContext.Provider>
  );
}

export function useCampaign() {
  const context = useContext(NewCampaignContext);
  if (!context)
    throw new Error("useSession need to be used inside of NewCampaignProvider");
  return context;
}
