import styled from "styled-components";

export const ModalBody = styled.div`
  font-family: karla;
  .modal-title {
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #e6e6e6;
    h1 {
      font-size: 1.1rem;
    }
  }
`;

export const BoxSelectedCampaign = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid #e6e6e6;
  margin-top: 15px;
  border-radius: 8px;
  padding: 10px 0px;
  .message-select-campaign {
    color: rgb(100, 100, 100);
  }
  .selected-campaign {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.2);
    padding: 10px 10%;
    width: 50%;
    .campaign-id {
      font-size: 0.8rem;
      color: rgb(100, 100, 100);
    }
  }
`;

export const BoxFormTriggers = styled.div`
  height: 88%;
  .form {
    height: 100%;
    margin-top: 10px;
    justify-content: space-between;
    display: flex;
    align-content: space-between;
    flex-direction: column;
    .field-box {
      width: 350px;
    }
  }
  .error-select-campaign {
    color: rgb(100, 0, 0);
    text-align: center;
  }
  .campaign-selector-wrapper {
    .campaign-selector-title {
      font-size: 1rem;
      text-align: center;
      margin: 0px 50px 0px 50px;
      padding-bottom: 8px;
      border-bottom: 1px solid #e6e6e6;
    }
  }
  .wrapper-button-save {
    display: flex;
    justify-content: center;
    button {
      width: 100px;
    }
  }
  .message-select-campaign {
  }
`;
