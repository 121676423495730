import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { API } from "services/api";

// Icones
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";

// Estilos
import { FormWrapper, ScreenTitle, ButtonBlueDefault } from "styles/Global";
import { WrapperButtonsEdit, WrapperPreview } from "./styles";

export default function View() {
  const params = useParams();
  const { id } = params;

  const [form, setForm] = React.useState({});

  useEffect(() => {
    API({ method: "GET", url: `forms/${id}` }).then((res) => {
      console.log("teste");
      console.log(res);
      setForm(res);
    });
  }, [id]);

  return (
    <>
      <FormWrapper>
        <ScreenTitle>
          <span>
            <FontAwesomeIcon icon={faAlignLeft} />
          </span>
          {form.name}
        </ScreenTitle>
        <WrapperPreview>
          <div className="field-preview">
            <h2 className="title-field-preview">Perguntas</h2>
            <div className="wraper-content">
              {form.questions ? (
                form.questions.map((question) => (
                  <div className="content-field-preview">
                    <div className="title-content-field-preview">
                      {question.question}
                    </div>
                    <div className="required-field">
                      <div className="snippet-required">
                        {question.required ? "Obrigatório" : "Não obrigatório"}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="field-preview mt-3">
            <h2 className="title-field-preview">Idioma</h2>
            <div className="box-languages">
              <div className="language-snippet">Português</div>
            </div>
          </div>
        </WrapperPreview>
        {/* <WrapperButtonsEdit>
          <ButtonBlueDefault>Editar</ButtonBlueDefault>
        </WrapperButtonsEdit> */}
      </FormWrapper>
    </>
  );
}
