import styled from "styled-components";

export const ModalSelectLanguageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  .search-field {
    width: 100%;
    margin-bottom: 4px;
    display: flex;
    input {
      width: 95%;
    }
    .icon-search {
      display: flex;
      cursor: pointer;
      font-weight: bold;
      font-size: 1.2rem;
      justify-content: center;
      align-items: center;
      margin-left: 2px;
      width: 8%;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
    }
  }

  .select-languages {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    .language {
      cursor: pointer;
      text-align: center;
      padding: 5px 0;
      border-bottom: 1px solid #e6e6e6;
      font-size: 1rem;
      :hover {
        background-color: #f5f5f5;
      }
      :active {
        background-color: #e3e3e3;
      }
    }
    .selected {
      background-color: #b2df50;
      :hover {
        background-color: #a0c947;
      }
    }
  }
`;
