import React from "react";
import { ButtonBlueDefault } from "styles/Global";
import { Link } from "react-router-dom";

import { PlansBannerWrapper } from "./styles";

export default function PlansBanner() {
  return (
    <PlansBannerWrapper>
      <div className="header-plans">
        <div className="title-plans">PLANOS</div>
        <div className="subtitle-plans">
          Escolha um plano que se encaixe nos seus objetivos
          <br /> ou <a>entre em contato</a> para conhecer mais sobre nossas
          soluções personalizadas.
        </div>
      </div>
      <div className="wrapper-plans">
        {[1, 2, 3].map((idx) => (
          <div key={idx} className="card-plan">
            <div className="price-box">R$ 200.25</div>
            <div className="title-box">Basic</div>
            <div className="decription-box">
              <ul>
                <li>1000 envios transacionais / mês</li>
                <li>Templates ilimitados</li>
                <li>Formulários básicos ilimitados</li>
                <li>Formulários avançados</li>
              </ul>
            </div>
            <div className="wrapper-button-plan">
              <Link to="checkout/123" className="link-button">
                <ButtonBlueDefault>INICIAR AVALIAÇÃO</ButtonBlueDefault>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </PlansBannerWrapper>
  );
}
