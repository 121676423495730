import React, { useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// Components
import Footer from "components/landings/footer";
import WhiteLoading from "components/global/WhiteLoading";

// Images
import Logo from "assets/LogoNpsAPPV2.png";

// Styles
import { WrapperPageRegister } from "./styles";
import { ButtonBlueDefault } from "styles/Global";

import { API } from "services/api";
import { useNotification } from "contexts/session";

import Lottie from "react-lottie";
import LootieConfirm from "assets/lotties/64787-success.json";

export default function AccountRegistration() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { token } = useParams();
  const { openNotification } = useNotification();

  const navigate = useNavigate();

  // Estados
  const [loaded, setLoaded] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [textMessage, setTextMessage] = React.useState("Buscando informações");
  const [showconfirmRegister, setShowconfirmRegister] = React.useState(false);
  const [confirmationTextError, setConfirmationTextError] = React.useState("");

  const [successRegistration, setSuccessRegistration] = React.useState(false);

  // Lottie
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: LootieConfirm,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Dados
  const [linkData, setLinkData] = React.useState(false);

  // function accessLogin() {
  //   window.location.href = `${process.env["REACT_APP_FRONT_PROTOCOL"]}://${linkData.tenant}.${process.env["REACT_APP_FRONT_URL"]}/login`;
  // }

  function onSubmit(data) {
    console.log(data);
    if (data.password !== data.passwordConfirmation) {
      setConfirmationTextError("As senhas não conferem");
      return;
    } else setConfirmationTextError("");
    setRequestLoading(true);
    API({
      method: "POST",
      url: `account/registration/${token}`,
      headers: { Authorization: "registry" },
      data: {
        password: data.password,
        tenantName: linkData.tenant,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === "success") {
          setRequestLoading(false);
          setSuccessRegistration(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);

    API({
      method: "GET",
      url: `account/registration/${token}`,
      headers: { Authorization: "registry" },
    })
      .then((linkDataForm) => {
        setLinkData(linkDataForm);
        setTextMessage("Quase lá!");
        console.log("Confimando conta");
        console.log(linkDataForm);
        setShowconfirmRegister(true);
      })
      .catch(({ response }) => {
        const { status } = response.data;
        setTextMessage("Link inválido");
        if (status === "Expirated") {
          openNotification({
            message: "Expirado",
            description: "O link não é mais válido",
            status: "info",
          });
        }
      });
  });

  return (
    <WrapperPageRegister className="gradient  min-h-screen pt-5 pb-6 px-2 md:px-0">
      {requestLoading && <WhiteLoading />}
      <header className="max-w-lg mx-auto justify-center flex">
        <Link to="/">
          <div className="m-0 p-0 cursor-pointer">
            <img alt="Home" src={Logo} width="200" height="50" />
          </div>
        </Link>
      </header>
      {successRegistration ? (
        <main className="bg-white max-w-lg mx-auto px-8 pb-8 md:px-12 md:pb-8 md:pt-5  pt-5 mb-4 mt-5 rounded-lg shadow-2xl box-form-window">
          <section className="justify-center flex">
            <h3 className="font-bold text-2xl">Sucesso</h3>
          </section>
          <section>
            <Lottie options={defaultOptions} height={200} width={200} />
          </section>
          <section className="text-confirm-register">
            Sua conta foi confirmada, você já pode fazer login.
            <div className="button-login-wrapper">
              <ButtonBlueDefault
                onClick={() => {
                  window.location.href = `${process.env["REACT_APP_FRONT_PROTOCOL"]}://${linkData.tenant}.${process.env["REACT_APP_FRONT_URL"]}/login`;
                }}
              >
                Login
              </ButtonBlueDefault>
            </div>
          </section>
        </main>
      ) : (
        <main className="bg-white max-w-lg mx-auto px-8 pb-8 md:px-12 md:pb-8 md:pt-5  pt-5 mb-4 mt-5 rounded-lg shadow-2xl box-form-window">
          <section className="justify-center flex">
            <h3 className="font-bold text-2xl">{textMessage}</h3>
          </section>

          {showconfirmRegister && (
            <section className="mt-2 wrapper-form-confirmation">
              <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
                <div className="title-instructions">
                  Crie uma senha bem forte 💪
                </div>
                <div className="mb-3 pt-1 rounded ">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="name"
                  >
                    Senha
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="name"
                    type="password"
                    name="name"
                    placeholder="••••••••••••"
                    {...register("password", {
                      required: true,
                    })}
                  />
                </div>

                <div className="mb-3 pt-1 rounded ">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="name"
                  >
                    Confirmar Senha
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="name"
                    type="password"
                    name="name"
                    placeholder="••••••••••••"
                    {...register("passwordConfirmation", {
                      required: true,
                    })}
                  />
                </div>
                <div className="flex align-center justify-center">
                  <ButtonBlueDefault type="submit" disabled={requestLoading}>
                    Começar
                  </ButtonBlueDefault>
                </div>
                {confirmationTextError != "" && (
                  <div className="confirmation-text-error">
                    {confirmationTextError}
                  </div>
                )}
              </form>
            </section>
          )}
        </main>
      )}

      <div className="max-w-lg mx-auto text-center mt-12 mb-6">
        <Footer />
      </div>
    </WrapperPageRegister>
  );
}
