import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { parseCookies, setCookie, destroyCookie } from "nookies";
import { notification } from "antd";
import axios from "axios";
import { API } from "../services/api";

/**
 * Context encharaged to store session data.
 */

const SessionContext = createContext({ name: "Session" });

export function SessionProvider({ children }) {
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();
  const [activePage, setActivePage] = useState("Dashboard");
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    role: "",
    active: false,
    tenant: "",
  });
  const [activeTenant, setActiveTenant] = useState("");

  const logout = () => {
    setUserData({
      name: "",
      email: "",
      role: "",
      active: false,
      tenant: "",
    });
    setActiveTenant("");
    destroyCookie(null, "cookieValidate");
    destroyCookie(null, "JWT");

    window.location.href = `${process.env.REACT_APP_FRONT_PROTOCOL}://${process.env["REACT_APP_FRONT_URL"]}`;
  };

  /**
   *
   * @param message message
   * @param description [error, success, info, ""]
   * @param status
   */
  const openNotification = (props) => {
    const { message, description, status } = props;
    let placement = "topRight";
    let content = {
      message: message,
      description: (
        <SessionContext.Consumer>
          {({ name }) => `${description}`}
        </SessionContext.Consumer>
      ),
      placement,
    };
    if (status === "error") {
      api.error(content);
    } else if (status === "success") {
      api.success(content);
    } else if (status === "info") {
      api.info(content);
    } else {
      api.open(content);
    }
  };

  // Modal
  const [modalAlertOpened, setModalAlertOpened] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("");
  const [modalFunction, setModalFunction] = useState(() => {});

  const openModalAlert = (props) => {
    const { message, onConfirm } = props;
    setModalAlertMessage(message);
    setModalFunction(onConfirm);
    setModalAlertOpened(true);
  };

  const setLogged = (userInfo) => {
    if (!userInfo)
      setUserData({
        active: false,
      });
    else
      setUserData({
        name: userInfo.name || "",
        email: userInfo.email || "",
        role: userInfo.role || "",
        active: true,
        tenant: userInfo.tenant || "",
      });
    setActiveTenant(userInfo.tenant || "");
  };

  const validateLogin = async () => {
    return new Promise((resolve, reject) => {
      const cookies = parseCookies();
      const { JWT, cookieValidate } = cookies;
      const requestUrl = `${process.env["REACT_APP_API_PROTOCOL"]}://${process.env["REACT_APP_BACK_URL"]}/auth/refresh`;

      if (
        cookieValidate &&
        new Date(cookieValidate).getTime() >= new Date().getTime()
      ) {
        if (!userData.active && JWT) {
          axios
            .get(requestUrl, {
              headers: {
                Authorization: `Bearer ${JWT}`,
              },
            })
            .then(({ data }) => {
              const localDate = new Date();
              const expirationTime =
                localDate.getTime() + 5 * 24 * 60 * 60 * 1000;
              const expirationDate = new Date(
                localDate.getTime() + 5 * 24 * 60 * 60 * 1000
              );
              setCookie(null, "JWT", data.accessToken, {
                maxAge: expirationTime,
                path: "/",
              });
              setCookie(null, "cookieValidate", expirationDate, {
                maxAge: expirationTime,
                path: "/",
              });
              setLogged(data.user);
              return resolve(true);
            })
            .catch(() => {
              setLogged(false);
              return resolve(false);
            });
        }
      } else {
        setLogged(false);
        return resolve(false);
      }
    });
  };

  const validateLoginAdmin = async () => {
    await validateLogin();
    API({ method: "GET", url: "auth/admin" }).then((data) => {
      console.log(data);
    });
  };

  return (
    <SessionContext.Provider
      value={{
        userData,
        activeTenant,
        logout,
        setLogged,
        validateLogin,
        openNotification,
        openModalAlert,
        modalAlertOpened,
        modalAlertMessage,
        modalFunction,
        setModalAlertOpened,
        activePage,
        setActivePage,
        validateLoginAdmin,
      }}
    >
      {contextHolder}
      {children}
    </SessionContext.Provider>
  );
}

export function useSession() {
  const context = useContext(SessionContext);
  if (!context)
    throw new Error("useSession need to be used inside of SessionProvider");
  return context;
}

export function useNotification() {
  const context = useContext(SessionContext);
  if (!context)
    throw new Error(
      "UseNotification need to be used inside of SessionProvider"
    );
  const { openNotification } = context;
  return { openNotification };
}

export function useModalAlert() {
  const context = useContext(SessionContext);
  if (!context)
    throw new Error("useModalAlert need to be used inside of SessionProvider");
  return context;
}
