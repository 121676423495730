import styled from "styled-components";

export const ModalAlertBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .close-modal-wrapper {
    position: absolute;
    right: 0;
    cursor: pointer;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    top: 10;
    width: 50px;
  }
  .modalMessage {
    font-font-family: karla;
    font-size: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70%;
  }
  .wrapper-button {
    display: flex;
    justify-content: center;
    width: 60%;
  }
`;

export const WrapperLayout = styled.div`
  font-family: karla;
  background-color: rgb(243, 244, 246);
  display: flex;
  .wrapper-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    .wrapper-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      main {
        width: 100%;
        height: 100%;
        flex-grow: 1;
        padding: 0.5rem 0.25rem;
      }
    }
  }
`;
