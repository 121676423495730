import React, { useState, useEffect } from "react";
import { ModalSelectLanguageWrapper } from "./styles";
import { Modal } from "antd";
import { useNotification } from "contexts/session";
import { SearchOutlined } from "@ant-design/icons";

export default function ModalSelectLanguage(props) {
  const { languages, visible, onOk, onCancel, addComponentHandler } = props;
  const [selected, setSelected] = useState(null);
  const [searchField, setSearchField] = useState("");
  const [listLanguages, setLisLanguages] = useState(languages);

  const { openNotification } = useNotification();

  function customOnOk() {
    if (selected) {
      onOk(selected);
      onCancel();
      return;
    }
    openNotification({ message: "Selecione um idioma", status: "info" });
  }

  function filterLanguages(value) {
    let newLanguages;
    if (value == "") newLanguages = languages;
    else
      newLanguages = languages.filter((language) =>
        language.name.toLowerCase().includes(value.toLowerCase())
      );
    setLisLanguages(newLanguages);
  }
  useEffect(() => {
    setLisLanguages(languages);
  }, [languages]);

  return (
    <Modal
      title="Novo idioma"
      centered
      width={700}
      visible={visible}
      onOk={customOnOk}
      onCancel={onCancel}
    >
      <ModalSelectLanguageWrapper>
        <div className="search-field">
          <input
            className="input-form shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            placeholder="Idioma"
            value={searchField}
            onChange={(e) => {
              setSearchField(e.target.value);
              filterLanguages(e.target.value);
            }}
          />
          <div className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline icon-search">
            <SearchOutlined />
          </div>
        </div>
        <div className="select-languages">
          {listLanguages &&
            listLanguages.map((language, index) => (
              <div
                key={index}
                className={`language ${
                  language.code == selected ? "selected" : ""
                }`}
                onClick={() => setSelected(language.code)}
              >
                {language.name}

                {language.selected && (
                  <div className="check-icon">
                    <i className="fas fa-check" />

                    <span>Selected</span>
                  </div>
                )}
              </div>
            ))}
        </div>
      </ModalSelectLanguageWrapper>
    </Modal>
  );
}
