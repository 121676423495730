import styled from "styled-components";

export const RatingTypeWrapper = styled.div`
  margin: 5px 20px;
  text-align: center;
  font-size: 1rem;
  width: 200px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  :hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    animation: all 0.8s ease-in-out;
  }
  .rating-icon {
    display: flex;
    justify-content: center;
    img {
      width: 50px;
      margin-bottom: 10px;
    }
  }
  .connection-type-name {
    font-weight: bold;
  }

  // Se for a opção selecionada
  background-color: ${(props) => (props.selected ? "#f5f5f5" : "#fffA")};
  border: ${(props) =>
    props.selected ? "2px solid #C0C0C0" : "1px solid #e6e6e6"};
`;
