import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Lottie from "react-lottie";
import { useNotification } from "contexts/session";
import LootieConfirm from "assets/lotties/64787-success.json";

// Components
import Footer from "components/landings/footer";
import WhiteLoading from "components/global/WhiteLoading";

// Images
import Logo from "assets/LogoNpsAPPV2.png";

// Styles
import { ButtonBlueDefault } from "styles/Global";
import { FormRegister, WrapperPageRegister } from "./styles";
import { API } from "services/api";

export default function Register() {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const { openNotification } = useNotification();

  // Estados
  const [loaded, setLoaded] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [requestSucess, setRequestSucess] = React.useState(false);

  // Lottie
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: LootieConfirm,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const onSubmit = (data) => {
    setRequestLoading(true);

    API({
      method: "POST",
      url: "register",
      data,
      headers: { Authorization: `registry` },
    })
      .then((data) => {
        setRequestLoading(false);
        setRequestSucess(true);
        console.log(data);
      })
      .catch(({ response }) => {
        setRequestLoading(false);
        if (!response.data) {
          openNotification({
            message: "Erro",
            description: "Houve um erro interno, por favor tente novamente",
            status: "error",
          });
          return false;
        }
        const { status } = response.data;
        if (status === "Duplicated") {
          openNotification({
            message: "Indisponível",
            description: "Já existe uma conta com este subdominio",
            status: "info",
          });
        } else if (status === "ErrorSendingEmail") {
          openNotification({
            message: "Erro",
            description: "Houve um erro interno, por favor tente novamente",
            status: "info",
          });
        } else {
          openNotification({
            message: "Erro",
            description: "Houve um erro ao criar a conta",
            status: "error",
          });
        }
      });
  };

  const checkErrors = () => {
    console.log("Deu erro 😕");
    console.log(errors);
    if (errors.email) {
      openNotification({
        message: "E-mail inválido",
        description: "Insira um e-mail válido",
        status: "error",
      });
    }
    if (errors.password) {
      openNotification({
        message: "Senha inválida",
        description: "A senha deve conter ao menos 6 caracteres",
        status: "error",
      });
    }
  };

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
    let insertedEmail = localStorage.getItem("registerEmail");
    if (insertedEmail) {
      setValue("email", insertedEmail);
      localStorage.removeItem("registerEmail");
    }
  });

  return (
    <WrapperPageRegister className="gradient  min-h-screen pt-5 pb-6 px-2 md:px-0">
      {requestLoading && <WhiteLoading />}
      <header className="max-w-lg mx-auto justify-center flex">
        <Link to="/">
          <div className="m-0 p-0 cursor-pointer">
            <img alt="Home" src={Logo} width="200" height="50" />
          </div>
        </Link>
      </header>

      {!requestSucess ? (
        <main className="bg-white max-w-lg mx-auto px-8 pb-8 md:px-12 md:pb-8 md:pt-5  pt-5 mb-4 mt-5 rounded-lg shadow-2xl box-form-window">
          <section className="justify-center flex">
            <h3 className="font-bold text-2xl">Bem vindo!</h3>
          </section>

          <section className="mt-2">
            <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3 pt-1 rounded ">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Nome
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Your Beautiful Name"
                  {...register("name", {
                    required: true,
                  })}
                />
              </div>
              <div className="mb-3 pt-1 rounded ">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  E-mail
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="seuemail@email.com"
                  {...register("email", {
                    required: true,
                  })}
                />
              </div>
              <div className="mb-5 pt-1 rounded ">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="dominio"
                >
                  Domínio
                </label>
                <div className="flex items-center py-2">
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="dominio"
                    type="text"
                    placeholder="seu-dominio"
                    {...register("domain", { required: true })}
                  />
                  <span className="ml-1">.npsapp.com.br</span>
                </div>
              </div>

              <div className="flex align-center justify-center">
                <ButtonBlueDefault
                  type="submit"
                  onClick={checkErrors}
                  disabled={requestLoading}
                >
                  Começar
                </ButtonBlueDefault>
              </div>
            </form>
          </section>
        </main>
      ) : (
        <main className="bg-white max-w-lg mx-auto px-8 pb-8 md:px-12 md:pb-8 md:pt-5  pt-5 mb-4 mt-5 rounded-lg shadow-2xl box-form-window">
          <section className="justify-center flex">
            <h3 className="font-bold text-2xl">Sucesso</h3>
          </section>
          <section>
            <Lottie options={defaultOptions} height={200} width={200} />
          </section>
          <section className="text-confirm-register">
            Enviamos um e-mail para <strong>{watch("email")}</strong> com
            instruções para confirmar sua conta.
          </section>
        </main>
      )}

      <div className="max-w-lg mx-auto text-center mt-12 mb-6">
        <p className="text-white">
          Já tem uma conta?{" "}
          <Link to="/" className="font-bold hover:underline">
            Entrar
          </Link>
        </p>
        <Footer />
      </div>
    </WrapperPageRegister>
  );
}
