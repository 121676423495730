import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faUser,
  faAddressCard,
  faPlug,
} from "@fortawesome/free-solid-svg-icons";

import ItemMenu from "components/home/admin/itemMenu";

const icons = {
  empresas: <FontAwesomeIcon icon={faBuilding} />,
  contatos: <FontAwesomeIcon icon={faAddressCard} />,
  usuarios: <FontAwesomeIcon icon={faUser} />,
  api: <FontAwesomeIcon icon={faPlug} />,
  profile: <FontAwesomeIcon icon={faAddressCard} />,
};

export default function index() {
  return (
    <div className="flex flex-wrap justify-center">
      {/* <ItemMenu
        icon={icons.empresas}
        label="EMPRESAS"
        link="/home/admin/companies/list"
      />
      <ItemMenu
        icon={icons.contatos}
        label="CONTATOS"
        link="/home/admin/companies/list"
      /> */}
      <ItemMenu icon={icons.profile} label="MEU PERFIL" link="profile" />
      <ItemMenu icon={icons.usuarios} label="USUÁRIOS" link="users" />
      {/* <ItemMenu icon={icons.api} label="API" link="users/list" /> */}
    </div>
  );
}
