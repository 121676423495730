import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { notification } from "antd";
import { setCookie } from "nookies";
import { useNotification, useSession } from "contexts/session";
import { API } from "services/api";

// Images
import LogoNPS from "assets/LogoNpsAPPV2.png";

// Styles
import { ButtonBlueDefault } from "styles/Global";
import { WrapperPageRegister } from "./styles";
import { InputDefault } from "styles/Form";

// Components
import Footer from "components/landings/footer";
import Loading from "components/global/Loading";

export default function Register(props) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { setLogged } = useSession();
  const [loginError] = useState("");
  const [checkingSession, setCheckingSession] = useState(false);
  const navigate = useNavigate();
  const { openNotification } = useNotification();
  const [checkingLogin, setCheckingLogin] = useState(true);

  function openNotificationError(typeError) {
    const descError = {
      notFilled: "Preencha os campos antes de continuar",
      loginInformations: "Confira as informações de login e senha.",
    };

    openNotification({
      message: "Oops! Não deu certo",
      description: descError[typeError] || "Houve um erro ao registrar",
      status: "error",
    });
  }

  const onSubmit = (data) => {
    const subdomain = window.location.origin.split("//")[1].split(".")[0];

    const headers = {
      Authorization: subdomain,
    };
    const body = {
      username: watch("username"),
      password: watch("password"),
    };

    console.log(body);

    API({
      window: window,
      method: "POST",
      url: "auth/login",
      data: body,
      headers: headers,
    })
      .then((data) => {
        console.log(data);
        try {
          setCookie(null, "JWT", data.accessToken, {
            maxAge: 27 * 24 * 60 * 60,
            path: "/",
          });
          const expirationDate = new Date(
            new Date().getTime() + 7 * 24 * 60 * 60
          );
          setCookie(null, "cookieValidate", expirationDate, {
            maxAge: 7 * 24 * 60 * 60,
            path: "/",
          });
          setLogged(data.user);
          navigate("/");
          // setCheckingLogin(false);
        } catch (e) {
          console.log(e);
          setCheckingLogin(false);
        }
      })
      .catch((error) => {
        openNotificationError("loginInformations");
      });
  };

  const checkErrors = () => {
    // for (let field in errors) {}
    if (Object.keys(errors).length > 0) {
      openNotificationError("notFilled");
    }
  };

  const validateLogin = async () => {
    const tenantId = window.location.origin.split(".")[0].split("//")[1];

    API({ method: "GET", url: "auth/refresh" })
      .then((response) => {
        const { accessToken } = response;
        const localDate = new Date();
        const expirationTime = localDate.getTime() + 5 * 24 * 60 * 60 * 1000;
        const expirationDate = new Date(
          localDate.getTime() + 5 * 24 * 60 * 60 * 1000
        );
        setCookie(null, "cookieValidate", expirationDate, {
          maxAge: expirationTime,
          path: "/",
        });
        setCookie(null, "JWT", accessToken, {
          maxAge: expirationTime,
          path: "/",
        });
        setLogged(response.user);
        navigate("/");
        // setCheckingLogin(false);
      })
      .catch((err) => {
        setCheckingLogin(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (!checkingSession) {
      setCheckingSession(true);
      validateLogin();
    }
  }, [checkingSession]);

  return (
    <>
      {checkingLogin ? (
        <>
          <Loading />
        </>
      ) : (
        <WrapperPageRegister className="gradient">
          <header className="header-login">
            <Link to="/">
              <div className="login-image">
                <img
                  alt="Home"
                  src={LogoNPS}
                  width="200"
                  height="50"
                  className="logo"
                />
              </div>
            </Link>
          </header>

          <main className="login-card ">
            <section className="title-box-login">
              <h3>Bem vindo!</h3>
            </section>

            <section className="section-form-login">
              <form onSubmit={handleSubmit(onSubmit)}>
                {loginError}
                <div className="wrapper-input">
                  <label htmlFor="email">E-mail</label>
                  <InputDefault
                    id="email"
                    type="email"
                    placeholder="seuemail@email.com"
                    {...register("username", { required: true })}
                  />
                </div>
                <div className="wrapper-input">
                  <label htmlFor="password">Senha</label>
                  <InputDefault
                    id="password"
                    type="password"
                    placeholder=""
                    {...register("password", { required: true })}
                  />
                </div>

                <div className="flex justify-center align-center">
                  <ButtonBlueDefault
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200"
                    type="submit"
                    onClick={checkErrors}
                  >
                    Entrar
                  </ButtonBlueDefault>
                </div>
              </form>
            </section>
          </main>

          <div className="max-w-lg mx-auto text-center mt-12 mb-6">
            <p className="text-white">
              Ainda não tem uma conta?{" "}
              <Link to="/register" className="font-bold hover:underline">
                Registrar-se
              </Link>
            </p>
            <Footer />
          </div>
        </WrapperPageRegister>
      )}
    </>
  );
}
