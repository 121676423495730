import styled from "styled-components";

export const FreshdeskRatingWrapper = styled.div``;

export const WrapperRatings = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3px;
`;

export const OptionRating = styled.div`
  width: 90px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 10px;
  align-items: center;
  border-top: 5px solid ${(props) => props.colorHeader};
  background-color: ${(props) => props.colorBody};
  box-shadow: 0 0 0 ${(props) => (props.selected ? "2px" : "0")} #dff315;
  cursor: pointer;
  .label {
    height: 70%;
    text-align: center;
    font-weight: bold;
    color: ${(props) => (props.selected ? "black" : "#646262")};
  }
  .emoji {
    height: 20%;
    font-size: 1rem;
  }
`;
