import styled from "styled-components";

export const DataListWrapper = styled.div`
  .data-filters {
    padding: 5px 0;
    display: flex;
    width: 100%;
    height: 10%;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

    .button-select-filter {
      margin-left: 5px;
      cursor: pointer;
      padding: 10px 15px;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      font-size: 1rem;
      display: flex;
      align-items: center;
      box-shadow: none;
      transition: all 0.2s ease-in-out;
      .text-name {
        margin-left: 5px;
      }

      :hover {
        transform: scale(1.03);
        transition: all 0.2s ease-in-out;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }
`;
