import { useEffect, useState } from "react";
import "./styles/output.css";
import { BrowserRouter as Router } from "react-router-dom";
import Loading from "components/global/Loading";
import { getSubdomain, getTenantContext, navigateToHome } from "utils";
import PublicRoutes from "./routes/PublicRoutes";
import PrivateRoutes from "routes/PrivateRoutes";

function App() {
  const [rootElement, setRootElement] = useState(<Loading />);

  useEffect(() => {
    (async () => {
      const subdomain = getSubdomain();
      let routeSection = "Not Found";

      try {
        routeSection = await getTenantContext(subdomain);
      } catch (error) {
        routeSection = "Not Found";
        console.log(error);
      }

      if (!subdomain) {
        setRootElement(<PublicRoutes />);
        return;
      }
      if (routeSection === "Not Found") {
        navigateToHome();
        setRootElement(<PublicRoutes route="/" />);
        return;
      }

      if (routeSection === "Validated") {
        setRootElement(<PrivateRoutes />);
        return;
      }

      // Demais rotas reservadas
    })();
  }, []);
  return <Router>{rootElement}</Router>;
}

export default App;
