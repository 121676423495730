import { Link } from "react-router-dom";
import React from "react";

export default function ItemMenu(props) {
  const { icon, label, link } = props;
  return (
    <>
      <Link to={link}>
        <div className="item-menu">
          <div className="item-menu-icon">{icon}</div>
          <div className="item-menu-label">{label}</div>
        </div>
      </Link>
      <style jsx>{`
        .item-menu {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 1.5rem;
          padding: 1rem 1.5rem;
          border-radius: 0.25rem;
          background-color: #fafafa;
          box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.5);
          margin: 1.5rem 0.8rem;
          min-width: 150px;
          min-height: 110px;
          cursor: pointer;
          justify-content: center;
          transition: transform 0.3s ease-in-out;
        }
        .item-menu:hover {
          transform: scale(1.02);
          box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.3);
          transition: transform 0.3s ease-in-out;
          transition: box-shadow 0.25s ease-in-out;
        }
        .item-menu-label {
          font-size: 0.8rem;
          font-family: "Roboto", sans-serif;
          margin-top: 0.6rem;
          color: #4a4a4a;
          font-weight: bold;
        }
        .item-menu-icon {
          color: rgb(148, 181, 209);
          font-size: 2.5rem;
          width: 25%;
          height: 25%;
          margin-bottom: 8px;
        }
      `}</style>
    </>
  );
}
