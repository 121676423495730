import React from "react";
import { Skeleton } from "antd";

import { SkeletonConnectionsContainer } from "./styles";

export default function SkeletonConnections() {
  return (
    <SkeletonConnectionsContainer>
      <Skeleton.Input
        active={true}
        size="default"
        shape="default"
        block={false}
        style={{ width: 350, height: 120 }}
      />
      <Skeleton.Input
        active={true}
        size="default"
        shape="default"
        block={false}
        style={{ width: 350, height: 120 }}
      />
      <Skeleton.Input
        active={true}
        size="default"
        shape="default"
        block={false}
        style={{ width: 350, height: 120 }}
      />
    </SkeletonConnectionsContainer>
  );
}
