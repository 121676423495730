import styled from "styled-components";

export const ItemWrapper = styled.tr`
  font-family: karla;
  .itemMiddle {
    border-right: 1px solid #e6e6e6;
    font-weight: 600;
  }
  .item-language {
    font-weight: 600;
    --tw-bg-opacity: 1;
    //background-color: rgba(243, 244, 246, 0.6);
    margin: 0 3px;
  }
`;
