import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Pagination } from "antd";

// Styles
import { PageWrapper } from "styles/ListItems";
import { BtnOptMenu, ScreenTitle } from "styles/Global";

// Components
import ItemTemplate from "components/home/templates/List/Item";
import { API } from "services/api.js";

// Icones
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SkeletonList from "components/home/global/skeletons/skeletonList";

export default function List() {
  const [loaded, setLoaded] = useState(false);
  const [total, setTotal] = useState(100);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [forms, setForms] = useState([]);

  const [loadedForms, setLoadedForms] = useState(false);

  const onChange = (_page) => {
    setPage(_page);
    API({
      window: window,
      method: "GET",
      url: `templates?page=${_page}&per_page=${perPage}`,
    }).then(({ data, total }) => {
      setForms(data);
      setTotal(total);
    });
  };

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
    API({ method: "GET", url: "forms" }).then(({ data, total }) => {
      setForms(data);
      setLoadedForms(true);
      setTotal(total);
    });
  });

  return (
    <>
      <PageWrapper>
        <ScreenTitle>
          <span>
            <FontAwesomeIcon icon={faAlignLeft} />
          </span>
          Formulários
        </ScreenTitle>

        {loadedForms ? (
          <>
            {forms.length ? (
              <>
                <div className="menu-options">
                  <Link to="/campaigns/forms/new">
                    <BtnOptMenu className="text-gray-800 font-extrabold rounded my-2 md:my-2 py-2 px-2 w-40 mx-2 shadow-sm">
                      Criar novo
                    </BtnOptMenu>
                  </Link>
                </div>
                <table className="w-full text-md bg-white shadow-md rounded mb-4">
                  <tbody>
                    <tr className="border-b">
                      <th className="px-4 py-3">Id</th>
                      <th className="px-4 py-3">Nome</th>
                      <th className="px-4 py-3">Idioma</th>
                      <th className="px-4 py-3"></th>
                    </tr>
                    {forms.map((template) => (
                      <ItemTemplate
                        key={template.id}
                        id={template.id}
                        name={template.name}
                        language={template.language}
                        isForm={true}
                        component="form"
                      />
                    ))}
                  </tbody>
                </table>
                <div className="wrapper-paginator">
                  <Pagination
                    simple
                    defaultCurrent={page}
                    total={total}
                    onChange={onChange}
                    pageSize={perPage}
                  />
                </div>
              </>
            ) : (
              <div className="notFound-message-wrapper">
                <div className="message-nnotFound">
                  Nenhum formulário encontrado
                </div>
                <div>
                  <Link to="/campaigns/forms/new">
                    <BtnOptMenu className="text-gray-800 font-extrabold rounded my-2 md:my-2 py-2 px-2 w-40 mx-2 shadow-sm">
                      Criar novo
                    </BtnOptMenu>
                  </Link>
                </div>
              </div>
            )}
          </>
        ) : (
          <SkeletonList />
        )}
      </PageWrapper>
    </>
  );
}
