import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";

import { API } from "services/api";
import { FormWrapper, ScreenTitle } from "styles/Global";
import FormViewCampaign from "../components/FormView";
import { useNotification } from "contexts/session";

export default function ViewCampaign() {
  const { id } = useParams();

  const { openNotification } = useNotification();

  // Dados da campanha
  const [campaign, setCampaign] = useState({});

  // Dados
  const [ratingTypes, setRatingTypes] = useState([]);
  const [languages, setLanguages] = useState([]);

  // Carrega os dados nescessários
  useEffect(() => {
    API({ method: "GET", url: `campaigns/${id}` }).then((campaignData) => {
      API({ method: "GET", url: "ratingtypes" })
        .then((types) => {
          API({ method: "GET", url: "config/langs" })
            .then((languages) => {
              console.log(campaignData);
              setRatingTypes(types);
              setLanguages(languages.value);
            })
            .catch((err) => {
              openNotification({
                message: "Erro",
                description:
                  "Não foi possível carregar os dados nescessários, atualize a página",
                status: "error",
              });
            });
        })
        .catch((err) => {
          openNotification({
            message: "Erro",
            description:
              "Não foi possível carregar os dados nescessários, atualize a página",
            status: "error",
          });
          console.log(err.message);
        });
      setCampaign(campaignData);
    });
  }, [id]);
  return (
    <>
      <FormWrapper>
        <ScreenTitle>
          <span>
            <FontAwesomeIcon icon={faStar} />
          </span>
          {`Campanha: ${campaign.name || " ..."}`}
        </ScreenTitle>
        <FormViewCampaign
          campaign={campaign}
          ratingTypes={ratingTypes}
          languages={languages}
        />
      </FormWrapper>
    </>
  );
}
