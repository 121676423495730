import styled from "styled-components";

export const FormNewUserWrapper = styled.form`
  padding: 20px 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 10px;

  .disabled {
    background-color: #029e9b !important;
    color: #c3c3c3 !important;
    cursor: disabled !important;
  }

  .fields-wrapper {
    width: 60%;
    display: flex;
    justify-content: center;
    .fields-box {
      width: 80%;
      @media (max-width: 950px) {
        width: 90%;
      }
      @media (max-width: 850px) {
        width: 100%;
      }
    }
    @media (max-width: 950px) {
      width: 90%;
    }
    @media (max-width: 850px) {
      width: 100%;
    }
    .half-input {
      width: 80%;
      @media (max-width: 890px) {
        width: 100%;
      }
    }
    .small-input {
      width: 70%;
      @media (max-width: 890px) {
        width: 100%;
      }
    }
    .invalid-password {
      height: 20px;
      margin-bottom: 5px;
      .invalid-password-message {
        color: red;
      }
    }
    .wrapper-button {
      width: 100%;
      display: flex;
      justify-content: center;
      button {
        width: 300px;
      }
    }
  }
`;
