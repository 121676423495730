import React from "react";

import { FreshdeskRatingWrapper, WrapperRatings, OptionRating } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FaRegSadTear,
  FaRegFrown,
  FaRegMeh,
  FaRegSmile,
  FaRegLaugh,
} from "react-icons/fa";

function RatingFreshdesk(props) {
  const { onChange, value } = props;
  const arrayValues = [
    {
      value: 1,
      label: "Muito triste",
      emoji: <FaRegSadTear />,
      colorHeader: "#e7340f",
      colorBody: "#fce3e0",
    },
    {
      value: 2,
      label: "Triste",
      emoji: <FaRegFrown />,
      colorHeader: "#ff8c00",
      colorBody: "#ffefdf",
    },
    {
      value: 3,
      label: "Nem feliz nem triste",
      emoji: <FaRegMeh />,
      colorHeader: "#f1db16",
      colorBody: "#fdfae2",
      selected: true,
    },
    {
      value: 4,
      label: "Feliz",
      emoji: <FaRegSmile />,
      colorHeader: "#6bb436",
      colorBody: "#ebf4e3",
    },
    {
      value: 5,
      label: "Muito Feliz",
      emoji: <FaRegLaugh />,
      colorHeader: "#4e8d00",
      colorBody: "#e6efdd",
    },
  ];

  return (
    <FreshdeskRatingWrapper>
      <WrapperRatings>
        {arrayValues.map((v, index) => (
          <OptionRating
            key={v}
            onClick={() => onChange(v.value)}
            colorHeader={v.colorHeader}
            colorBody={v.colorBody}
            selected={value == v.value}
          >
            <div className="label">{v.label}</div>
            <div className="emoji">{v.emoji}</div>
          </OptionRating>
        ))}
      </WrapperRatings>
    </FreshdeskRatingWrapper>
  );
}

export default RatingFreshdesk;
