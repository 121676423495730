import styled from "styled-components";

export const FormRegister = styled.form``;

export const WrapperPageRegister = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    color: rgb(255, 255, 255);
    font-weight: bold;
  }
  .logo {
    width: 400px;
    @media (max-height: 768px) {
      width: 250px;
    }
  }
  .box-form-window {
    width: 70%;
  }
`;
