import styled from "styled-components";

export const ConnectionBox = styled.div`
  background-color: white;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.1);
  margin: 5px;
  padding: 5px;
  border-radius: 10px;
  width: 480px;

  .connection-box {
    display: grid;
    grid-template-columns: 3fr 5fr 2fr;
    height: 120px;
    .connection-image-box {
      display: flex;
      padding-left: 5px;
      justify-content: center;
      align-items: center;
      width: 80px;
      .connection-image {
        height: 80px;
        margin: 10px;
      }
    }
    .connection-infos-box {
      font-family: karla;
      display: flex;
      padding-left: 10px;
      flex-direction: column;
      justify-content: space-between;
      .connection-type {
        font-weight: bold;
        font-size: 1.1rem;
      }
      .connection-name {
        .name {
          color: rgba(0, 0, 40, 0.3);
        }
      }
      .connection-toolUrl {
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      a {
        text-decoration: none;
        color: rgb(65, 65, 65);
      }
      .button {
        font-size: 1.2rem;
        box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.2);
        padding: 2px 7px;
        border-radius: 5px;
        cursor: pointer;
      }
      .button:hover {
        cursor: pointer;
      }
    }
  }
`;
