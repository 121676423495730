import React from "react";
import { Outlet } from "react-router-dom";
import { CampaignRegisterProvider } from "contexts/campaignRegister";

export default function Campaings() {
  return (
    <>
      <CampaignRegisterProvider>
        <Outlet />
      </CampaignRegisterProvider>
    </>
  );
}
