import styled from "styled-components";

export const RatingTypeWrapper = styled.tr`
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: 250px;
  margin: 5px 10px;
  padding: 5px 10px;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;

  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.1);
  :hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    animation: all 0.8s ease-in-out;
  }

  .connection-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 60px;
    }
  }
  .connection-name {
    margin-left: 10px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // Se for a opção selecionada
  background-color: ${(props) => (props.selected ? "#f5f5f5" : "#fffA")};
  border: ${(props) =>
    props.selected ? "2px solid #C0C0C0" : "1px solid #e6e6e6"};
`;
